import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Layout from '../components/common/layout/Layout'
import CopyWrapper from '../components/common/CopyWrapper'
import PaperBody from '../components/common/layout/PaperBody'
import Stack from '@mui/material/Stack'
import { useAuth } from '../context/AuthContext'
import { stripAt, titleCaps } from '../util'
import { PERMISSION_CUSTOMER_DATA_WRITE } from '../config/config'
import ButtonCard from '../components/common/ButtonCard'
import Box from '@mui/system/Box'
import CustomDivider from '../components/common/CustomDivider'
import ContentCopySharp from '@mui/icons-material/ContentCopySharp'
import CustomBold from '../components/common/CustomBold'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(3)
  },
  containerFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    columnGap: '15px'
  },
  link: {
    color: theme.palette.GSB[400]
  },
  ButtonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& > *': {
      marginLeft: 0,
      margin: theme.spacing(1)
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  },
  gridPaper: {
    flex: '45%',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '8px',
    margin: theme.spacing(0)
  },
  pre: {
    whiteSpace: 'pre-wrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    overflowX: 'auto',
    cursor: 'pointer',
    background: theme.palette.grey[50],
    '&:hover': {
      background: theme.palette.GSB[50]
    },
    '& > div': {
      maxWidth: 'calc(100% - 30px)'
    }
  },
  noOverflow: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  buttonGrid: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    padding: '0px'
  },
  headerLarge: {
    fontFamily: 'KlarheitKurrent',
    fontSize: '1.7em',
    marginBottom: '20px',
    lineHeight: theme.spacing(4)
  },
  headerMedium: {
    fontSize: '1.25em',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500]
  },
  itemHeaderMedium: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.6em',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[700]
  },
  paragraphStandard: {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: '0.875em',
    fontWeight: 400,
    color: theme.palette.grey[700]
  },
  copyIcon: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5)
  }

}))

function Home () {
  const classes = useStyles()
  const { username, role } = useAuth()

  const queryCallTextToCopy = 'curl -d \'{ "operationName":"<operation>","query":"<query>","variables":"<vars>"}\' -H "authorization: Bearer <token> -X POST https://contentgraph.media.api.geniussports.com/v1/graphql'
  const authTestToCopy = `curl -d '{"username":"${username}", "password":"<password>"}' -H "Content-Type: application/json" -X POST https://auth.media.api.geniussports.com/v1/login`

  return (
    <Layout>
      <Box className={classes.container}>
        <Typography style={{ marginBottom: '16px' }} className={`${classes.noOverflow} ${classes.headerLarge}`}><b>{username ? `Welcome, ${titleCaps(stripAt(username))}` : 'Welcome!'} </b></Typography>
        <Typography variant='body1' className={classes.headerMedium}>
          The Content Graph <CustomBold>is a GraphQL-powered API</CustomBold> providing real-time access to live sports data.
        </Typography>
      </Box>

      <CustomDivider />

      <Stack className={classes.buttonGrid} direction='row' spacing={2}>
        <ButtonCard
          link='/v1/assets/GSMContentGraphIntegrationGuide.pdf'
          text='Integration Guide'
          icon='integration_instructions_black'
          variant='contained'
          externalLink
        />
        <ButtonCard
          link='/graphiql'
          text='Query Explorer'
          icon='find_in_page'
          variant='outlined'
          externalLink={false}
        />
        {role.hasPermission(PERMISSION_CUSTOMER_DATA_WRITE) &&
          <ButtonCard
            link='/customer-data'
            text='External Sources'
            icon='backup_black'
            variant='outlined'
            externalLink={false}
          />}
      </Stack>

      <Box className={classes.containerFlex}>
        <PaperBody noMargin className={classes.gridPaper}>
          <div>
            <Typography className={classes.itemHeaderMedium} gutterBottom>Getting Started</Typography>
            <Typography className={classes.paragraphStandard} gutterBottom>
              The <b><a href='/graphiql' className={classes.link}>Query Explorer</a></b> provides the best way to get acquainted with the features and content of the graph, it's composed of the following parts:
            </Typography>
            <Typography className={classes.paragraphStandard} gutterBottom component='div'>
              <p>
                <b><a href='/graphiql' className={classes.link}>Query Editor</a></b> - This is where queries are edited and launched interactively from the UI. Featuring <b>code completion</b> and <b>syntax validation</b>. Launch queries by pressing the play button.
              </p>
              <p>
                <b><a href='/graphiql' className={classes.link}>Explorer</a></b> - Select fields from a list to build your query as an alternative to manually writing the query. Useful to see what’s available under a specific entity.
              </p>
              <p>
                <b>History</b> - A local history of queries launched by you, clicking on a query in the history will your query again.
              </p>
            </Typography>
          </div>
          <div />
        </PaperBody>
        <PaperBody noMargin className={classes.gridPaper}>
          <div>
            <Typography className={classes.itemHeaderMedium} gutterBottom>Integrate your applications</Typography>
            <CopyWrapper text={authTestToCopy}>
              <Typography className={classes.paragraphStandard} gutterBottom component='div'>
                Our platform uses <b>Bearer Authentication</b> to grant access to the Content Graph and related APIs.
                You can request a token making a <b>POST</b> request to our authentication endpoint as shown below:
                <pre className={classes.pre}>
                  curl -d {'\'{'}"username":"<b>{username || '&lt;query&gt;'}</b>", "password":"<b>&lt;password&gt;</b>"{'}\''} -H "Content-Type: application/json" -X POST <b>https://auth.media.api.geniussports.com/v1/login</b><ContentCopySharp className={classes.copyIcon} />
                </pre>
              </Typography>
            </CopyWrapper>
            <CopyWrapper text={queryCallTextToCopy}>
              <Typography className={classes.paragraphStandard} gutterBottom component='div'>
                Calls to the API are standard GraphQL, and can be made to our public GraphQL endpoint as in the following example:
                <pre className={classes.pre}>
                  <div>curl -d {'\'{'} "operationName":"<b>&lt;operation&gt;</b>","query":"<b>&lt;query&gt;</b>","variables":"<b>&lt;vars&gt;</b>"{'}\''} -H "authorization: Bearer <b>&lt;token&gt;</b> -X POST <b>https://contentgraph.media.api.geniussports.com/v1/graphql</b></div><ContentCopySharp className={classes.copyIcon} />
                </pre>
              </Typography>
            </CopyWrapper>
          </div>
        </PaperBody>
      </Box>

    </Layout>
  )
}

export default Home
