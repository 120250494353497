import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ObjectEditor from '../common/ObjectEditor'
import type { ObjectEditorField } from '../common/ObjectEditor/types'
import { useTranslations } from '../../context/ApiContext'
import { trim } from '../../util'
import { useDeepEffect } from '../../hooks/useDeepEffect'
import GraphTableSnackbar from '../common/GraphTable/GraphTableSnackbar'
import { usePopup } from '../../hooks/modals'

export interface TranslationEditDialogProps {
  customerId: string,
  locale: string,
  open?: boolean,
  onClose?: (finished?: boolean) => any,
  translation?: {sourceText?: string, targetText?: string},
  isNew?: boolean
}

export default function TranslationEditDialog ({ customerId, locale, open, onClose, translation: initialTranslation = {}, isNew = false }: TranslationEditDialogProps) {
  const setPopup = usePopup()
  const [isLoading, setIsLoading] = React.useState(false)
  const [translation, setTranslation] = React.useState(initialTranslation || {})
  const api = useTranslations()
  const isValid = translation && trim(translation.sourceText).length > 1 && trim(translation.targetText).length > 1

  const fields: ObjectEditorField[] = [
    {
      field: 'sourceText',
      title: 'Source Text',
      description: 'Text in English',
      disabled: !isNew
    },
    {
      field: 'targetText',
      title: 'Target Text',
      description: 'Text in your locale'
    }
  ]

  const handleSubmit = () => {
    if (!isLoading) {
      setIsLoading(true)
      api.updateLocale(customerId, locale, {
        [trim(translation.sourceText)]: trim(translation.targetText)
      }).then(() => {
        setPopup(<GraphTableSnackbar message={isNew ? `Your translation has been added to ${locale}.` : 'Your translation has been updated.'} />)
        handleClose(true)
      }).catch(error => {
        setPopup(<GraphTableSnackbar error={error} />)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }

  const handleClose = (finished = false) => {
    onClose && onClose(finished)
  }

  useDeepEffect(() => {
    setTranslation(initialTranslation)
  }, [initialTranslation])

  return (
    <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby='form-dialog-title' fullWidth maxWidth='sm'>
      <DialogTitle id='form-dialog-title'>{isNew ? 'Add translation' : 'Edit translation'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isNew ? 'Please enter the word in English followed by its translation.' : 'Please enter the right translation for your locale.'}
        </DialogContentText>
        <ObjectEditor data={translation} fields={fields} disabled={isLoading} onChange={(e, newData) => setTranslation(newData)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} disabled={isLoading} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={isLoading || !isValid} variant='contained'>
          {isNew ? 'Add translation' : 'Save changes'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
