import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import UserEditor from './UserEditorComponent'
import type { User, SimpleRole } from '../../api/UsersApi'
import Loading from '../common/Loading'
import RoleEditor from './RoleEditor'
import { useApi } from '../../context/ApiContext'

function TabPanel (props: {children?: React.ReactNode, dir?: string, index: any, value: any}) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

function UserDialog ({ username, roles = [], onSubmit = (user) => null } : { username: string, roles: SimpleRole[], onSubmit: (user: User) => void}) {
  const newUser = false
  const [open, setOpen] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [tab, setTab] = React.useState(0)
  const [user, setUser] = React.useState<User>({ username })
  const { users: api } = useApi()

  React.useEffect(() => {
    setLoading(true)
    setOpen(true)
    api.getUser(username).then(user => {
      if (!user.email && validEmail(user.username)) user.email = user.username
      setUser(user)
      setLoading(false)
    })
  }, [username, api])

  const handleClose = (_, reason: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }

  const handleChange = (user) => {
    setUser(user)
  }

  const handleAttributeChange = (roleAttributes) => {
    user.roleAttributes = roleAttributes
    setUser(user)
  }

  const handleSubmit = () => {
    if (validUser() && onSubmit) {
      setOpen(false)
      return onSubmit(user)
    }
  }

  const validEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  const validUser = () => {
    if (!user.username) return false
    if (user.email && !validEmail(user.email)) return false
    if (!user.role) return false
    return true
  }

  const roleHasAttributes = () => {
    const role = roles && roles.find(role => role.name === user.role)
    return !!(role && role.attributes && role.attributes.length)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'>
      <DialogTitle id='form-dialog-title'>Edit {user.username}</DialogTitle>
      {isLoading ? <Loading /> : (<div>
        <AppBar position='static' color='primary' elevation={2}>
          <Tabs
            value={tab}
            onChange={(e, i) => setTab(i)}
            textColor='inherit'
            variant='fullWidth'
          >
            <Tab label='Basic' />
            <Tab label='Role Attributes' disabled={!roleHasAttributes()} />
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
          <UserEditor user={user} newUser={newUser} roles={roles} onChange={handleChange} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <RoleEditor roleAttributes={user.roleAttributes || {}} role={user.role} roles={roles} onChange={handleAttributeChange} />
        </TabPanel>
      </div>)}
      <DialogActions>
        <Button onClick={() => setOpen(false)} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={!validUser()}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserDialog
