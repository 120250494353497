import React from 'react'
import GraphTable from '../common/GraphTable'
import { defaultAttributes } from '../common/GraphTable/functions'
import { GraphTableData, GraphTableColumn, GraphTableAction, GraphTableRowAction, GraphTableRefAttributes } from '../common/GraphTable/types'
import { titleCaps } from '../../util'
import AddIcon from '@mui/icons-material/AddBox'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import GraphTableSnackbar from '../common/GraphTable/GraphTableSnackbar'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import { useCustomerData } from '../../context/ApiContext'
import { usePopup } from '../../hooks/modals'
import AddDialog from './CustomerDataAddDialog'
import ImportDialog from './CustomerDataImportDialog'
import ExportDialog from './CustomerDataExportDialog'
import { Link } from '@reach/router'
import get from 'lodash/get'
import uniq from 'lodash/uniq'

/**
 * Custom column extraction function to use with GraphTable
 *
 * 1. Grabs all the keys from the first level of the first result
 * 2. Grabs all the keys inside `data` of every item on the data
 * 3. Filter every column that doesn't have at least one truthy value
 *
 */
export function customerDataColumns (data: GraphTableData, type?: string): GraphTableColumn[] {
  data = data || []
  const baseKeys = Object.keys(data[0] || {})
  const dataKeys = data
    .reduce((acc, item) => uniq(acc.concat(Object.keys(item.data || {}))), [])
    .filter(key => !['key', 'dateKey', 'tableData'].includes(key))

  function columns (keys, path = '') {
    return keys.reduce((arr, key) => {
      if (!path && key === 'data') {
        arr.push(...columns(dataKeys, 'data'))
      } else {
        arr.push({
          title: titleCaps(key),
          field: path ? `${path}.${key}` : key,
          sortable: !path, // Only first level columns are sortable
          ...(defaultAttributes[key] || {})
        })
      }
      return arr
    }, [])
  }
  return columns(baseKeys).filter(({ field }) => data.some(item => !!get(item, field)))
}

function Table ({ customerId, type }: {customerId: string, type: string}) {
  const setPopup = usePopup()
  const api = useCustomerData()
  const tableRef = React.useRef<GraphTableRefAttributes>(null)
  const [importOpen, setImportOpen] = React.useState(false)
  const [exportOpen, setExportOpen] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [addOpen, setAddOpen] = React.useState(false)
  const [addFields, setAddFields] = React.useState<string[]>([])
  const canWrite = api.userCanWrite()
  const refresh = () => { tableRef && tableRef.current && tableRef.current.refresh() }
  const getColumns = () => { return tableRef && tableRef.current && tableRef.current.getColumns() }

  const handleAdd: GraphTableAction['onClick'] = (e) => {
    setAddFields((getColumns() || []).map(({ field }) => field))
    setAddOpen(true)
  }

  const handleAddClose = (finished) => {
    setAddOpen(false)
    finished && refresh()
  }

  const handleDeleteSingle: GraphTableRowAction['onClick'] = (e, { customerDataId }, actionRefresh) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you really sure you want to delete this entry?')) {
      api.deleteCustomerDataEntry(customerId, customerDataId).then(({ affectedRows }) => {
        setPopup(<GraphTableSnackbar message={affectedRows ? `Your data has been deleted, ${affectedRows} row affected.` : `No data has been deleted, ${customerDataId} did not exist.`} />)
        actionRefresh()
      })
    }
  }

  const handleDeleteAll: GraphTableAction['onClick'] = (e, actionRefresh) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you really sure you want to DELETE ALL DATA for type "${type}"?`)) {
      setIsDeleting(true)
      api.deleteCustomerData(customerId, type).then(({ affectedRows }) => {
        setPopup(<GraphTableSnackbar message={affectedRows ? `Your data has been deleted, ${affectedRows} row affected.` : `No data has been deleted, type "${type}" was already empty.`} />)
        actionRefresh()
      }).catch(error => {
        setPopup(<GraphTableSnackbar error={error} />)
      }).finally(() => {
        setIsDeleting(false)
      })
    }
  }

  const handleImport: GraphTableAction['onClick'] = (ev) => {
    setImportOpen(true)
  }

  const handleCloseImport = () => {
    setImportOpen(false)
    refresh()
  }

  const handleExport = () => {
    setExportOpen(true)
  }

  const handleCloseExport = () => {
    setExportOpen(false)
  }

  const tableActions: GraphTableAction[] = [
    {
      title: 'Export Customer Data',
      icon: SaveAltIcon,
      onClick: handleExport
    },
    canWrite && {
      title: 'Delete All',
      icon: DeleteSweepIcon,
      disabled: isDeleting,
      onClick: handleDeleteAll
    },
    canWrite && {
      title: 'Import Data',
      icon: LibraryAddIcon,
      onClick: handleImport
    },
    {
      title: 'Add Entry',
      icon: AddIcon,
      onClick: handleAdd
    }
  ]

  const rowActions: GraphTableRowAction[] = canWrite ? [
    {
      title: 'Delete',
      icon: DeleteIcon,
      onClick: handleDeleteSingle,
      hideTitle: true
    }
  ] : []

  return (<React.Fragment>
    <GraphTable
      ref={tableRef}
      title={<span>Data for {titleCaps(type)} <small>(<Link to={`/customer-data/${customerId}`}>{customerId}</Link>)</small></span>}
      type='customer_data'
      query={`
        query ($customerId: String, $type: String, $limit:Int, $offset:Int, $orderBy:[customer_data_order_by!]) {
          customer_data(where: {customerId: {_eq: $customerId}, type: {_eq: $type}}, limit: $limit, offset: $offset, order_by: $orderBy) {
            customerDataId
            key
            data
            dateKey

            fixtureId
            competitorId
            competitionId
            seasonId
            roundId
            marketTypeId
            _lastUpdatedAt
          }

          customer_data_aggregate(where: {customerId: {_eq: $customerId}, type: {_eq: $type}}) {
            aggregate {
              count
            }
          }
        }
      `}
      serverPagination
      serverSort
      variables={{
        customerId,
        type
      }}
      columns={customerDataColumns}
      columnAttributes={{
        customerDataId: {
          hidden: true
        },
        scheduledStartTime: {
          title: 'Start Time',
          type: 'datetime'
        }
      }}
      tableActions={tableActions}
      rowActions={rowActions}
    />
    <ExportDialog customerId={customerId} type={type} open={exportOpen} onClose={handleCloseExport} />
    <ImportDialog
      customerId={customerId}
      type={type}
      open={importOpen}
      onClose={handleCloseImport}
    />
    <AddDialog
      customerId={customerId}
      type={type}
      fields={addFields}
      open={addOpen}
      onClose={handleAddClose}
    />
          </React.Fragment>
  )
}

export default Table
