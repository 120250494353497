import React, { useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert, { AlertColor } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

export default function QuickSnack ({ id, title, severity = 'info', autoHideDuration = 5000, children } : {id?: any, title: string, severity?: string, autoHideDuration?: number | null, children?: any}) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [id, title, severity, children])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity as AlertColor}>
        {children && <AlertTitle>{title}</AlertTitle>}
        {children || title}
      </Alert>
    </Snackbar>
  )
}
