import React from 'react'
import Login from '../components/Login'
import AuthContext from '../context/AuthContext'

const withLoginForm = (BaseComponent: React.ComponentType<any>) => ({ ...props }) => {
  const { isLoggedIn, login } = React.useContext(AuthContext)
  const [isLoading, setLoading] = React.useState(false)
  const [errorMessage, setError] = React.useState('')

  const handleLogin = (username, password, newPassword) => {
    setLoading(true)
    login({ username, password, newPassword }).then(() => {
      setLoading(false)
      setError('')
    }).catch((error) => {
      setLoading(false)
      setError((error && error.message) || 'An unknown error has occured')
    })
  }

  return isLoggedIn
    ? <BaseComponent {...props} />
    : <Login onSubmit={handleLogin} errorMessage={errorMessage} loading={isLoading} />
}

export default withLoginForm
