import React from 'react'
import clsx from 'clsx'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemLink from '../ListItemLink'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'

import HomeSharp from '@mui/icons-material/HomeSharp'
import FindInPageSharp from '@mui/icons-material/FindInPageSharp'
import TranslateSharp from '@mui/icons-material/TranslateSharp'
import BackupSharp from '@mui/icons-material/BackupSharp'
import ManageAccountsSharp from '@mui/icons-material/ManageAccountsSharp'
import ChevronLeft from '@mui/icons-material/ChevronLeftSharp'
import ChevronRight from '@mui/icons-material/ChevronRightSharp'
import QueryStatsSharp from '@mui/icons-material/QueryStatsSharp'
import OpenInNewSharp from '@mui/icons-material/OpenInNewSharp'
import MenuBookSharp from '@mui/icons-material/MenuBookSharp'

import { PERMISSION_USERS_ADMIN, PERMISSION_TRANSLATIONS } from '../../../config/config'
import { useRole } from '../../../context/AuthContext'

export type MenuItem = {
  title?: string,
  target?: string,
  type?: 'link' | 'separator',
  permission?: string | ((item: MenuItem) => boolean) | false
  icon?: React.ComponentType,
  isExternal?: boolean
}

const menu: MenuItem[] = [
  {
    title: 'Home',
    target: '/',
    icon: HomeSharp
  },
  {
    title: 'Query Explorer',
    target: '/graphiql',
    icon: FindInPageSharp
  },
  {
    title: 'External Sources',
    target: '/customer-data',
    icon: BackupSharp
  },

  {
    title: 'Translations',
    target: '/translations',
    permission: PERMISSION_TRANSLATIONS,
    icon: TranslateSharp
  },
  {
    title: 'Users',
    target: '/users',
    permission: PERMISSION_USERS_ADMIN,
    icon: ManageAccountsSharp
  },
  {
    title: 'Reports',
    target: '/reports',
    icon: QueryStatsSharp
  },
  {
    title: 'Integration Guide',
    target: '/v1/assets/GSMContentGraphIntegrationGuide.pdf',
    icon: MenuBookSharp,
    isExternal: true
  }
]

export interface NavigatorProps extends DrawerProps {
  onSelectItem?: (item: MenuItem) => (false | void);
  fullscreen?: boolean;
  setFullscreen?: (fullScreen: boolean) => void
  drawerWidth: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainMenu: {
      overflow: 'hidden'
    },
    item: {
      color: theme.palette.grey[700],
      '&:hover': {
        backgroundColor: theme.palette.gsmBackground
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '& > .MuiListItemText-root': {
        margin: 0
      }
    },
    textMenuItems: {
      fontFamily: `${(theme.typography as any).subtitle2.fontFamily}`
    },
    headerSpacing: {
      paddingBottom: theme.spacing(2)
    },
    itemActiveItem: {
      color: theme.palette.GSB[400],
      backgroundColor: theme.palette.gsmBackground,
      borderRightWidth: '4px',
      borderRightColor: theme.palette.GSB[400],
      borderRightStyle: 'solid',
      '& .MuiTypography-root': {
        fontWeight: 'bold'
      }

    },
    itemCategory: {
      backgroundColor: theme.palette.common.white
    },
    itemIcon: {
      minWidth: 20,
      marginRight: theme.spacing(2)
    },
    itemExternal: {
      display: 'block',
      padding: theme.spacing(2)
    },
    itemIconExternal: {
      maxWidth: 12
    },
    itemPrimary: {
      fontSize: 'inherit',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    footer: {
      position: 'relative',
      minHeight: '63px',
      bottom: '0px',
      marginTop: 'auto',
      fontSize: 'inherit',
      width: '100%',
      textAlign: 'right',
      padding: '18px 18px 18px 18px',
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.grey[200],
      borderTopWidth: '1px',
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        verticalAlign: 'middle'
      }
    },
    content: {
      minHeight: 'calc(100vh, - 63px)'
    }
  })
)

function Navigator(props: NavigatorProps) {
  const { onSelectItem, fullscreen, setFullscreen, drawerWidth, ...other } = props
  const { hasPermission } = useRole()

  const classes = useStyles()

  const clickItem = (item: MenuItem) => {
    !item.isExternal && onSelectItem && onSelectItem(item)
  }

  const isVisible = (item: MenuItem) => {
    if (typeof item.permission === 'function') return item.permission(item)
    if (typeof item.permission === 'string') return hasPermission(item.permission)
    return true
  }

  return (
    <Drawer
      variant='temporary' {...other} sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' }
      }}
    >
      <div className={classes.content}>
        <List disablePadding className={classes.mainMenu}>
          <ListItemLink key='home' to='/' commonClassName={classes.headerSpacing}>
            {
              fullscreen
                ? <ListItemIcon className={classes.itemIcon}>
                  <HomeSharp />
                </ListItemIcon>
                : 'Content Graph'
            }
          </ListItemLink>
          {menu.filter(isVisible).map((item, index) => <MenuItemComponent key={`item-${index}`} small={fullscreen} item={item} classes={classes} onClick={() => clickItem(item)} />)}
        </List>
      </div>
      {
        fullscreen
          ? <div className={clsx(classes.footer, classes.item)} onClick={() => setFullscreen(false)}> <ChevronRight /> </div>
          : <div className={clsx(classes.footer, classes.item)} onClick={() => setFullscreen(true)}> Collapse <ChevronLeft />  </div>
      }
    </Drawer>
  )
}

function MenuItemComponent({ item, classes, onClick }: { item: MenuItem, small?: boolean, classes: any, onClick?: () => any }) {
  if (item.isExternal) {
    return (
      <a href='/v1/assets/GSMContentGraphIntegrationGuide.pdf' target='_blank' className={clsx(classes.item, classes.itemExternal)}>
        <Box sx={{ display: 'flex', maxHeight: '32px' }}>
          <Box>
            <ListItemIcon className={classes.itemIcon}>
              {item.icon && <item.icon />}
            </ListItemIcon>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <div className={classes.textMenuItems}>
              {item.title}
            </div>

          </Box>
          <Box><OpenInNewSharp className={classes.itemIconExternal} /></Box>

        </Box>
      </a>
    )
  };
  return (
    <ListItemLink
      to={item.target}
      commonClassName={classes.item}
      defaultClass={classes.itemCategory}
      activeClass={classes.itemActiveItem}
      onClick={onClick}
      title={item.title}
    >
      <ListItemIcon className={classes.itemIcon}>
        {item.icon && <item.icon />}
      </ListItemIcon>
      <ListItemText
        classes={{
          primary: classes.itemPrimary
        }}
      >
        <div className={classes.textMenuItems}>
          {item.title}
        </div>
      </ListItemText>
    </ListItemLink>
  )
}

export default Navigator
