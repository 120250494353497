import React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ExamplesType, { MapQuery, Sport } from './ExamplesType'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material'
import { GeniusTheme } from '../../../enhancers/withGeniusTheme'
import PopularQueries from './PopularQueries'
import QueriesByCategory from './QueriesByCategory'
import bringAllSampleQueries from './BringQueryExamples'

const StyledList = styled(List)({
  width: '100%',
  maxWidth: '360',
  paddingTop: 0
})

const StyledListButton = styled(ListItemButton)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[50]};
  background: ${(props) =>
    props.className === 'main' ? 'white' : props.theme.palette.gsmBackground};
`

interface SportWindowsProps {
  handleClick: () => void;
  sport: Sport;
  open: boolean;
  queries: MapQuery;
  updateDisplayedQuery: (queryId: string) => void;
}

const SportWindow = ({
  handleClick,
  sport,
  open,
  queries,
  updateDisplayedQuery
}: SportWindowsProps) => (
  <div>
    <StyledListButton
      onClick={handleClick}
      className={!open ? 'main' : 'secondary'}
    >
      {open && <ArrowBackIosIcon sx={{ fontSize: 12 }} />}
      <ListItemText primary={sport.name} />
      {!open && <ArrowForwardIosIcon sx={{ fontSize: 12 }} />}
    </StyledListButton>
    {open && (
      <PopularQueries
        popularQueryIds={sport.popularQueryIds}
        queries={queries}
        updateDisplayedQuery={updateDisplayedQuery}
      />
    )}
    <Collapse in={open} timeout='auto' unmountOnExit>
      <List component='div' disablePadding>
        {sport.queryCategories.map((queryCategory, index) => (
          <QueriesByCategory
            key={index}
            queryCategory={queryCategory}
            mapQueries={queries}
            updateDisplayedQuery={updateDisplayedQuery}
          />
        ))}
      </List>
    </Collapse>
  </div>
)

export default function QueryListView ({ onEditQuery, onEditVariables }) {
  const [windowIndex, setWindowIndex] = React.useState<number>(-1)
  const [sampleQueries, setSampleQueries] = React.useState<ExamplesType>(
    {} as ExamplesType
  )

  React.useEffect(() => {
    const fetchSampleQueries = async () => {
      const examplesData: ExamplesType = await bringAllSampleQueries()
      setSampleQueries(examplesData)
    }
    fetchSampleQueries()
  }, [])

  const updateDisplayedQuery = (queryId: string) => {
    const query = sampleQueries.queries[queryId]
    onEditQuery(query.query)
    onEditVariables(query.variables)
  }

  const handleOpenTab = (index: number): void => {
    setWindowIndex(isMainWindow(windowIndex) ? index : -1)
  }

  const isMainWindow = (index: number) => (index === -1)

  return (
    <ThemeProvider theme={GeniusTheme}>
      <StyledList aria-labelledby='nested-list-subheader'>
        {sampleQueries.sports &&
          sampleQueries.sports.map(
            (sport, index) =>
              (windowIndex === -1 || windowIndex === index) && (
                <SportWindow
                  key={`${sport.name}-${index}`}
                  handleClick={() => handleOpenTab(index)}
                  open={index === windowIndex}
                  sport={sport}
                  queries={sampleQueries.queries}
                  updateDisplayedQuery={updateDisplayedQuery}
                />
              )
          )}
      </StyledList>
    </ThemeProvider>
  )
}
