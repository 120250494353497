import PaperBody from '../common/layout/PaperBody'
import AddIcon from '@mui/icons-material/Add';
import NoDataAvailablePlaceholder from '../../components/common/NoDataAvailablePlaceholder'
import HeaderWithActions from '../common/HeaderWithActions'
import NavGrid from '../common/NavGrid'
import { titleCaps } from '../../util'

import type { TranslationsCustomer } from '../../api/Translations'

export type CustomerBodyProps = {
  customer: TranslationsCustomer
  onImportClick: (customerId: string, close?: boolean) => any
}

function CustomerBody ({ customer, onImportClick: handleImport } : CustomerBodyProps) {
  const { customerId, locales = [], canWrite } = customer
  const title = titleCaps(customerId)

  const importAction = {
    title: `Add Language`,
    onClick: () => handleImport(customerId),
    icon: AddIcon
  }
  
  // Do not display empty customers we cant write to!
  if (!locales.length && !canWrite) {
    return <></>
  }

  return (
    <PaperBody>
      <HeaderWithActions defaultAction={canWrite && importAction}>{title}</HeaderWithActions>
      <NavGrid
        items={locales.map(type => ({
          title: type,
          target: `/translations/${customerId}/${type}`
        }))} placeholder={<NoDataAvailablePlaceholder title='There are no locales for this customer' actionTitle='Import translations Data...' actionOnClick={() => handleImport(customerId)} />}
      />
    </PaperBody>)
}

export default CustomerBody
