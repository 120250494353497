import React from 'react'
import QuickSnack from '../QuickSnack'

export type GraphTableSnackbarProps = {
  message?: string | JSX.Element,
  error?: any,
  warning?: boolean
  autoHideDuration?: number | null
}

function GraphTableSnackbar ({ message, error, warning, autoHideDuration }: GraphTableSnackbarProps) {
  return error
    ? <QuickSnack
      title={(typeof error === 'object' && error.title) || 'An Error has occurred!'} severity={warning ? 'warning' : 'error'}
      autoHideDuration={autoHideDuration}
      >
      {typeof error === 'object'
        ? ((error.body && (error.status === 403 ? 'Your user is not allowed to perform this action' : error.body.message)) || error.toString())
        : message || (typeof error === 'string' && error) || 'Please try again later.'}
      </QuickSnack>
    : <QuickSnack
      title={warning ? 'Warning!' : 'Success!'}
      severity={warning ? 'warning' : 'success'}
      autoHideDuration={autoHideDuration}
      >
      {message || 'Your operation has completed!'}
    </QuickSnack>
}

export default GraphTableSnackbar
