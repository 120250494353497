import React from 'react'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import makeStyles from '@mui/styles/makeStyles';
import { PERMISSION_USERS_ADMIN, PERMISSION_CUSTOMER_DATA, PERMISSION_CUSTOMER_DATA_WRITE, PERMISSION_TRANSLATIONS, PERMISSION_HASURA_TRAFFIC_RECORDER_API_ACCESS } from '../../config/config'
import type { User, SimpleRole } from '../../api/UsersApi'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  }
}))

export default function UserEditor ({ user = { username: '' }, newUser = false, roles = [], onChange = (user) => null } : {user?: User, newUser: boolean, roles: SimpleRole[], onChange: (user: User) => void}) {
  const [updatedUser, setUpdatedUser] = React.useState<User>(user)
  const classes = useStyles()

  const setUser = (user) => {
    setUpdatedUser(user)
    onChange && onChange(user)
  }

  const setUserProperty = (property, value) => {
    const update = { [property]: value }
    if (property === 'email' && newUser) update['username'] = value
    setUser(Object.assign({}, updatedUser, update))
  }

  const hasPermission = (permission: string) => (updatedUser.apiPermissions || '').split(',').includes(permission)
  const setPermission = (permission: string, value: boolean) => {
    const permissions = new Set((updatedUser.apiPermissions || '').split(','))
    if (value) {
      permissions.add(permission)
    } else {
      permissions.delete(permission)
    }
    setUserProperty('apiPermissions', [...permissions].filter(e => !!e).join(','))
  }

  return (
    <div className={classes.container}>
      {!newUser && <TextField
        autoFocus
        margin='dense'
        id='username'
        label='Username'
        value={updatedUser.username}
        type='text'
        disabled
        fullWidth
      />}
      <TextField
        autoFocus
        margin='dense'
        id='email'
        label='Email Address'
        value={updatedUser.email}
        type='email'
        fullWidth
        required
        onChange={e => setUserProperty('email', e.target.value)}
      />

      <TextField
        margin='dense'
        id='select_role'
        label='Content Graph Role'
        select
        value={updatedUser.role || ''}
        fullWidth
        onChange={e => setUserProperty('role', e.target.value)}
      >
        {roles && roles.map(role => (
          <MenuItem key={role.name} value={role.name}>{[role.name, role.description].filter(e => !!e).join(' - ')}</MenuItem>
        ))}
        <MenuItem key='admin' value='admin'>admin</MenuItem>
      </TextField>

      <TextField
        margin='dense'
        id='customer_data'
        label='Customer Data API Access'
        select
        value={hasPermission(PERMISSION_CUSTOMER_DATA_WRITE) ? 'write' : (hasPermission(PERMISSION_CUSTOMER_DATA) ? 'read' : 'none')}
        fullWidth
        onChange={e => {
          switch (e.target.value) {
            case 'write':
              setPermission(PERMISSION_CUSTOMER_DATA, true)
              setPermission(PERMISSION_CUSTOMER_DATA_WRITE, true)
              break
            case 'read':
              setPermission(PERMISSION_CUSTOMER_DATA, true)
              setPermission(PERMISSION_CUSTOMER_DATA_WRITE, false)
              break
            default:
              setPermission(PERMISSION_CUSTOMER_DATA, false)
              setPermission(PERMISSION_CUSTOMER_DATA_WRITE, false)
          }
        }}
      >
        <MenuItem key='none' value='none'>Restricted - Only read data from allowed customer data sources through the Graph.</MenuItem>
        <MenuItem key='read' value='read'>Read access - Read-only access through the API and CG Web.</MenuItem>
        <MenuItem key='write' value='write'>Full access - Read/write data as any allowed customer.</MenuItem>
      </TextField>

      <FormControlLabel
        control={
          <Checkbox
            checked={hasPermission(PERMISSION_USERS_ADMIN)}
            onChange={e => setPermission(PERMISSION_USERS_ADMIN, e.target.checked)}
            name='user_manager'
            color='primary'
          />
        }
        label='Enable User Manager admin access (create/modify/delete other users)'

      />

      <FormControlLabel
        control={
          <Checkbox
            checked={hasPermission(PERMISSION_TRANSLATIONS)}
            onChange={e => setPermission(PERMISSION_TRANSLATIONS, e.target.checked)}
            name='translations_api_access'
            color='primary'
          />
        }
        label='Enable Translation API access'

      />

      <FormControlLabel
        control={
          <Checkbox
            checked={hasPermission(PERMISSION_HASURA_TRAFFIC_RECORDER_API_ACCESS)}
            onChange={e => setPermission(PERMISSION_HASURA_TRAFFIC_RECORDER_API_ACCESS, e.target.checked)}
            name='hasura_traffic_recorder_api_access'
            color='primary'
          />
        }
        label='Enable Hasura Traffic Recorder API access'

      />

    </div>
  )
}
