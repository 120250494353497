import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

export default function CopyUserDialog ({
  username,
  onSubmit = (username, copyFrom) => null
}: {
  username: string;
  onSubmit: (username: string, copyFrom: string) => void;
}) {
  const [open, setOpen] = React.useState(false)
  const [copyFrom, setCopyFrom] = React.useState<string>('')

  React.useEffect(() => {
    setOpen(true)
  }, [])

  const handleClose = (_: object, reason?: string) => {
    if (!reason || reason !== 'backdropClick') {
      setOpen(false)
    }
  }

  const handleChange = (copyFrom) => {
    setCopyFrom(copyFrom)
  }

  const handleSubmit = () => {
    if (validCopyFrom() && onSubmit) {
      setOpen(false)
      return onSubmit(username, copyFrom)
    }
  }

  const validCopyFrom = () => {
    if (!copyFrom) return false
    if (!(copyFrom && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(copyFrom))) { return false }
    return true
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Create User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the user's email address to copy permissions from
        </DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          id='email'
          label='Email Address'
          type='email'
          fullWidth
          required
          onChange={(e) => handleChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color='primary'
          disabled={!validCopyFrom()}
        >
          Copy permissions
        </Button>
      </DialogActions>
    </Dialog>
  );
}
