import React from 'react'
import Layout from '../../components/common/layout/Layout'
import PaperBody from '../../components/common/layout/PaperBody'
import GraphTable from '../../components/common/GraphTable'

function Page (props) {
  return (
    <Layout>
      <PaperBody noPadding>
        <GraphTable
          title='Upcoming Fixtures'
          type='fixtures'
          query={`
          query ($where: [fixtures_bool_exp!], $startTime: timestamptz, $limit:Int, $offset:Int) {
            fixtures(where: {scheduledStartTime: {_gt: $startTime}, _and: $where}, limit: $limit, offset: $offset, order_by: {scheduledStartTime: asc}) {
              sportId
              sportName
              fixtureId
              name
              competitionId
              seasonId
              roundId
              scheduledStartTime
              isAvailable
              _lastUpdatedAt
            }

            fixtures_aggregate(where: {scheduledStartTime: {_gt: $startTime}, _and: $where}) {
              aggregate {
                count
              }
            }
          }
        `}
          serverPagination
          serverFilters
          searchFields={['name', 'competitionName']}
          variables={{
            startTime: new Date().toISOString()
          }}
          columnAttributes={{
            scheduledStartTime: {
              title: 'Start Time',
              type: 'datetime'
            },
            sportName: {
              hidden: true,
              filter: true
            },
            seasonId: {
              hidden: true
            },
            roundId: {
              hidden: true
            },
            isAvailable: {
              type: 'boolean'
            }
          }}
        />
      </PaperBody>
    </Layout>
  )
}

export default Page
