import parseISO from 'date-fns/parseISO'

/* eslint-disable-next-line no-useless-escape */
export const isoDateRegex = /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3])\:[0-5]\d|24\:00)(\:[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3])\:?([0-5]\d)?)?)?$/

export function localeFormatDate(value, format: 'date'|'datetime'|'time', locale) {
  let obj = null
  if (value instanceof Date) {
    obj = value
  } else if (isoDateRegex.test(value)) {
    obj = parseISO(value)
  } else {
    return value
  }

  switch (format) {
    case 'date': return obj.toLocaleDateString(locale)
    case 'time': return obj.toLocaleTimeString(locale)
    default: return obj.toLocaleString(locale)
  }
}

export function parseDate(value) {
  try {
    return new Date(value).toISOString()
  } catch (e) {
    return false
  }
}
