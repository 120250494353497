import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import SimpleAutocomplete from '../common/SimpleAutocomplete'
import QuickSnack from '../common/QuickSnack'
import { titleCaps, loadCustomerData, checkCustomerType } from '../../util'

import { navigate } from '@reach/router'
import SimpleFileUpload from '../common/SimpleFileUpload'
import { useApi } from '../../context/ApiContext'
import { useCache } from '../../hooks/cache'
import { usePopup } from '../../hooks/modals'

import SteppedDialog, { SteppedDialogFinishAction } from '../common/SteppedDialog'
import ImportDataTable from '../common/ImportDataTable'
import { CustomerDataCustomer, CustomerDataType } from '../../api/CustomerData'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: 0,
    '& > *': {
      marginRight: theme.spacing(2)
    }
  },
  fileFab: {
    marginRight: theme.spacing(4)
  }
}))

export interface ImportCustomerDataProps {
  customerId: string,
  type?: string,
  types?: Array<CustomerDataType>
  defaultType?: string
  open?: boolean,
  onClose?: () => any,
}

function ImportCustomerDataDialog({ customerId, type: initialType = '', open, onClose }: ImportCustomerDataProps) {
  const { customerData } = useApi()
  const { data: customers, refresh } = useCache(customerData, 'listCustomers', {
    fallback: customerData.listTokenCustomers()
  })

  const classes = useStyles()
  const setPopup = usePopup()
  const [type, setCustomerType] = React.useState(initialType)
  const [data, setData] = React.useState([])
  const [reset, setReset] = React.useState(false)

  const customer: CustomerDataCustomer = customers.find(customer => (customer.customerId === customerId))
  const foundCustomerType = customer && customer.types && !!customer.types.map(item => item.type).find(customerType => (customerType === type))

  React.useEffect(() => {
    clearForm()
    // eslint-disable-next-line
  }, [customerId])

  const clearForm = () => {
    setReset(true)
    setCustomerType(initialType)
    setData([])
  }

  const handleClose = () => {
    clearForm()
    onClose && onClose()
  }

  const loadData = (file) => {
    setReset(false)
    if (!file) { setData([]); return }
    try {
      setData(loadCustomerData(file))
    } catch (e) {
      setPopup(<QuickSnack title='We cant load your file!' severity='error'>{e.toString()}</QuickSnack>)
    }
  }

  const saveData = async (replace = false) => {
    const fun = (replace
      ? customerData.setCustomerData
      : customerData.addCustomerData).bind(customerData)
    return fun(customerId, type, data).then(() => {
      clearForm()
      refresh()
      navigate(`/customer-data/${customerId}/${type}`)
      return 'Your data have been uploaded.'
    })
  }

  const validType = customer && checkCustomerType(type)
  const validData = !!data && !!data.length
  const validDone = validData && validType

  const steps = [
    {
      label: 'Select type',
      title: 'Give your data a name',
      next: validType,
      content: <React.Fragment>
        <p>Please enter or choose a type name for the data you want to import.</p>
        <SimpleAutocomplete
          autoFocus
          label='Type'
          value={type}
          freeSolo
          options={(customer && customer.types?.map(item => item.type)) || []}
          onChange={(e, data) => setCustomerType(data)}
          required
        />
      </React.Fragment>
    },
    {
      label: 'Import File',
      title: `Import into ${titleCaps(type)}`,
      next: validData,
      content: <React.Fragment>
        <p>Please select a file to load your data from, we accept standard CSV and JSON files in <b>utf-8 encoding</b>.</p>
        <p>CSV files must include header row, JSON files must be an array of objects with the same keys.</p>
        <p>The following fields are treated specially:</p>
        <ul>
          <li><b>key</b>: Is used as an indexed unique key, can be used for entry retrieval.</li>
          <li><b>dateKey</b>: A custom searchable timestamp asociated to the entry (in ISO Date format).</li>
          <li><b>fixtureId</b>, <b>competitorId</b>, <b>competitionId</b>, <b>seasonId</b>, <b>roundId</b> and <b>marketTypeId</b> are used to relate your data to core entities.</li>
        </ul>
        <p>Any other fields included in your data will be saved as custom data.</p>
        <div className={classes.buttonGroup}>
          <SimpleFileUpload
            id='bulkdata'
            accept='.csv,text/csv,.json,application/json'
            reset={reset}
            onChange={(data) => loadData(data)}
            className={classes.fileFab}
          />
          <a href='/example.csv' target='_blank'>Example CSV</a>
          <a href='/example.json' target='_blank' download>Example JSON</a>
        </div>
      </React.Fragment>
    },
    {
      label: 'Confirm Import',
      title: 'Preview',
      next: validDone,
      content: <React.Fragment>
        {validType && <p>We will import <b>{data.length}</b> entries for <b>{type}</b>,
          only users authorized for customerId <b>{customerId}</b> will be able to see the data.
        </p>}
        <p>Please review if the data appears correctly.
          Object and array fields from JSON files are excluded from the view but will still be accessible through the Content Graph.
        </p>
        <ImportDataTable data={data.slice(0, 5)} />
      </React.Fragment>
    }
  ]

  const finishSteps: SteppedDialogFinishAction[] = [
    {
      label: `Add ${data.length} records for ${type}`,
      tooltip: 'This will add/update the translations in your file without replacing anything previously stored.',
      disabled: !validDone,
      onClick: () => saveData(false)
    }
  ]

  if (foundCustomerType || initialType) {
    finishSteps.push({
      label: `Replace all records for ${type}`,
      tooltip: 'This will delete ALL the data previously stored for the chosen type!',
      disabled: !validDone,
      onClick: () => saveData(true)
    })
  }

  return (
    <React.Fragment>
      <SteppedDialog open={open} step={initialType ? 1 : 0} onClose={handleClose} steps={steps} finish={finishSteps} />
    </React.Fragment>
  )
}

export default ImportCustomerDataDialog
