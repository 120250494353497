import { styled } from '@mui/material/styles'

export const CustomDivider = styled('div')`
    margin-left: ${props => props.theme.spacing(2)};
    margin-right: ${props => props.theme.spacing(2)};
    border-top: 1px solid ${props => props.theme.palette.grey[200]};
    padding-bottom: ${props => props.theme.spacing(1)};
`

export default CustomDivider
