
import React from 'react'
import HasuraTrafficReports from '../components/reports/HasuraTrafficReports'
import Layout from '../components/common/layout/Layout'

function Content () {
  return (
    <Layout>
      <HasuraTrafficReports />
    </Layout>
  )
}

export default Content
