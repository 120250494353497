import NodeCache from 'node-cache'
import { queryCacheTTL } from '../config/config'
import { isPromise } from '../util'

export interface GraphCache extends NodeCache {
  hasData: (key: string) => boolean,
  getData: (key: string, defaultValue?: any) => any,
  getPromise: (key: string) => Promise<any>,
  setPromise: (key: string, fn: () => Promise<any>, ttl?: number) => Promise<any>,
  isPromise: (key: string) => boolean
}

export interface MemoizedFn extends Function {
  memoized?: boolean,
  raw: Function,
}

const stores: {[storeId: string] : GraphCache} = {}

export function flushCache() {
  Object.keys(stores).forEach(store => stores[store].flushAll())
}

export function buildKey(method: keyof any, args?) {
  return `${String(method)}:${args ? JSON.stringify(args) : 'default'}`
}

export function getRawFetcher<T extends MemoizedFn>(fn: T, api?: Object): T {
  if (api) fn = fn.bind(api)
  return (fn.memoized) ? fn.raw as T : fn
}

export function memoize<T extends Object, K extends keyof T>(api: T, method: K, ttl?: number): T[K] {
  const cache = getStore(api.constructor.name)
  const fetcher = typeof api[method]==='function' ? getRawFetcher(api[method] as any, api) : () => api[method]

  const fn = function() {
    const key = buildKey(method, arguments)
    if (!cache.has(key)) {
      return cache.setPromise(key, fetcher.apply(this, arguments), ttl)
    }
    return cache.getPromise(key)
  } as any

  fn.memoized = true
  fn.raw = fetcher
  return fn
}

export function getStore(store: string): GraphCache {
  if (typeof stores[store] === 'undefined') {
    const cache = new NodeCache({
      stdTTL: queryCacheTTL, checkperiod: 0, useClones: false, deleteOnExpire: true
    })
    stores[store] = Object.assign(cache, {
      hasData: (key) => {
        const value = cache.has(key) && cache.get(key)
        return value && !isPromise(value)
      },
      getData: (key, defaultValue = null) => {
        const value = cache.has(key) && cache.get(key)
        return (!isPromise(value) && value) || defaultValue
      },
      setPromise: (key, prom, ttl) => {
        const val = prom.then(
          results => {
            cache.set(key, results, ttl)
            return results
          },
          error => {
            if (cache.has(key) && isPromise(cache.get(key))) cache.del(key)
            return Promise.reject(error)
          })
        if (!cache.has(key)) cache.set(key, val, ttl)
        return val
      },
      getPromise: (key) => {
        const value = cache.has(key) && cache.get(key)
        return isPromise(value) ? (value as Promise<any>) : Promise.resolve(value)
      },
      isPromise: (key) => {
        const value = cache.has(key) && cache.get(key)
        return isPromise(value)
      }
    })
  }
  return stores[store]
}