import RestApi, { GET, PUT, PATCH, DELETE } from './RestApi'
import { getAttribute, getUserRole } from './Auth'
import { memoize } from './Cache'
import uniq from 'lodash/uniq'

export interface TranslationsApiCustomers {
  [string: string]: string[]
}

export interface TranslationsCustomer {
  customerId: string,
  locales: string[],
  canWrite?: boolean
}

export interface Translations {
  [string: string]: string
}

export default class TranslationsApi extends RestApi {
  name = 'TranslationsApi'

  constructor(api, client) {
    super(api, client)
    this.listApiCustomers = memoize(this, 'listApiCustomers')
    this.listCustomers = memoize(this, 'listCustomers')
  }

  listTokenCustomers (raw: true): [string[], string[]];
  listTokenCustomers (raw?: false): TranslationsCustomer[];
  listTokenCustomers (raw: boolean = false): [string[], string[]] | TranslationsCustomer[] {
    const readAllowedCustomers: string[] = getAttribute('customer_translations_sources')
    const writeAllowedCustomers: string[] = getAttribute('customer_translations_writes')
    const allCustomers = Array.from(new Set(readAllowedCustomers.concat(writeAllowedCustomers)));

    return raw ? [
      allCustomers, // Read or write
      writeAllowedCustomers,
     ] : allCustomers.map(customerId => ({
      customerId,
      locales: [],
      canWrite: writeAllowedCustomers.includes(customerId)
    }))
  }

  userIsAdmin (): boolean {
    const role = getUserRole()
    return role === 'admin' || role === 'internal'
  }

  getLocaleUrl (customerId: string, locale: string): string {
    return `${this.apiUrl}/api/customers/${customerId}/locale/${locale}`
  }

  async listCustomers (): Promise<TranslationsCustomer[]> {
    const apiCustomers = (await this.listApiCustomers()) || {}
    const [readAllowedCustomers, writeAllowedCustomers] = this.listTokenCustomers(true)
    const allCustomers = uniq(Object.keys(apiCustomers).concat(readAllowedCustomers.concat(writeAllowedCustomers)));

    return allCustomers.map(customerId => ({
      customerId,
      locales: uniq(apiCustomers[customerId] || []),
      canWrite: writeAllowedCustomers.includes(customerId) || this.userIsAdmin()
    }))
  }

  async listLocales (): Promise<string[]> {
    return Array.from((await this.listCustomers()).reduce((acc, customer) => {
      customer.locales.forEach(locale => acc.add(locale))
      return acc
    }, new Set<string>()))
  }

  async listApiCustomers (): Promise<TranslationsApiCustomers> {
    return this.api(GET, 'api/customers')
  }

  async listCustomerLocales (customerId: string): Promise<string[]> {
    return this.api(GET, `api/customers/${customerId}`)
  }

  async getLocale (customerId: string, locale: string): Promise<Translations> {
    return this.api(GET, `api/customers/${customerId}/locale/${locale}`)
  }

  async setLocale (customerId: string, locale: string, data: Translations): Promise<string> {
    return this.api(PUT, `api/customers/${customerId}/locale/${locale}`, JSON.stringify(data))
  }

  async updateLocale (customerId: string, locale: string, data: Translations): Promise<string> {
    return this.api(PATCH, `api/customers/${customerId}/locale/${locale}`, JSON.stringify(data))
  }

  async deleteLocale (customerId: string, locale: string): Promise<string> {
    return this.api(DELETE, `api/customers/${customerId}/locale/${locale}`)
  }

  async getTranslation (customerId: string, locale: string, sourceText: string): Promise<string> {
    return this.api(GET, `api/customers/${customerId}/locale/${locale}/translation?sourceText=${sourceText}`)
  }

}