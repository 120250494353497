import React from 'react'
import Layout from '../../components/common/layout/Layout'

function Translations (props) {
  return (
    <Layout>
      {props.children}
    </Layout>
  )
}

export default Translations
