import React from 'react'
import Paper from '@mui/material/Paper'
import type { ContainerProps } from '@mui/material/Container'
import type { PaperProps } from '@mui/material/Paper'
import clsx from 'clsx'
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: "8px",
      overflow: 'hidden'
    },
    noPadding: {
      padding: 0
    },
    noMargin: {
      marginLeft: 0,
      marginRight: 0
    }
  }))

export interface PaperBodyProps extends PaperProps {
  noPadding?: boolean,
  noMargin?: boolean,
  disabled?: boolean // TODO: check if this really works!
  maxWidth?: ContainerProps['maxWidth'] // TODO: implement if needed
}

function PaperBody (props: PaperBodyProps) {
  const { noPadding, noMargin, maxWidth, className, ...rest } = props
  const classes = useStyles()
  const paperClasses = [classes.root]
  if (noPadding) paperClasses.push(classes.noPadding)
  if (noMargin) paperClasses.push(classes.noMargin)
  if (className) paperClasses.push(className)

  return (
    <Paper variant="outlined" className={clsx(...paperClasses)} {...rest} />
  )
}

export default PaperBody
