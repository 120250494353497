import React from 'react'
import Users from '../components/users/Users'
import Layout from '../components/common/layout/Layout'

function Content (props) {
  return (
    <Layout>
      <Users />
    </Layout>
  )
}

export default Content
