import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Tooltip from '@mui/material/Tooltip'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import makeStyles from '@mui/styles/makeStyles';
import { isPromise } from '../../util'
import GraphTableSnackbar from '../common/GraphTable/GraphTableSnackbar'
import { usePopup } from '../../hooks/modals'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1)
  },
  content: {
    margin: theme.spacing(2),
    marginTop: 0
  },
  spaced: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  spacedTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(2)
  }
}))

export interface SteppedDialogStep {
  label: string | JSX.Element,
  title?: string | JSX.Element,
  content: string | JSX.Element,
  next?: boolean /** if next step is enabled */
}

export interface SteppedDialogFinishAction {
  label?: string | JSX.Element,
  tooltip?: string | JSX.Element,
  onClick?: (index?: number) => string | void | Promise<string|void>,
  disabled?: boolean,
}

export interface SteppedDialogProps {
  title?: string,
  step?: number,

  open?: boolean,
  onClose?: () => any,
  onFinish?: () => any,

  steps: SteppedDialogStep[],
  finish: SteppedDialogFinishAction | SteppedDialogFinishAction[]

  disabled?: boolean
}

function SteppedDialog ({ title, step: initialStep = 0, open, onClose, onFinish, steps, finish, disabled: disabledProp }: SteppedDialogProps) {
  const [disabledState, setDisabled] = React.useState(false)
  const setPopup = usePopup()
  const [step, setStep] = React.useState(initialStep)
  const classes = useStyles()
  const disabled = disabledState || disabledProp

  const nextDisabled = !(steps[step].next && steps[step + 1])
  const isLast = () => step >= steps.length - 1
  finish = Array.isArray(finish) ? finish : [finish]

  const handleClose = () => {
    onClose && onClose()
    setStep(initialStep)
  }

  const handleFinish = (handler?) => {
    return () => {
      const ret = handler ? handler() : (onFinish && onFinish())
      if (isPromise(ret)) {
        setDisabled(true)
        ret.then((success) => {
          setPopup(<GraphTableSnackbar message={success || 'Done!'} />)
          handleClose()
        }, (error) => {
          setPopup(<GraphTableSnackbar error={error} />)
        }).finally(() => setDisabled(false))
      } else {
        setPopup()
        handleClose()
      }
    }
  }

  const handlePrevious = () => (step < 1) ? handleClose() : setStep(step - 1)
  const handleNext = () => {
    isLast() ? handleFinish() : setStep(step + 1)
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth='sm'>
      {title && <DialogTitle id='form-dialog-title'>{title}</DialogTitle>}
      <Stepper activeStep={step}>
        {steps.map(({ label }, index) => <Step key={index}>
          <StepLabel>{label}</StepLabel>
                                         </Step>)}
      </Stepper>
      <div className={classes.content}>
        {steps[step] && steps[step].title && <h2 className={classes.spacedTitle}>{steps[step].title}</h2>}
        {steps[step] && steps[step].content}
      </div>
      <DialogActions>
        <Button className={classes.button} onClick={handlePrevious} color='primary' disabled={disabled}>
          {step > 0 ? 'Back' : 'Close'}
        </Button>
        {(isLast() && finish) ? finish.map(({ label, tooltip, disabled }, index) => {
          const button = <Button key={index} className={classes.button} onClick={handleFinish(finish[index].onClick)} variant='contained' color='primary' disabled={disabled}>
            {label}
                         </Button>
          return tooltip ? <Tooltip key={index} title={tooltip} placement='top'>{button}</Tooltip> : button
        }) : <Button className={classes.button} onClick={handleNext} color='primary' variant='contained' disabled={disabled || nextDisabled}>
          {isLast() ? 'Finish' : 'Next'}
        </Button>}
      </DialogActions>
    </Dialog>
  )
}

export default SteppedDialog
