import { deepMerge } from '../util'
import baseData from './autocomplete.json'

export const baseOptions = {
  // FROM https://opengrok.prod.aws.geniussports.com/source/xref/DotNet/GlobalConstants/Source/App/GlobalConstants/CompanyIds.cs
  sourceId: [
    { id: 'Betgenius-ISD', name: 'Betgenius PBP1' },
    { id: 'Betgenius-ISD2', name: 'Betgenius PBP2' },
    { id: 'Betgenius', name: 'Betgenius Fixtures' },
    { id: '0', name: 'Betgenius MS' },
    { id: '-1', name: 'RunningBall' },
    { id: '-2', name: 'Enetpulse' },
    { id: '-3', name: 'RealTimeSportscast' },
    { id: '-4', name: 'SportingPulse' },
    // { id: '-99999', name: 'SportingPulseTest' },
    { id: '-5', name: 'BetgeniusProduction' },
    { id: '-6', name: 'GlobalBettingServices' },
    { id: '-7', name: 'IMG' },
    { id: '-8', name: 'Sportsman' },
    { id: '-9', name: 'SIS' },
    { id: '-10', name: 'ChampionData' },
    { id: '-11', name: 'MLB' },
    { id: '-12', name: 'Spreadex' },
    { id: '-13', name: 'InPlaySportsData' },
    { id: '-14', name: 'AutoFeed' },
    { id: '-15', name: 'Media' },
    { id: '-16', name: 'PressAssociation' },
    { id: '17', name: 'SpreadexProduction' },
    { id: '-18', name: 'STATS' },
    { id: '-19', name: 'Omnisoft' },
    { id: '-21', name: 'Faceit' },
    { id: '-22', name: 'Jarlon' },
    { id: '-50', name: 'PreMatchTennisLevel1' },
    { id: '-60', name: 'PreMatchTennisLevel2' },
    { id: '-23', name: 'FIBA' },
    { id: '-24', name: 'DataProject' },
    { id: '-40', name: 'PrematchFootballPrices' },
    { id: '-30', name: 'UKEarlyPrices' },
    { id: '-20', name: 'AUSEarlyPrices' },
    { id: '-365', name: 'Bet365' },
    { id: '-25', name: 'OneXBet' },
    { id: '-26', name: 'Betradar' },
    { id: '6462', name: 'AlgoTrading' },
    { id: '9257', name: 'NFL NGS' },
    { id: '50007', name: 'NFL GSIS' }
  ]
}

export const fetchQuery = `
query {
  competitionId: competitions {
    id: competitionId
    name
  }

  sportId: sports {
    id: sportId
    name
  }

  sourceId: markets (distinct_on: [sourceId]){
    id: sourceId
    name: sourceName
  }
}
`

export const autoCompleteData = deepMerge(baseData || {}, { data: baseOptions }, true)
