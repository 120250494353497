import React from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useAutoComplete } from '../../hooks/autocomplete'
import { arrayToMap, titleCaps } from '../../util'

import type { RoleAttribute } from '../../api/UsersApi'

function RoleAutocomplete ({ attribute, data, onChange, ...props }: {attribute: RoleAttribute, data: Array<string>, onChange?: (e, data) => void}) {
  const optionsToMap = arr => arrayToMap(arr, 'id', false)
  const flattenData = data => Array.isArray(data) && data.map(item => getId(item))
  const fattenData = ids => Array.isArray(ids) && ids.map(id => (optionsMap && optionsMap[id]) || id)
  const getId = item => typeof item === 'object' ? item.id : item
  const getName = item => typeof item === 'object' ? `${item.name} (${item.id})` : item

  const options = useAutoComplete(attribute.type)
  const optionsMap = optionsToMap(options)

  const filterOptions = createFilterOptions({
    limit: 50
  })

  return (
    <Autocomplete
      {...props}
      multiple
      id={`tags-${attribute.name}`}
      filterOptions={filterOptions}
      options={options || []}
      getOptionLabel={getName}
      isOptionEqualToValue={(a, b) => getId(a) === getId(b)}
      defaultValue={fattenData(data) || []}
      onChange={(e, data) => {
        onChange && onChange(attribute.name, flattenData(data))
      }}
      openOnFocus={false}
      clearOnBlur
      selectOnFocus
      filterSelectedOptions
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label={titleCaps(attribute.name)}
          helperText={attribute.description}
          fullWidth
        />
      )}
    />
  );
}

export default RoleAutocomplete
