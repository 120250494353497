import decodeJwt from 'jwt-decode'
import { authEndpoint, permissionsClaim, roleClaim, attributesClaim, adminRole, newPasswordRequired } from '../config/config'
import { fetchJson } from './HttpClient'

let clear = null

export function needsNewPassword (reason) {
  return reason && newPasswordRequired.test(reason)
}

export function logout () {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  return Promise.resolve()
}

export function login (props) {
  const { username, password, newPassword, onRefresh } = props
  if (clear) clearTimeout(clear)

  const request = new Request(`${authEndpoint}/login`, {
    method: 'POST',
    body: JSON.stringify({ username, password, newPassword }),
    headers: new Headers({ 'Content-Type': 'application/json' })
  })
  return (fetch(request)
    .catch(() => Promise.reject(new Error('Check your internet connection!')))
    .then(response => response.status < 500 ? response.json() : Promise.reject(new Error('Service unavailable')))
    .then(response => response.error ? Promise.reject(new Error(response.message || response.error)) : response)
    .then(({ token, expiresIn, lastLogIn }) => {
      localStorage.setItem('username', username)
      localStorage.setItem('token', token)
      localStorage.setItem('lastLogIn', lastLogIn)
      clear = setTimeout(() => {
        logout()
        typeof onRefresh === 'function' && onRefresh()
      }, (expiresIn * 960))
    }))
}

export function getLastLogInDate () {
  const lastlogin = localStorage.getItem('lastLogIn') || null
  return lastlogin != null ? new Date(lastlogin).toLocaleDateString() : null
}

export function getToken () {
  return localStorage.getItem('token') || null
}

export function getDecoded () {
  const token = getToken()
  try {
    return token ? decodeJwt(token) : {}
  } catch (e) {
    return {}
  }
}

export function getUsername () {
  const user = getDecoded()
  return user && user['cognito:username']
}

export function checkAuth () {
  return localStorage.getItem('token') ? Promise.resolve() : Promise.reject(new Error('Token not found'))
}

export function isAdmin () {
  return (getDecoded()[roleClaim] === adminRole)
}

export function getPermissions () {
  return (getDecoded()[permissionsClaim] || '').split(',')
}

export function hasPermission (permission) {
  return getPermissions().includes(permission)
}

export function getUserRole () {
  return getDecoded()[roleClaim] || ''
}

export function getRoleAttributes () {
  try {
    return JSON.parse((getDecoded()[attributesClaim] || '{}'))
  } catch (e) {
    return {}
  }
}

export function getAttribute (attribute) {
  return getRoleAttributes()[attribute] || []
}

export const HttpClient = (url, options = {}) => fetchJson(url, {
  ...options,
  token: getToken()
})

export const Auth = {
  login,
  logout,
  checkAuth
}

export const Role = {
  isAdmin,
  getPermissions,
  hasPermission,
  getAttributes: getRoleAttributes,
  getAttribute
}

export default Auth
