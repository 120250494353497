export const baseKeys = ['key', 'date', 'dateKey', 'fixtureId', 'competitorId', 'competitionId', 'seasonId', 'roundId', 'marketTypeId']

export {
  trim,
  titleCaps,
  stripAt,
  stripTypePluralSuffix,
  stripIdSuffix,
  checkCustomerType,
  sanitizeType,
  sanitizeHeader,
  replaceVars
} from './string'

export {
  loadCustomerData,
  loadTranslations,
  loadJSON,
  loadCSV
} from './data'

export {
  localeFormatDate,
  parseDate
} from './datetime'

export function random (max = 1024) {
  return Math.floor(Math.random() * max)
}

export function filterBaseKeys (arr, keep = []) {
  return arr ? arr.filter(key => !baseKeys.includes(key) || keep.includes(key)) : []
}

/**
 * Convert an array into a map
 *
 * [
 *  {name: a, value: x}
 *  {name: b, value: y}
 * ]
 *
 * becomes:
 * {
 *  a: {value: x},
 *  b: {value: y}
 * }
 *
 * @param {object[]} source
 * @param {string} [key=name]
 * @param {boolean} [removeKey=true]
 * @returns {{[]:{}}}
 */
export function arrayToMap (source, key = 'name', removeKey = true) {
  return source && Array.isArray(source) ? source.reduce((map, current) => {
    const keyValue = current[key]
    if (keyValue === undefined) {
      throw new Error(`${key} missing in ${JSON.stringify(current)}.`)
    }

    if (removeKey) {
      const item = Object.assign({}, current)
      delete item[key]
      map[keyValue] = item
    } else {
      map[keyValue] = current
    }

    return map
  }, {}) : source
}

export function mapToArray (map, keyName = 'key', valueName = 'value') {
  return map ? Object.keys(map).map(key => ({
    [keyName]: key,
    [valueName]: map[key]
  })) : []
}

/**
 * Deep merge optionally concatenating arrays on both objects
 *
 * @param {object} target
 * @param {object} source
 * @param {boolean} [catArrays=false] concatenates arrays
 */
export function deepMerge (target, source, catArrays = false) {
  const isObject = a => a && typeof a === 'object' && !isArray(a)
  const isArray = a => a && Array.isArray(a)

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) target[key] = {}
        deepMerge(target[key], source[key], catArrays)
      } else if (catArrays && isArray(target[key]) && isArray(source[key])) {
        target[key].push(...source[key])
      } else {
        target[key] = source[key]
      }
    }
  }

  return target
}

export function isPromise(val) {
  return !!val && typeof val['then']==='function'
}
