
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Chip from '@mui/material/Chip'
import NoDataAvailablePlaceholder from './NoDataAvailablePlaceholder'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    placeholder: {
      padding: theme.spacing(2),
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.light
    },
    itemChip: {
      margin: theme.spacing(1)
    }
  })
)

export interface NavGridItem {
  title: string,
  target: string,
  count?: number
}

export interface NavGridProps {
  items: NavGridItem[],
  placeholder?: JSX.Element
}

function GridItem({ classes, item }) {
  const itemCount = (item.count ? ' (' + item.count + ')' : '')
  const itemLabel = item.title + itemCount
  return (
    <Chip label={itemLabel}
      className={classes.itemChip}
      component="a"
      href={item.target}
      clickable
    />)

}

function NavGrid({ items, placeholder }: NavGridProps) {
  const classes = useStyles()
  return items && items.length ? (
    <div>
      {items.map(item => <GridItem key={item.target} classes={classes} item={item}/>)}
    </div>
  ) : (
    placeholder || <NoDataAvailablePlaceholder />
  )

}

export default NavGrid
