import React from 'react'
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import { G00, GeniusTheme } from '../../enhancers/withGeniusTheme'
import Box from '@mui/system/Box'

export type ButtonCardProps = {
  link: string,
  text: string,
  icon: string,
  variant: 'contained' | 'outlined',
  externalLink: boolean
}

function ButtonCard(props: ButtonCardProps) {
  let bgButton: string
  let bgAvatar: string
  let iconColor: string
  let borderColor: string
  let textColor: string
  let hoverColor: string
  let hoverBorderColor: string
  let hoverBgAvatar: string
  if (props.variant === 'contained') {
    bgButton = GeniusTheme.palette.GSB[400]
    bgAvatar = GeniusTheme.palette.GSB[500]
    iconColor = G00
    borderColor = GeniusTheme.palette.GSB[400]
    textColor = G00
    hoverColor = GeniusTheme.palette.GSB[500]
    hoverBorderColor = GeniusTheme.palette.GSB[500]
    hoverBgAvatar = GeniusTheme.palette.GSB[600]
  } else {
    bgButton = G00
    bgAvatar = GeniusTheme.palette.GSB[50]
    iconColor = GeniusTheme.palette.GSB[400]
    borderColor = GeniusTheme.palette.grey[200]
    textColor = GeniusTheme.palette.grey[700]
    hoverColor = GeniusTheme.palette.grey[50]
    hoverBorderColor = GeniusTheme.palette.grey[200]
    hoverBgAvatar = GeniusTheme.palette.GSB[50]

  }

  return (
    <Button
      variant={props.variant} href={props.link}
      target={props.externalLink ? '_blank' : '_self'}
      sx={{
        bgcolor: bgButton,
        paddingRight: '7px',
        borderRadius: '8px',
        width: '237px',
        height: '80px',
        border: '1px solid ' + borderColor,
        display: 'flex',
        justifyContent: 'space-between',
        '&:hover': {
          background: hoverColor,
          borderColor: hoverBorderColor,
          '& div': {
            background: hoverBgAvatar
          }
        }
      }}
    >
      <Avatar sx={{ bgcolor: bgAvatar, width: 56, height: 56 }}>
        <Icon baseClassName='material-icons-sharp' sx={{ color: iconColor }}>
          {props.icon}
        </Icon>
      </Avatar>
      <span style={{ fontFamily: 'Red Hat Text', fontWeight: 500, fontSize: '1.1em', color: textColor }}>{props.text}</span>
      {
        props.externalLink
          ? <OpenInNewSharpIcon sx={{ fontSize: '16px', alignSelf: 'flex-end' }} />
          : <Box />
      }

    </Button>
  )
}

export default ButtonCard
