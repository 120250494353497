import React from 'react'
import TextField from '@mui/material/TextField'

import { useStyles } from './styles'
import type { ObjectEditorFieldProps, ObjectEditorProps } from './types'

import deepClone from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'

function ObjectEditorField ({ title = '', placeholder, description, type, disabled, hidden, error, value, cleanup, onChange }: ObjectEditorFieldProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event, cleanup ? cleanup(event.target.value) : event.target.value)
  }

  return (
    <TextField
      label={title}
      placeholder={placeholder || title}
      helperText={description}
      fullWidth
      disabled={disabled}
      hidden={hidden}
      value={value}
      error={error}
      onChange={handleChange}
    />
  )
}

function ObjectEditor ({ data = {}, fields = [], disabled, onChange, onChangeField }: ObjectEditorProps) {
  const classes = useStyles()

  const handleChange = (field: string) => (e: React.ChangeEvent<any> | null, newValue: any) => {
    onChangeField && onChangeField(e, field, newValue)
    onChange && onChange(e, set(deepClone(data), field, newValue))
  }

  return <div className={classes.container}>
    {fields.map(field => (
      <ObjectEditorField key={field.field} {...field} disabled={disabled || field.disabled} value={get(data, field.field)} onChange={handleChange(field.field)} />
    ))}
  </div>
}

export default ObjectEditor
