import React from 'react'
import PaperBody from '../../components/common/layout/PaperBody'
import RefreshIcon from '@mui/icons-material/Refresh'
import Alert from '@mui/material/Alert'
import Wrapper from './Wrapper'
import Loading from '../../components/common/Loading'
import HeaderWithActions from '../../components/common/HeaderWithActions'
import ImportDialog from '../../components/translations/TranslationsImportDialog'
import CustomerBody from '../../components/translations/TranslationsCustomerBody'
import Box from '@mui/system/Box'
import { useStyles } from '../../components/common/ObjectEditor/styles'
import { useTranslations } from '../../context/ApiContext'
import { useCache } from '../../hooks/cache'
import { titleCaps } from '../../util'
import { styled } from '@mui/material/styles'
import CustomDivider from '../../components/common/CustomDivider'
import CustomBold from '../../components/common/CustomBold'

export interface TranslationsProps {
  customerId?: string,
  noWrapper?: boolean
}

const TranslationTitle = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).h5.fontFamily}`,
  fontSize: `${(theme.typography as any).h5.fontSize}`,
  lineHeight: theme.spacing(4)
}))

const Spaced = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2)
}))

const HalfSpaced = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1)
}))

const TranslationMessage = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).subtitle1.fontFamily}`,
  fontSize: `${(theme.typography as any).h6.fontSize}`,
  color: `${(theme.palette.grey['400'])}`
}))

function Translations({ customerId, noWrapper }: TranslationsProps) {
  const translationsApi = useTranslations()
  const classes = useStyles()
  const [importOpen, setImportOpen] = React.useState(false)
  const [importCustomerId, setImportCustomerId] = React.useState('')
  const { data, isLoading, refresh } = useCache(translationsApi, 'listCustomers', {
    fallback: []
  })
  const customers = customerId ? data.filter(customer => customer.customerId === customerId) : data

  const refreshAction = {
    title: 'Refresh list',
    onClick: () => refresh(),
    icon: RefreshIcon
  }

  const handleImport = (customerId, close = false) => {
    setImportCustomerId(customerId)
    setImportOpen(!close)
  }

  const content = (
    <React.Fragment>
      <ImportDialog customerId={importCustomerId} open={importOpen} onClose={() => setImportOpen(false)} />
      {!isLoading && customers.length === 0 && <PaperBody noPadding><Alert severity='warning'>You currently have no access to {customerId ? `translations for ${titleCaps(customerId)}` : 'the Translations API'}, please contact our team to request it.</Alert></PaperBody>}
      {!customerId &&
        <div>
          <Box className={classes.commonContainer}>
            <HeaderWithActions defaultAction={refreshAction}><TranslationTitle><b>Our data, your language</b></TranslationTitle></HeaderWithActions>
            <TranslationMessage>
              View available translations or upload your own in <CustomBold>JSON</CustomBold> or <CustomBold>CSV</CustomBold> formats.
            </TranslationMessage>
            <Spaced />
          </ Box>
          <CustomDivider />
          <HalfSpaced />
          <Box className={classes.commonContainer}>
            <TranslationMessage>
              Every translation will be applied to <CustomBold>competitor names, selection names, market outcomes, match plays ids</CustomBold> and <CustomBold>marketTypeIds</CustomBold>.
            </TranslationMessage>
          </Box>
        </div>
      }
      {isLoading ? <PaperBody><Loading /></PaperBody> : customers.map((customer) => <CustomerBody key={customer.customerId} customer={customer} onImportClick={handleImport} />)}
    </React.Fragment>
  )

  return noWrapper ? content : <Wrapper>{content}</Wrapper>
}

export default Translations
