import React from 'react'
import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import { CostChipsConfigs } from '../../../enhancers/CostChipColor'
import QUERY_COSTS_LIMITS from './CostChipConfiguration'
import Tooltip from '@mui/material/Tooltip'


const ChipAnalyzing = styled(Chip)`
    background-color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).analyzing.background};
    color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).analyzing.text}
`
const ChipOptimal = styled(Chip)`
    background-color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).optimal.background};
    color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).optimal.text}
`
const ChipNormal = styled(Chip)`
    background-color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).normal.background};
    color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).normal.text}
`
const ChipHigh = styled(Chip)`
    background-color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).high.background};
    color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).high.text}
`
const ChipHeavy = styled(Chip)`
    background-color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).heavy.background};
    color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).heavy.text}
`
const ChipError = styled(Chip)`
    background-color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).error.background};
    color: ${props => ((props.theme.palette as any).costChipsConfigs as CostChipsConfigs).error.text}
`

interface CostChipProps {
  queryCost: number
}

const ChipFactory = (queryCostChip) => {
  switch (true) {
    case (queryCostChip === 0):
      return <ChipAnalyzing label='Analyzing' />
    case (queryCostChip === -1):
      return <ChipError label='Error' />
    case queryCostChip < QUERY_COSTS_LIMITS.OPTIMAL:
      return <ChipOptimal label='Optimal' />
    case queryCostChip < QUERY_COSTS_LIMITS.NORMAL:
      return <ChipNormal label='Normal' />
    case queryCostChip < QUERY_COSTS_LIMITS.HIGH:
      return <ChipHigh label='High' />
    default:
      return <ChipHeavy label='Heavy' />
  }
}

function CostChip({ queryCost }: CostChipProps) {
  return <Tooltip title={queryCost}>{ChipFactory(queryCost)}</Tooltip>
}



export default CostChip
