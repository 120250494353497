import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Loading from '../common/Loading'
import RoleEditorAutocomplete from './RoleEditorAutocomplete'
import type { Role, SimpleRole } from '../../api/UsersApi'
import { useApi } from '../../context/ApiContext'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  }
}))

function RoleEditor ({ roleAttributes = {}, role, roles = [], onChange = (attributes) => null } : {roleAttributes:any, role: string, roles: SimpleRole[], onChange: (attributes: any) => void}) {
  const [isLoading, setLoading] = React.useState(true)
  const [roleData, setRoleData] = React.useState<Role>({ name: role })
  const [error, setError] = React.useState(null)
  const { users: api } = useApi()

  const [attributes, setAttributes] = React.useState(roleAttributes || {})
  const classes = useStyles()

  React.useEffect(() => {
    setLoading(true)
    api.getRole(role).then(data => {
      setRoleData(data)
      setLoading(false)
    }).catch(e => {
      setError('We were unable to load the role, please try again later...')
      setLoading(false)
    })
  }, [role, api])

  const updateAttributes = (attributes) => {
    setAttributes(attributes)
    onChange && onChange(attributes)
  }

  const setAttribute = (key, value) => {
    const update = { [key]: value }
    updateAttributes(Object.assign({}, attributes, update))
  }

  return (
    <div className={classes.container}>
      {isLoading
        ? <Loading />
        : ((roleData && roleData.attributes)
          ? roleData.attributes.map((attribute) => (
            <RoleEditorAutocomplete
              key={attribute.name}
              attribute={attribute}
              data={attributes[attribute.name]}
              onChange={(e, data) => setAttribute(attribute.name, data)}
            />
          )) : <p>{error || 'Role has no attributes...'}</p>)}
    </div>
  )
}

export default RoleEditor
