
import React from 'react'
import { customerDataApi, usersApi, hasuraEndpoint, translationsApi, hasuraReportApi } from '../config/config'
import { HttpClient } from '../api/Auth'
import CustomerDataApi from '../api/CustomerData'
import UsersApi from '../api/UsersApi'
import ContentGraph from '../api/ContentGraph'
import TranslationsApi from '../api/Translations'
import HasuraReportApi from '../api/HasuraReport'

const customerData = new CustomerDataApi(customerDataApi, HttpClient)
const users = new UsersApi(usersApi, HttpClient)
const graph = new ContentGraph(hasuraEndpoint, HttpClient)
const translations = new TranslationsApi(translationsApi, HttpClient)
const hasuraReport = new HasuraReportApi(hasuraReportApi, HttpClient)

const ApiContext = React.createContext({
  customerData,
  users,
  graph,
  translations,
  hasuraReport
})

export function useApi () {
  return React.useContext(ApiContext)
}

export function useCustomerData () {
  return useApi().customerData
}

export function useGraph () {
  return useApi().graph
}

export function useTranslations () {
  return useApi().translations
}

export function useHasuraReport () {
  return useApi().hasuraReport
}

export default ApiContext
