import React from 'react'
import PaperBody from '../../components/common/layout/PaperBody'
import TranslationsGraphTable from '../../components/translations/TranslationsGraphTable'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/system/Box'
import { Link } from '@reach/router'
import { Link as MUILink } from '@mui/material';
import { useStyles } from '../../components/common/ObjectEditor/styles'
import ReplyIcon from '@mui/icons-material/Reply';
import { styled } from '@mui/material/styles'
import CustomDivider from '../../components/common/CustomDivider'

const TranslationTitle = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).h5.fontFamily}`,
  fontSize: `${(theme.typography as any).h4.fontSize}`
}))

function Page({ customerId, locale }) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Box className={classes.commonContainer}>
        <TranslationTitle>
          <b>
            Translations for <MUILink
              color='primary'
              href={`/translations`}>
              {customerId}
              <ReplyIcon />
            </MUILink> ({locale})
          </b>
        </TranslationTitle>
      </Box>

      <Tabs value={0} >
        <Tab label='Entries' />
        <Tab component={Link} to='explore' label='Query Explorer' />
      </Tabs>
      <CustomDivider />

      <PaperBody noPadding>
        <TranslationsGraphTable customerId={customerId} locale={locale} />
      </PaperBody>
    </React.Fragment>
  )
}

export default Page
