import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export interface QueryDetailsDialogProperties {
  open: boolean,
  query?: string,
  variables?: string,
  onClose?: () => void,
}

export default function QueryDetailsDialog (props: QueryDetailsDialogProperties) {
  return (
    <Dialog open={props.open || false} onClose={props.onClose} aria-labelledby='alert-dialog-title'>
      <DialogTitle id='form-dialog-title'>Query Details</DialogTitle>
      {(props.query) &&
        <DialogContent>
          <DialogContentText>
            <label>Query</label>
            <textarea disabled rows={10} cols={67} value={props.query.trim()} />
            <label>Variables</label>
            <textarea disabled rows={3} cols={67} value={props.variables.trim()} />
          </DialogContentText>
        </DialogContent>}
      <DialogActions>
        <Button onClick={props.onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
