import React from 'react'
import GraphTable from '../common/GraphTable'
import type { GraphTableAction, GraphTableRowAction, GraphTableRefAttributes } from '../common/GraphTable/types'
import AddIcon from '@mui/icons-material/AddBox'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import EditIcon from '@mui/icons-material/Edit'
import GraphTableSnackbar from '../common/GraphTable/GraphTableSnackbar'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import { useTranslations } from '../../context/ApiContext'
import { usePopup } from '../../hooks/modals'
import ImportDialog from './TranslationsImportDialog'
import ExportDialog from './TranslationsExportDialog'
import EditDialog from './TranslationEditDialog'

function Table ({ customerId, locale }: {customerId: string, locale: string}) {
  const setPopup = usePopup()
  const api = useTranslations()
  const tableRef = React.useRef<GraphTableRefAttributes>(null)
  const [importOpen, setImportOpen] = React.useState(false)
  const [exportOpen, setExportOpen] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [editData, setEditData] = React.useState({})
  const [editOpen, setEditOpen] = React.useState<'edit'|'new'|false>(false)

  const refresh = () => { tableRef && tableRef.current && tableRef.current.refresh() }

  const handleEdit: GraphTableRowAction['onClick'] = (ev, { sourceText, targetText }) => {
    setEditData({ sourceText, targetText })
    setEditOpen('edit')
  }

  const handleAdd: GraphTableAction['onClick'] = (ev) => {
    setEditData({})
    setEditOpen('new')
  }

  const handleCloseEdit = (finished) => {
    setEditOpen(false)
    finished && refresh()
  }

  const handleDeleteAll: GraphTableAction['onClick'] = (ev, actionRefresh) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to DELETE ALL TRANSLATIONS for this locale?')) {
      setIsDeleting(true)
      api.deleteLocale(customerId, locale).then(() => {
        setPopup(<GraphTableSnackbar message={`All your translations for ${locale} have been deleted from the API. It will take a while for this to be reflected on the Content Graph.`} />)
        actionRefresh()
      }).catch(error => {
        setPopup(<GraphTableSnackbar error={error} />)
      }).finally(() => {
        setIsDeleting(false)
      })
    }
  }

  const handleImport: GraphTableAction['onClick'] = (ev) => {
    setImportOpen(true)
  }

  const handleCloseImport = () => {
    setImportOpen(false)
    refresh()
  }

  const handleExport = () => {
    setExportOpen(true)
  }

  const handleCloseExport = () => {
    setExportOpen(false)
  }

  const tableActions: GraphTableAction[] = [
    {
      title: 'Export Translations',
      icon: SaveAltIcon,
      onClick: handleExport
    },
    api.userIsAdmin() && {
      title: 'Delete All Translations',
      icon: DeleteSweepIcon,
      disabled: isDeleting,
      onClick: handleDeleteAll
    },
    {
      title: 'Import Translations',
      icon: LibraryAddIcon,
      onClick: handleImport
    },
    {
      title: 'Add Translation',
      icon: AddIcon,
      onClick: handleAdd
    }
  ]

  const rowActions: GraphTableRowAction[] = [
    {
      title: 'Edit',
      icon: EditIcon,
      onClick: handleEdit,
      hideTitle:true 
    }
  ]       

  return (<React.Fragment>
    <GraphTable
      ref={tableRef}
      type='translations'
      hideTitle
      tableActions={tableActions}
      rowActions={rowActions}
      query={`
        query ($customerId:String, $locale:String, $where: [translations_bool_exp!], $limit:Int, $offset:Int, $orderBy:[translations_order_by!]) {
          translations(where: { customerId: {_eq: $customerId}, locale: {_eq: $locale}, _and: $where }, limit: $limit, offset: $offset, order_by: $orderBy) {
            sourceText
            targetText
            _lastUpdatedAt
          }
        }
      `}
      serverPagination
      serverSort
      serverFilters
      searchFields={['sourceText', 'targetText']}
      variables={{
        customerId,
        locale
      }}
      columnAttributes={{
        sourceText: {
          title: 'Source Text'
        },
        targetText: {
          title: 'Target Text'
        }
      }}
    />
    <ExportDialog customerId={customerId} locale={locale} open={exportOpen} onClose={handleCloseExport} />
    <ImportDialog
      customerId={customerId}
      locale={locale}
      open={importOpen}
      onClose={handleCloseImport}
    />
    <EditDialog
      customerId={customerId}
      locale={locale}
      translation={editData}
      isNew={editOpen === 'new'}
      open={!!editOpen}
      onClose={handleCloseEdit}
    />
  </React.Fragment>)
}

export default Table
