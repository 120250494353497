import React from 'react'
import Layout from '../../components/common/layout/Layout'

function Customers (props) {
  return (
    <Layout>
      {props.children}
    </Layout>
  )
}

export default Customers
