
import React from 'react'
import { HttpClient, Role } from '../api/Auth'
import type { HttpClient as HttpClientType } from '../api/HttpClient'

export type AuthContextType = {
  username?: string,
  token?: string,
  isLoggedIn: boolean,
  login: ({ username, password, newPassword } : { username: string, password: string, newPassword?: string }) => Promise<boolean>,
  logout: () => Promise<void>,
  checkAuth: () => Promise<boolean>,
  getDecoded: () => any,
  fetch: HttpClientType,
  role?: typeof Role
}

const AuthContext = React.createContext({
  isLoggedIn: false,
  login: async () => false,
  logout: async () => {},
  checkAuth: async () => false,
  getDecoded: () => ({}),
  fetch: HttpClient,
  role: Role
} as AuthContextType)

export function useAuth () {
  return React.useContext(AuthContext)
}

export function useRole () {
  return Role
}

export default AuthContext
