/**
 * Everything needed for this to work
 */

const prefix = '/v1' // "https://contentgraph.ci.media.api.geniussports.com/"
export const authEndpoint = `${prefix}/auth`
export const hasuraEndpoint = `${prefix}/graphql`
export const customerDataApi = `${prefix}/customerdata`
export const translationsApi = `${prefix}/translations`
export const hasuraReportApi = `${prefix}/report/hasura`
export const usersApi = `${prefix}/users`

export const permissionsClaim = 'contentgraph.geniussports.com/api/permissions'
export const roleClaim = 'contentgraph.geniussports.com/role'
export const attributesClaim = 'contentgraph.geniussports.com/role/attributes'
export const queryCacheTTL = 600

export const adminRole = 'admin'
export const customerSourcesAttribute = 'customer_data_sources'

export const newPasswordRequired = /NEW_PASSWORD_REQUIRED/
export const newPasswordRegex = /^.{8,}$/ // just min 8 characters

export const PERMISSION_USERS_ADMIN = 'users_admin'
export const PERMISSION_CUSTOMER_DATA = 'customer_data_api_access' // THIS IS UNUSED ON API FOR NOW
export const PERMISSION_CUSTOMER_DATA_WRITE = 'write_customer_data'
export const PERMISSION_TRANSLATIONS = 'translations_api_access'
export const PERMISSION_HASURA_TRAFFIC_RECORDER_API_ACCESS = 'hasura_traffic_recorder_api_access'
