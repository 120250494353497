import RestApi, { GET, PUT, POST, DELETE } from './RestApi'
import { customerSourcesAttribute, PERMISSION_CUSTOMER_DATA_WRITE } from '../config/config'
import { getAttribute, hasPermission } from './Auth'
import omit from 'lodash/omit'

export interface CustomerDataCustomer {
  customerId: string,
  types?: Array<CustomerDataType>
}

export interface CustomerDataType {
  type: string,
  entries?: number
}

export interface CustomerDataEntry {
  customerId: string,
  type: string,
  data: any,
  key?: string,
  dateKey?: string,
  fixtureId?: string,
  competitorId?: string,
  competitionId?: string,
  seasonId?: string,
  roundId?: string,
  marketTypeId?: string,
  customerDataId?: string,
  lastUpdated?: string
}

export interface ApiUpdateResponse {
  message?: string,
  affectedRows?: number,
  timestamp?: string
}

export default class CustomerDataApi extends RestApi {
  name = 'CustomerDataApi'

  listTokenCustomers (): CustomerDataCustomer[] {
    return getAttribute(customerSourcesAttribute).map(customerId => {
      return { customerId, types: [] }
    })
  }

  userCanWrite (): boolean {
    return hasPermission(PERMISSION_CUSTOMER_DATA_WRITE)
  }

  async listCustomers (): Promise<CustomerDataCustomer[]> {
    return this.api(GET, 'customers')
  }

  async getCustomerTypes (customerId: string): Promise<CustomerDataType[]> {
    return this.api(GET, `customers/${customerId}/types`)
  }

  async getCustomerData (customerId: string, type: string): Promise<CustomerDataEntry[]> {
    return this.api(GET, `customers/${customerId}/types/${type}`)
  }

  /**
   * Returns a flattened version of the data that can be re-imported after making changes
   */
  async getCustomerDataRaw (customerId: string, type: string): Promise<any[]> {
    return this.getCustomerData(customerId, type).then(data => data.map(item => ({
      ...omit(item, ['customerDataId', 'customerId', 'type', 'data', 'lastUpdated']),
      ...item.data,
    })))
  }

  async setCustomerData (customerId: string, type: string, data: Array<any>): Promise<ApiUpdateResponse> {
    return this.api(PUT, `customers/${customerId}/types/${type}?keyProperty=key&dateProperty=dateKey`, JSON.stringify(data))
  }

  async setCustomerDataForKey (customerId: string, type: string, key: string, data: any): Promise<ApiUpdateResponse> {
    return this.api(PUT, `customers/${customerId}/types/${type}/keys/${key}`, JSON.stringify(data))
  }

  async addCustomerData (customerId: string, type: string, data: Array<any>): Promise<ApiUpdateResponse> {
    return this.api(POST, `customers/${customerId}/types/${type}?keyProperty=key&dateProperty=dateKey`, JSON.stringify(data))
  }

  async addCustomerDataForKey (customerId: string, type: string, key: string, data: Array<any>): Promise<ApiUpdateResponse> {
    return this.api(POST, `customers/${customerId}/types/${type}/keys/${key}`, JSON.stringify(data))
  }

  async deleteCustomerData (customerId: string, type: string): Promise<ApiUpdateResponse> {
    return this.api(DELETE, `customers/${customerId}/types/${type}`)
  }

  async deleteCustomerDataForKey (customerId: string, type: string, key: string): Promise<ApiUpdateResponse> {
    return this.api(DELETE, `customers/${customerId}/types/${type}/keys/${key}`)
  }

  async getCustomerDataEntry (customerId: string, dataId: string): Promise<CustomerDataEntry> {
    return this.api(GET, `customers/${customerId}/entries/${dataId}`)
  }

  async updateCustomerDataEntry (customerId: string, dataId: string, data: CustomerDataEntry): Promise<ApiUpdateResponse> {
    return this.api(PUT, `customers/${customerId}/entries/${dataId}`, JSON.stringify(data))
  }

  async deleteCustomerDataEntry (customerId: string, dataId: string): Promise<ApiUpdateResponse> {
    return this.api(DELETE, `customers/${customerId}/entries/${dataId}`)
  }

}