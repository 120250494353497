import React, { useEffect } from 'react'
import isEqual from 'lodash/isEqual'

export function useDeepEffect(fn: React.EffectCallback, deps: React.DependencyList) {
  const isFirst = React.useRef(true)
  const prevDeps = React.useRef(deps)

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, i) => isEqual(obj, deps[i]))

    if (isFirst.current || !isSame) {
      fn()
    }

    isFirst.current = false
    prevDeps.current = deps
    // eslint-disable-next-line
  }, deps)
}
