import React from 'react'
import { styled } from '@mui/material/styles'
import { GeniusTheme } from '../../../enhancers/withGeniusTheme'
import { ThemeProvider } from '@mui/material'
import QueryListView from './QueryListView'

const QueryExampleParent = styled('div')`
    font-size: 14px;
    .query-example-title-bar {
        cursor: default;
        display: flex;
        height: 47px !important;
        line-height: 14px;
        padding: 8px 8px 5px;
        position: relative;
        -webkit-user-select: none;
        user-select: none;
        font-family: ${props => props.theme.typography.fontFamily};
        background: #FFFFFF;
    }

    .query-example-title-bar .query-example-title {
        flex: 1 1;
        font-weight: normal !important;
        overflow-x: hidden;
        padding: 10px 0 10px 10px;
        text-align: center;
        text-overflow: ellipsis;
        -webkit-user-select: text;
        user-select: text;
        white-space: nowrap;
        height: 48px;
    }

    .query-example-title-bar .query-example-rhs {
        position: relative;
    }

    .query-example-hide {
        cursor: pointer;
        font-size: 18px;
        margin: -7px -8px -6px 0;
        padding: 18px 16px 15px 12px;
        background: 0;
        border: 0;
        line-height: 14px;
    }


    .query-example-contents {
        border-top: 1px solid #d6d6d6;
        padding: 0px;
        overflow-y: unset;
        bottom: 0;
        left: 0;
        right: 0;
        top: 47px;
    }
`

export default function QueryExample ({ onToggleQueryExample, onEditQuery, onEditVariables }) {
  return (
    <ThemeProvider theme={GeniusTheme}>
      <QueryExampleParent>
        <div className='query-example-title-bar'>
          <div className='query-example-title'>
            Query Examples
          </div>
          <div className='query-example-rhs'>
            <div className='query-example-hide' onClick={onToggleQueryExample}>✕</div>
          </div>
        </div>
        <div className='query-example-contents'>
          <QueryListView onEditQuery={onEditQuery} onEditVariables={onEditVariables} />
        </div>
      </QueryExampleParent>
    </ThemeProvider>
  )
}
