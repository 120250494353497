export async function copyTextToClipboard (text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

interface CopyWrapperProps {
    children: any;
    text: string;
}

export default function CopyWrapper (props: CopyWrapperProps) {
  const handleCopyClick = () => {
    copyTextToClipboard(props.text.toString())
      .catch((err) => {
        console.log(err)
      })
  }
  return (
      <div onClick={handleCopyClick}>{props.children}</div>
  )
}
