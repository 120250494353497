import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ObjectEditor from '../common/ObjectEditor'
import type { ObjectEditorField } from '../common/ObjectEditor/types'
import { useCustomerData } from '../../context/ApiContext'
import { titleCaps, parseDate } from '../../util'
import { useDeepEffect } from '../../hooks/useDeepEffect'
import GraphTableSnackbar from '../common/GraphTable/GraphTableSnackbar'
import { usePopup } from '../../hooks/modals'
import omit from 'lodash/omit'
import TextField from '@mui/material/TextField'

export interface CustomerDataAddDialogProps {
  customerId: string,
  type: string,
  open?: boolean,
  onClose?: (finished?: boolean) => any,
  fields?: string[]
  initialData?: any
}

export const customerDataRelationshipFields = [
  'fixtureId', 'competitorId', 'competitionId', 'seasonId', 'roundId', 'marketTypeId'
]

export default function CustomerDataAddDialog({ customerId, type, open, onClose, fields: baseFields = [], initialData = {} }: CustomerDataAddDialogProps) {
  const setPopup = usePopup()
  const [isLoading, setIsLoading] = React.useState(false)
  const [tab, setTab] = React.useState(0)
  const [data, setData] = React.useState(initialData || {})
  const [rawData, setRawData] = React.useState('')
  const [rawError, setRawError] = React.useState(false)

  const api = useCustomerData()
  const dataFields = baseFields.filter(field => field.startsWith('data.')).map((field) => field.replace(/^data\./, ''))

  const isValidDate = (data && data.dateKey) ? !!parseDate(data.dateKey) : true
  const isValidKey = data.key && data.key.length
  const isValid = isValidDate && isValidKey && dataFields
    .reduce((acc, key) => acc && !!data[key], true) // Check if all values are truthy

  const fields: ObjectEditorField[] = [
    {
      title: 'Key',
      description: 'Unique key for the data entry (required)',
      field: 'key'
    },
    ...dataFields.map((field) => ({
      title: titleCaps(field),
      field
    })),
    ...customerDataRelationshipFields.filter(field => baseFields.length ? baseFields.includes(field) : true).map(field => ({
      // TODO: it would be nice for these to be rendered as autocomplete or to have a preview beside them
      title: titleCaps(field),
      field,
      description: `${field} the data is related to.`
    })),
    {
      title: 'Date Key',
      description: 'A custom timestamp asociated to the entry (ISO Date)',
      placeholder: '2020-04-20T16:20:00.000Z',
      field: 'dateKey',
      error: !isValidDate
    }
  ]

  React.useEffect(() => {
    try {
      setData(JSON.parse(rawData))
      setRawError(false)
    } catch (e) {
      setRawError(true)
    }
  }, [rawData])

  React.useEffect(() => {
    if (tab === 1) setRawData(JSON.stringify(data, null, 2))
    // eslint-disable-next-line
  }, [tab])

  const handleSubmit = () => {
    if (isValid && !isLoading) {
      setIsLoading(true)
      const key = data.key
      const date = data.dateKey

      api.addCustomerDataForKey(customerId, type, `${key}${date ? `?date=${date}` : ''}`, omit(data, ['key', 'dateKey'])).then(({ affectedRows }) => {
        setPopup(<GraphTableSnackbar message={`Your data has been added, ${affectedRows} row(s) affected.`} />)
        handleClose(true)
      }).catch(error => {
        setPopup(<GraphTableSnackbar error={error} />)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }

  const handleClose = (finished = false) => {
    setTab(0)
    setData(initialData)
    onClose && onClose(finished)
  }

  useDeepEffect(() => {
    setData(initialData)
  }, [initialData])

  return (
    <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby='form-dialog-title' fullWidth maxWidth='sm'>
      <DialogTitle id='form-dialog-title'>Add Customer Data Entry</DialogTitle>
      <AppBar position='static' color='primary' elevation={2}>
        <Tabs
          value={tab}
          onChange={(e, i) => setTab(i)}
          variant='fullWidth'
          disabled={isLoading}
        >
          <Tab label='Fields' />
          <Tab label='JSON' />
        </Tabs>
      </AppBar>
      <DialogContent>
        {tab === 0 && <ObjectEditor data={data} fields={fields} disabled={isLoading} onChange={(e, newData) => setData(newData)} />}
        {tab === 1 && <TextField style={{ margin: '8px 0' }} disabled={isLoading} inputProps={{ style: { fontFamily: 'Courier New, monospace' } }} multiline value={rawData} onChange={(ev) => setRawData(ev.target.value)} error={rawError} fullWidth />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} disabled={isLoading} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={isLoading || !isValid} variant='contained'>
          Add Entry
        </Button>
      </DialogActions>
    </Dialog>
  )
}
