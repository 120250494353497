import CopyAll from '@mui/icons-material/CopyAll';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Edit from '@mui/icons-material/Edit';
import Password from '@mui/icons-material/Password';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import React, { useState } from 'react';
import { replaceVars } from '../../util';
import ConfirmDialog from './ConfirmDialog';

export default function UserList(props) {
  const { data, title, actions } = props


  const columns: MUIDataTableColumnDef[] = [
    { label: 'Username', name: 'username' },
    { label: 'Role', name: 'role' },
    {
      label: 'Actions',
      name: 'actions',
      options: {
        customBodyRender: (_, tableMeta, __) => {
          const user = {
            username: tableMeta.rowData[0],
            role: tableMeta.rowData[1]
          }
          return (
            <div>
              <Tooltip title='Edit User'>
                <IconButton
                  aria-label='edit'
                  aria-haspopup='true'
                  onClick={() => actions.edit(user)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title='Copy permissions from existing user'>
                <IconButton
                  aria-label='edit'
                  aria-haspopup='true'
                  onClick={() => actions.copy(user)}
                >
                  <CopyAll />
                </IconButton>
              </Tooltip>
              <Tooltip title='Reset Password'>
                <IconButton
                  aria-label='edit'
                  aria-haspopup='true'
                  onClick={() =>
                    confirmAction(actions.reset, {
                      title: `Reset Password for ${user.username}?`,
                      description: `A new password will be automatically generated for ${user.username}. They will be asked to reset it the first time they log in with it.`,
                      action: 'Reset Password',
                      row: user
                    })}
                >
                  <Password />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete User'>
                <IconButton
                  aria-label='edit'
                  aria-haspopup='true'
                  onClick={() =>
                    confirmAction(actions.delete, {
                      title: `Delete ${user.username}?`,
                      description: `This will PERMANENTLY delete the  user ${user.username} of role ${user.role}`,
                      action: 'Delete',
                      row: user
                    })}
                >
                  <DeleteForever />
                </IconButton>
              </Tooltip>
            </div>
          )
        }
      }
    }
  ]

  const [confirmProps, setConfirmProps] = useState({
    open: false,
    title: undefined,
    description: undefined,
    action: undefined,
    onCancel: () => { },
    onConfirm: () => { }
  })

  const closeConfirm = () => setConfirmProps({ open: false } as any)
  const confirmAction = (action, props) => {
    return setConfirmProps({
      open: true,
      title: props.title && replaceVars(props.title, props.row),
      description:
        props.description && replaceVars(props.description, props.row),
      action: props.action,
      onCancel: () => closeConfirm(),
      onConfirm: () => {
        action(props.row)
        closeConfirm()
      }
    })
  };

  return (
    <div>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={
          {
            pagination: false,
            search: true,
            selectableRows: 'none',
            searchAlwaysOpen: true,
            filter: false,
            print: false,
            download: false,
            viewColumns: false
          } as any
        }
      />
      {confirmProps.open && <ConfirmDialog {...confirmProps} />}
    </div>
  )
}
