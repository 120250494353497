import React from 'react'
import PaperBody from '../../components/common/layout/PaperBody'
import RefreshIcon from '@mui/icons-material/Refresh'
import Alert from '@mui/material/Alert'
import Wrapper from './Wrapper'
import Loading from '../../components/common/Loading'
import HeaderWithActions from '../../components/common/HeaderWithActions'
import ImportDialog from '../../components/customerdata/CustomerDataImportDialog'
import CustomerBody from '../../components/customerdata/CustomerDataCustomerBody'
import Box from '@mui/system/Box'
import { useStyles } from '../../components/common/ObjectEditor/styles'
import { useCustomerData } from '../../context/ApiContext'
import { useCache } from '../../hooks/cache'
import { titleCaps } from '../../util'
import { styled } from '@mui/material/styles'
import CustomDivider from '../../components/common/CustomDivider'
import CustomBold from '../../components/common/CustomBold'

export interface CustomerDataProps {
  customerId?: string,
  noWrapper?: boolean
}

const Spaced = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2)
}))


const CustomerDataTitle = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).h5.fontFamily}`,
  fontSize: `${(theme.typography as any).h5.fontSize}`,
  lineHeight: theme.spacing(4)
}))

const CustomerDataMessage = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).subtitle1.fontFamily}`,
  fontSize: `${(theme.typography as any).h6.fontSize}`,
  color: `${(theme.palette.grey['400'])}`
}))

function CustomerData({ customerId, noWrapper }: CustomerDataProps) {
  const customerData = useCustomerData()
  const classes = useStyles()
  const [importOpen, setImportOpen] = React.useState(false)
  const [importCustomerId, setImportCustomerId] = React.useState('')
  const { data, isLoading, refresh } = useCache(customerData, 'listCustomers', {
    fallback: []
  })
  const customers = customerId ? data.filter(customer => customer.customerId === customerId) : data

  const refreshAction = {
    title: 'Refresh list',
    onClick: () => refresh(),
    icon: RefreshIcon
  }

  const handleImport = (customerId, close = false) => {
    setImportCustomerId(customerId)
    setImportOpen(!close)
  }

  const content = (
    <React.Fragment>
      <ImportDialog customerId={importCustomerId} open={importOpen} onClose={() => setImportOpen(false)} />
      {!isLoading && customers.length === 0 && <PaperBody noPadding><Alert severity='warning'>You currently have no access to {customerId ? `Customer Data for ${titleCaps(customerId)}` : 'the Customer Data API'}, please contact our team to request it.</Alert></PaperBody>}
      {!customerId &&
        <Box className={classes.commonContainer}>
          <HeaderWithActions defaultAction={refreshAction}><CustomerDataTitle><b>Bring your own data</b></CustomerDataTitle></HeaderWithActions>
          <CustomerDataMessage>
            Upload your own data in <CustomBold>JSON</CustomBold> or <CustomBold>CSV</CustomBold> formats and relate it to our core entities:
            <CustomBold>Fixtures, Competitors, Competitions, Seasons, Rounds, Markets, Match actions</CustomBold>
          </CustomerDataMessage>

        </Box>}
      <Spaced />
      <CustomDivider />
      <Spaced />
      {isLoading ? <PaperBody><Loading /></PaperBody> : customers.map((customer) => <CustomerBody key={customer.customerId} customer={customer} onImportClick={handleImport} />)}
    </React.Fragment>
  )

  return noWrapper ? content : <Wrapper>{content}</Wrapper>
}

export default CustomerData
