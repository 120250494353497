import Papa from 'papaparse'
import { sanitizeHeader } from './string'

export function loadCustomerData (body: string): {[key: string]: any}[] {
  if (/^\s*\{/.test(body)) { // starts with a {
    throw new Error('Data must be an array.')
  } else if (/^\s*\[\s*\{/.test(body)) { // json object array
    return loadJSON(body)
  } else {
    return loadCSV(body)
  }
}

export function loadTranslations (body: string): {[key: string]: any} {
  if (/^\s*\{/.test(body)) { // starts with a {
    return loadJSON(body)
  } else if (/^\s*\[\s*\{/.test(body)) { // json object array
    throw new Error('Format not supported.')
  } else {
    return loadCSVTranslations(body)
  }
}

export function loadJSON (json: string) {
  try {
    return JSON.parse(json)
  } catch (e) {
    throw new Error(`Invalid JSON: ${e.toString()}`)
  }
}

export function loadCSV (csv: string): {[key: string]: any}[] {
  const { data } = Papa.parse(csv, {
    header: true,
    transformHeader: (header) => sanitizeHeader(header)
  })
  if (!data || !data.length) throw new Error('CSV must not be empty.')
  return data
}

export function loadCSVTranslations (csv: string) {
  const { data } = Papa.parse(csv)
  if (!data || !data.length) throw new Error('CSV must not be empty.')
  return data.reduce((acc, t) => (t[0] && t[1]) ? Object.assign(acc, {[t[0]]: t[1]}): acc, {})
}
