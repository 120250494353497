import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export type SimpleFileUploadProps = {
  id: string,
  label?: string,
  className?: string,
  accept?: string,
  reset: boolean,
  style?: any,
  onChange?: (data) => any
}

const useStyles = makeStyles((theme) => (
  createStyles({
    root: {
      marginRight: theme.spacing(1)
    }
  })
))

export default function SimpleFileUpload ({ id, label, style, accept, reset = false, onChange }: SimpleFileUploadProps) {
  let fileReader
  const [isError, setError] = React.useState(false)
  const [isFileLoaded, setFileLoaded] = React.useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (reset) {
      setFileLoaded(false)
    }
  }, [reset])

  const handleFileRead = (e) => {
    try {
      onChange && onChange(fileReader.result)
      setFileLoaded(true)
      setError(false)
    } catch (e) {
      setError(true)
    }
  }

  const handleFileChosen = (file) => {
    if (file) {
      fileReader = new FileReader()
      fileReader.onloadend = handleFileRead
      fileReader.readAsText(file)
    }
  }

  const color = isError ? 'inherit' : (isFileLoaded ? 'secondary' : 'primary')
  const text = isError ? 'Invalid File' : (isFileLoaded ? 'Change File' : (label || 'Select File'))

  return (
    <>
      <input
        type='file'
        id={id}
        accept={accept || '.json,application/json'}
        onChange={e => handleFileChosen(e.target.files[0])}
        style={{ display: 'none' }}
      />
      <label htmlFor={id}>
        <Button component='span' variant='contained' color={color} className={classes.root} style={style || {}} startIcon={<AttachFileIcon />}>
          {text}
        </Button>
      </label>
    </>
  )
}
