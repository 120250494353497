export const GET = 'GET'
export const PUT = 'PUT'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'

export type HttpMethod = typeof GET|typeof PUT|typeof POST|typeof DELETE|typeof PATCH

export interface HttpClient {
  (string, { method, body }): Promise<{ headers, json }>
}

export interface HttpClientOptions extends RequestInit {
  token?: string
}

export class HttpError extends Error {
  constructor(
    public readonly message,
    public readonly status,
    public readonly body = null
  ) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.stack = new Error().stack;
  }
}

export const createHeadersFromOptions = (options: HttpClientOptions): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })) as Headers;
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options.token) {
    requestHeaders.set('Authorization', `Bearer ${options.token}`)
    delete options.token
  }
  return requestHeaders;
};

export const fetchJson = (url: string, options: HttpClientOptions = {}) => {
  const requestHeaders = createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError(
            (json && json.message) || statusText,
            status,
            json
          )
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};