import { renderMuiTableCell } from './GraphTableCell'
import { GraphDataFieldEntities } from './GraphDataField'
import { stripTypePluralSuffix, titleCaps } from '../../../util'

import type ContentGraph from '../../../api/ContentGraph'
import type { ContentGraphResponse } from '../../../api/ContentGraph'
import type { MUIDataTableColumn } from 'mui-datatables'
import type { GraphTableQueryArguments, GraphTableData, GraphTableColumn, GraphTableColumnAttributes } from './types'
import get from 'lodash/get'

export const defaultAttributes: GraphTableColumnAttributes = {
  key: {
    cellStyle: {
      fontWeight: 'bold'
    }
  },
  dateKey: {
    title: 'Date',
    type: 'datetime'
  },
  sportId: {
    title: 'Sport',
    type: 'sport'
  },
  _lastUpdatedAt: {
    title: 'Last Update',
    type: 'datetime'
  },
  _createdAt: {
    title: 'Created At',
    type: 'datetime'
  }
}

// Fill defaults for entities
GraphDataFieldEntities.forEach(entity => {
  defaultAttributes[`${entity}Id`] = {
    title: titleCaps(entity),
    type: entity as any
  }
})

/**
 * Returns a fetch function that makes queries to the Content Graph
 * @param cgApi to grab from API context (useApi/useGraph)
 */
export function graphFetch (cgApi: ContentGraph) {
  return (query: string, { operationName, variables, serverSort, orderBy, serverPagination, serverFilters, where, page, pageSize }: GraphTableQueryArguments) =>
    cgApi.graph(
      query,
      Object.assign(
        {},
        variables,
        serverSort && { orderBy },
        serverPagination && { limit: pageSize, offset: page * pageSize },
        serverFilters && { where }
      ),
      operationName
    )
}

/**
 * Returns a Hasura where clause for text search
 * @param searchText search query
 * @param fields the fields to search (columns marked as searchable)
 */
export function graphSearch (searchText: string, fields: string[]) {
  return (searchText && fields) ? {
    _or: fields.map(field => ({
      [field] : {
        _ilike: `${searchText}%`
      }
    }))
  } : {}
}

/**
 * Extracts data for a type from a GraphQL response
 * @param response
 * @param type
 */
export function graphExtract (response: ContentGraphResponse, type: string) {
  return (response.data && response.data[type]) || []
}

/**
 * Extracts the count from an aggregate query in a GraphQL response
 * @param response
 * @param type
 */
export function graphExtractCount (response: ContentGraphResponse, type: string): number {
  const agg = graphExtract(response, `${type}_aggregate`)
  return (agg && agg.aggregate && agg.aggregate.count) || graphExtract(response, type).length
}

/**
 * Returns an array of GraphTableColumn from the keys of a table row
 * @param item
 * @param type
 */
export function graphEntryColumns (item, type = ''): GraphTableColumn[] {
  const keys = Object.keys(item || {})
  return keys.map(key => {
    const defaultsKey = (`${stripTypePluralSuffix(type)}Id` === key) ? 'key' : key
    return {
      title: titleCaps(key),
      field: key,
      sortable: typeof item[key] === 'string' || typeof item[key] === 'number',
      ...(defaultAttributes[defaultsKey] || {})
    }
  })
}

/**
 * Calculate the columns in GraphTableData
 *
 * 1. Extract keys from first row
 * 2. Filter out keys that dont have at least one truthy value
 *
 * @param data
 * @param type
 */
export function graphColumns (data: GraphTableData, type?: string): GraphTableColumn[] {
  const first = data && data[0]
  return first && graphEntryColumns(first, type).filter(({ field }) => data.some(item => !!get(item, field)))
}

/**
 * Converts a GraphTableColumn into an MuiDataTable Column
 * @param column
 * @param columnAttributes
 */
export function mapColumn (data: GraphTableData, column: GraphTableColumn, columnAttributes: GraphTableColumnAttributes = {}): MUIDataTableColumn {
  // TODO: fix hidden columns from props staying hidden on page change even when user set them to visible
  column = Object.assign(column, columnAttributes[column.field] || {})
  return {
    name: column.field,
    label: column.title,
    options: {
      customBodyRenderLite: renderMuiTableCell(data, column),
      display: !column.hidden,
      filter: column.filter,
      filterType: 'dropdown', // it'd be cool to customize filter type based on column types and support specifying options
      sort: column.sortable
    }
  }
}

/**
 * Clones an object into a string->string map
 * @param item
 */
export function toStringMap (item: any): {[key: string]: string} {
  return Object.keys(item).reduce((acc, currentKey) => {
    if (item[currentKey] === null || item[currentKey] === undefined) return acc
    return Object.assign(acc, {
      [currentKey]: item[currentKey].toString() // TODO: maybe do flatenning or skip falsy items
    })
  }, {})
}

/**
 * Clones an object array into a string->string map array (what we pass to Material Table)
 * @param data
 */
export function toStringMapArray (data: any[]): {[key: string]: string}[] {
  return data.map(item => toStringMap(item))
}
