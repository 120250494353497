import { Box, TextField, InputAdornment } from '@mui/material'

import { outlinedInputClasses } from '@mui/material/OutlinedInput'

import { styled } from '@mui/material/styles'

const NotchedOutlined = styled(TextField)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: 'gray'
  }
})

export function CustomTextField({ children, id, label, ...props }) {
  return (
    <Box mt={1}>
      <NotchedOutlined
        id={id}
        name={id}
        label={label}
        fullWidth
        margin='normal'
        variant='outlined'
        autoFocus
        FormHelperTextProps={{ className: 'gray' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>{children}</InputAdornment>
          )
        }}
        {...props}
      />
    </Box>
  )
}
