import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import GeniusBigLogo from '../assets/logo-login-big.svg'
import GeniusSmallLogo from '../assets/logo-login-small.svg'
import { Box, Grid, ThemeProvider, Typography } from '@mui/material'
import { needsNewPassword } from '../api/Auth'
import { newPasswordRegex } from '../config/config'
import Alert from '@mui/material/Alert'
import Loading from './common/Loading'
import Button from '@mui/material/Button'
import { CustomTextField } from './common/CustomTextField'
import { GeniusTheme } from '../enhancers/withGeniusTheme'
import PasswordIcon from '../assets/password-login.svg'
import EmailIcon from '../assets/mail-login.svg'

const AuthMainGridContainer = styled(Grid)`
  height: 100vh;
`

const CustomBackground = styled(Grid)`
  background-image: url(${GeniusBigLogo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const SmallGeniusLogo = styled('div')`
  background-image: url(${GeniusSmallLogo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 168px;
  height: 169px;
  align-self: center;
`

const ColumnDiv = styled(Grid)`
  &&& {
    max-width: none;
  }
  padding-bottom: 6vh;
`
const SpacedColumnDiv = styled(Grid)`
  &&& {
    max-width: none;
  }
  padding-top: 4vh;
`

const PassIcon = styled('div')`
  width: 14.67px;
  height: 8px;
  background-image: url(${PasswordIcon});
  background-size: cover;
`

const MailIcon = styled('div')`
  width: 13.33px;
  height: 10.67px;
  background-image: url(${EmailIcon});
`

function LoginForm (props) {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const errorMessage = props.errorMessage || ''
  const askNewPassword = needsNewPassword(errorMessage)
  const isReady =
    user &&
    password &&
    (askNewPassword ? confirmed && newPasswordRegex.test(newPassword) : true)
  const loading = props.loading

  const handleSubmit = (e) => {
    e && e.preventDefault && e.preventDefault()
    props.onSubmit &&
      props.onSubmit.call(this, user, password, newPassword || undefined)
  }
  return (
    <SpacedColumnDiv item xs={4}>
      {errorMessage &&
        !loading &&
        (askNewPassword
          ? (
            <Alert severity='info'>
              Please enter the new password you want to use for your account (min
              8 characters).
            </Alert>
            )
          : (
            <Alert severity='error'>{errorMessage}</Alert>
            ))}
      {loading
        ? (
          <Loading />
          )
        : (
          <form onSubmit={handleSubmit}>
            {askNewPassword
              ? (
                <>
                  <CustomTextField
                    id='newPassword'
                    label='New Password'
                    type='password'
                    onChange={(e) => setNewPassword(e.target.value)}
                  >
                    <PassIcon />
                  </CustomTextField>
                  <CustomTextField
                    id='confirmPassword'
                    label='Confirm Password'
                    type='password'
                    onChange={(e) => setConfirmed(e.target.value === newPassword)}
                  >
                    <PassIcon />
                  </CustomTextField>
                </>
                )
              : (
                <>
                  <CustomTextField
                    id='email'
                    label='Email'
                    autoComplete='email'
                    placeholder='Your email here!'
                    autoFocus
                    onChange={(e) => setUser(e.target.value)}
                  >
                    <MailIcon />
                  </CustomTextField>
                  <CustomTextField
                    id='password'
                    label='Password'
                    type='password'
                    placeholder='Your password here!'
                    autoComplete='current-password'
                    onChange={(e) => setPassword(e.target.value)}
                  >
                    <PassIcon />
                  </CustomTextField>
                </>
                )}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              disabled={!isReady}
              sx={{
                marginTop: '5vh'
              }}
            >
              {askNewPassword ? 'Confirm' : 'Sign In'}
            </Button>
          </form>
          )}
    </SpacedColumnDiv>
  )
}
function AuthMainGrid ({ children }) {
  return (
    <AuthMainGridContainer container spacing={0}>
      <Grid container item xs={5} alignItems='stretch' justifyContent='center'>
        <Grid container item xs={9} direction='column' justifyContent='center'>
          {children}
        </Grid>
      </Grid>
      <CustomBackground item xs={7} />
    </AuthMainGridContainer>

  )
}

export default function Login (props) {
  const errorMessage = props.errorMessage || ''
  return (
    <ThemeProvider theme={GeniusTheme}>
      <AuthMainGrid>
        <ColumnDiv container item xs={4} direction='column'>
          <SmallGeniusLogo />
          <Box mt={6} mb={1}/* @ts-ignore */>
            <Typography variant='h4'>Welcome to Content Graph</Typography>
          </Box>
          <Box mt={1}>
            <Typography variant='subtitle2'>
              {errorMessage || 'Enter your Email and password.'}
            </Typography>
          </Box>
          <ColumnDiv item xs={4}>
            {LoginForm(props)}
          </ColumnDiv>
        </ColumnDiv>

      </AuthMainGrid>
    </ThemeProvider>
  )
}
