import React from 'react'

import { MapQuery, QueryCategory } from './ExamplesType'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import List from '@mui/material/List'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

interface QueriesByCategoryProps {
  queryCategory: QueryCategory,
  mapQueries: MapQuery,
  updateDisplayedQuery: (queryId: string) => void
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(4)}`
}))

const QueriesByCategory = ({ queryCategory, mapQueries, updateDisplayedQuery }: QueriesByCategoryProps) => {
  const [open, setOpen] = React.useState(false)

  return (
    <div>
      <ListItemButton
        sx={{ pl: 4, paddingLeft: '8px' }} onClick={() =>
          setOpen(!open)}
      >
        {open ? <ArrowDropDownIcon sx={{ fontSize: 20 }} /> : <ArrowRightIcon sx={{ fontSize: 20 }} />}
        <ListItemText primary={queryCategory.categoryName} />
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {queryCategory.sampleQueryIds.map((sampleQueryId, index) => {
            const query = mapQueries[sampleQueryId]
            return (
              <StyledListItemButton
                key={sampleQueryId + index} sx={{ pl: 4 }}
                onClick={() => updateDisplayedQuery(sampleQueryId)}
              >
                <ListItemText primary={query.name} />
              </StyledListItemButton>
            )
          })}
        </List>
      </Collapse>
    </div>
  )
}

export default QueriesByCategory
