import React from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Layout from '../components/common/layout/Layout'
import { Link } from '@reach/router'
import glass from '../assets/glass.svg'

const styles = (theme: Theme) => (
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2),
      minHeight: 'calc(50vh)'
    },
    content: {
      padding: theme.spacing(2)
    },
    glassContainer: {
      flex: 1
    },
    glass: {
      width: '100%',
      height: 'auto'
    }
  })
)

function NotFound (props) {
  const { classes } = props

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant='h2' gutterBottom>I think you might be lost...</Typography>
          <Typography variant='body1'><Button variant='contained' size='large' color='primary' component={Link} to='/'>Go Home</Button>&nbsp; or use the side navigation to find what you want.</Typography>
        </div>
        <div className={classes.glassContainer}>
          <img src={glass} className={classes.glass} alt='Shattered glass' />
        </div>
      </div>
    </Layout>
  )
}

export default withStyles(styles)(NotFound)
