import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import List from '@mui/material/List'
import { useCustomerData } from '../../context/ApiContext'
import DownloadableListItem from '../common/DownloadableListItem'
import Papa from 'papaparse'
import { titleCaps } from '../../util'

export interface ExportDialogProps {
  customerId: string
  type: string
  open: boolean
  onClose: () => void
}

function CustomerDataExportDialog ({ customerId, type, open, onClose }: ExportDialogProps) {
  const api = useCustomerData()

  const handleClose = () => {
    onClose()
  }

  const dataFetcher = async (url) => {
    const data = await api.getCustomerDataRaw(customerId, type)
    const str = (url === 'csv') ? Papa.unparse(data) : JSON.stringify(data)
    const bytes = new TextEncoder().encode(str)
    return new Blob([bytes], {
      type: (url === 'csv') ? 'text/csv;charset=utf-8' : 'application/json;charset=utf-8'
    })
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby='export-dialog-title' open={open}>
      <DialogTitle id='export-dialog-title'>{`Export ${titleCaps(type)}`}</DialogTitle>
      <List>
        <DownloadableListItem url='json' fetch={dataFetcher} filename={`${customerId}-${type}.json`} onDone={handleClose}>Export to JSON</DownloadableListItem>
        <DownloadableListItem url='csv' fetch={dataFetcher} filename={`${customerId}-${type}.csv`} onDone={handleClose}>Export to CSV</DownloadableListItem>
      </List>
    </Dialog>
  )
}

export default CustomerDataExportDialog
