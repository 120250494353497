import React from 'react'
import clsx from 'clsx'
import ListItem from '@mui/material/ListItem'
import { Link, useLocation } from '@reach/router'


function ListItemLink({ activeClass = '', defaultClass = '', commonClassName = '', ...props }) {
  const location = useLocation()
  const active = location && (location.pathname === props.to || (location.pathname.startsWith(props.to) && props.to !== '/'))

  return (
    <ListItem
      button
      component={Link}
      className={active ? clsx(commonClassName, activeClass) : clsx(commonClassName, defaultClass)}
      {...props}
    />
  )
}

export default ListItemLink
