import React from 'react'
import { useGraph } from '../../context/ApiContext'
import { useCache } from '../../hooks/cache'
import GraphiQL from 'graphiql'
import './ExplorerBrowser.css'

function Browser (props) {
  const graph = useGraph()
  const { data: schema } = useCache(graph, 'getSchema', {
    fallback: null,
    ttl: 0
  })

  return (
    <div style={{ height: props.height || 'calc(80vh)' }}>
      <GraphiQL schema={schema} fetcher={graph.subscriptionFetch.bind(graph)} {...props} />
    </div>
  )
}

export default Browser
