import { deepMerge } from '../util'
import type ContentGraph from './ContentGraph'

import { fetchQuery, autoCompleteData } from '../config/autocomplete'

export default class AutoComplete {
  name = 'AutoComplete'

  graph: ContentGraph
  promise: Promise<any>
  data: any

  constructor(graph: ContentGraph) {
    this.graph = graph
    this.data = autoCompleteData
    this.fetch = this.fetch.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  async fetch(type?: string) {
    if (typeof type==='string')
      return this.fetch().then(() => this.getOptions(type))

    if (!this.promise) {
      this.promise = this.graph.graph(fetchQuery).then(data => {
        this.data = deepMerge(data || {}, autoCompleteData)
        this.promise = Promise.resolve()
      }).catch(e => console.log(e))
    }
    return this.promise
  }

  getOptions(type: string) {
    return (this.data && this.data.data[type]) || []
  }

}