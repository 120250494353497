import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import CircularProgress from '@mui/material/CircularProgress'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DoneIcon from '@mui/icons-material/Done'
import { getToken } from '../../api/Auth'

const useStyles = makeStyles((theme) => ({
  avatarPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  avatarDone: {
    backgroundColor: theme.palette.success.main,
    color: '#fff'
  }
}))

export interface DownloadableListItemProps {
  url: string
  filename?: string
  type?: string
  fetch?: (url, headers?) => Promise<any>
  onDone?: () => any
  children: string | JSX.Element
}

export function rawFetch (url, headers = {}) {
  const token = getToken()
  return fetch(url, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  })
    .then((r: Response) => r.blob())
}

function DownloadableListItem ({ url, filename = 'data.json', type = 'application/json', fetch: propFetch = null, onDone, children }: DownloadableListItemProps) {
  const classes = useStyles()
  const [isLoading, setLoading] = React.useState(false)
  const [href, setHref] = React.useState(null)
  const link = React.useRef<HTMLAnchorElement>()
  const fetch = propFetch || rawFetch

  React.useEffect(() => {
    setLoading(false)
    setHref(null)
  }, [url, filename])

  const handleLoad = (e) => {
    if (isLoading) return
    if (href) return onDone()

    setLoading(true)
    fetch(url, {
      Accept: type
    }).then(data => {
      setLoading(false)
      setHref(window.URL.createObjectURL(data))
      link.current.click()
    })
  }

  return (
    <ListItem autoFocus button ref={link} component='a' href={href || undefined} download={filename} onClick={handleLoad}>
      {href
        ? <React.Fragment>
          <ListItemAvatar>
            <Avatar className={classes.avatarDone}><DoneIcon /></Avatar>
          </ListItemAvatar>
          <ListItemText primary={children} />
          </React.Fragment>
        : <React.Fragment>
          <ListItemAvatar>
            {isLoading ? <CircularProgress /> : <Avatar className={classes.avatarPrimary}><CloudDownloadIcon /></Avatar>}
          </ListItemAvatar>
          <ListItemText primary={children} secondary={isLoading ? 'Loading...' : 'Click to download...'} />
          </React.Fragment>}
    </ListItem>
  )
}

export default DownloadableListItem
