import React from "react";
import MUIDataTable, { MUIDataTableColumn } from "mui-datatables";
import { renderGraphData } from "./GraphTable/GraphDataField";
function customBodyRender(entity: string, id?: string) {
  return (value) => {
    return renderGraphData(
      entity,
      id
    )({
      [id]: value,
    });
  };
}

function ImportDataTable({ data }) {
  const baseColumns: MUIDataTableColumn[] = [
    { label: "Key", name: "key" },
    { label: "Title", name: "title" },
    { label: "Translation", name: "translation" },
    { label: "Date", name: "date" },
    {
      label: "Fixture",
      name: "fixtureId",
      options: {
        customBodyRender: (value, ___, __) => {
          return renderGraphData(
            "fixture",
            "fixtureId"
          )({
            fixtureId: value,
          });
        },
      },
    },
    {
      label: "Competitor",
      name: "competitorId",
      options: {
        customBodyRender: (value, ___, __) => {
          return renderGraphData(
            "competitor",
            "competitorId"
          )({
            competitorId: value,
          });
        },
      },
    },
    {
      label: "Competition",
      name: "competitionId",
      options: {
        customBodyRender: (value, ___, __) => {
          return renderGraphData(
            "competition",
            "competitionId"
          )({
            competitionId: value,
          });
        },
      },
    },
    {
      label: "Season",
      name: "seasonId",
      options: {
        customBodyRender: customBodyRender("season", "seasonId"),
      },
    },
    {
      label: "Round",
      name: "roundId",
      options: {
        customBodyRender: customBodyRender("round", "roundId"),
      },
    },
  ];

  const columns: MUIDataTableColumn[] = Object.keys(data[0] || {})
    .filter((field) => data[0] && typeof data[0][field] !== "object")
    .map((field) => {
      return (
        baseColumns.find((item) => (item as any).name === field) || {
          label: field,
          name: field,
        }
      );
    });

  return (
    <MUIDataTable
      title=""
      data={data}
      columns={columns}
      options={
        {
          pagination: false,
          search: false,
          toolbar: false,
          selectableRows: "none",
          searchAlwaysOpen: false,
          filter: false,
          print: false,
          download: false,
          viewColumns: false,
        } as any
      }
    />
  );
}

export default ImportDataTable;
