import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import List from '@mui/material/List'
import { useTranslations } from '../../context/ApiContext'
import DownloadableListItem from '../common/DownloadableListItem'

export interface ExportDialogProps {
  customerId: string
  locale: string
  open: boolean
  onClose: () => void
}

function TranslationsExportDialog ({ customerId, locale, open, onClose }: ExportDialogProps) {
  const api = useTranslations()
  const url = api.getLocaleUrl(customerId, locale)

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby='export-dialog-title' open={open}>
      <DialogTitle id='export-dialog-title'>{`Export translations for ${locale}`}</DialogTitle>
      <List>
        <DownloadableListItem url={url} filename={`translations-${locale}.json`} onDone={handleClose}>Export to JSON</DownloadableListItem>
        <DownloadableListItem url={url} filename={`translations-${locale}.csv`} type='text/csv' onDone={handleClose}>Export to CSV</DownloadableListItem>
      </List>
    </Dialog>
  )
}

export default TranslationsExportDialog
