import React from 'react'
import { Alert, AlertTitle } from '@mui/material';
import Button from '@mui/material/Button'

export interface NoDataAvailablePlaceholderProps {
  title?: string,
  actionTitle?: string,
  actionOnClick?: any // TODO: use correct type for this
}

function NoDataAvailablePlaceholder (props: NoDataAvailablePlaceholderProps) {
  const { title, actionTitle, actionOnClick } = props
  return (
    <Alert icon={false} severity='info'>
      <AlertTitle>{title || "There's no data available to display..."}</AlertTitle>
      {actionTitle && <Button variant='contained' color='primary' onClick={actionOnClick}>{actionTitle}</Button>}
    </Alert>
  )
}

export default NoDataAvailablePlaceholder
