import React from 'react'
import { getStore, buildKey, getRawFetcher } from '../api/Cache'
import { useAuth } from '../context/AuthContext'
export { flushCache } from '../api/Cache'

export interface CacheOptions {
  args?: string | any[],
  fallback?: any,
  ttl?: number
}

export function useCache<T extends Object, K extends keyof T> (api: T, method: K, { args, fallback, ttl }: CacheOptions = {}) {
  const { logout } = useAuth()
  const cache = getStore(api['name'] || api.constructor.name)
  const key = buildKey(method, args)
  const fetcher = typeof api[method] === 'function' ? getRawFetcher(api[method] as any, api) : () => api[method]
  const [isRefreshing, setRefreshing] = React.useState(false)
  const [error, setError] = React.useState(null)
  const fetch = () => fetcher(...(Array.isArray(args) ? args : [args]))

  React.useEffect(() => {
    if (!cache.hasData(key)) {
      let mounted = true
      if (!cache.has(key)) {
        cache.setPromise(key, fetch(), ttl)
      }

      cache.getPromise(key).then(() => {
        mounted && setError(false)
      }, error => {
        mounted && setError(error)
      })
      return () => { mounted = false }
    }
    // eslint-disable-next-line
  }, [api, method, args])

  React.useEffect(() => { // TODO: find a better place to do this
    if (error && error.status && error.status === 401) logout()
    // eslint-disable-next-line
  }, [error])

  return {
    data: cache.getData(key, fallback),
    refresh: () => {
      setRefreshing(true)
      cache.setPromise(key, fetch(), ttl)
        .catch(error => setError(error))
        .finally(() => setRefreshing(false))
    },
    isLoading: (!cache.has(key) || cache.isPromise(key)) && !error,
    isRefreshing,
    error
  }
}
