import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import SimpleAutocomplete from '../common/SimpleAutocomplete'
import QuickSnack from '../common/QuickSnack'
import { titleCaps, loadTranslations, mapToArray } from '../../util'

import { navigate } from '@reach/router'
import SimpleFileUpload from '../common/SimpleFileUpload'
import { useApi } from '../../context/ApiContext'
import { useCache } from '../../hooks/cache'
import { usePopup } from '../../hooks/modals'

import SteppedDialog, { SteppedDialogFinishAction } from '../common/SteppedDialog'
import { TranslationsCustomer } from '../../api/Translations'
import ImportDataTable from '../common/ImportDataTable'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: 0,
    '& > *': {
      marginRight: theme.spacing(2)
    }
  },
  fileFab: {
    marginRight: theme.spacing(4)
  }
}))

export interface ImportTranslationsProps {
  customerId?: string,
  locale?: string,
  open?: boolean,
  onClose?: () => any,
}

function checkTranslations(map) {
  return Object.values(map).reduce((acc, key) => acc && typeof key === 'string', true) &&
    Object.keys(map).reduce((acc, key) => acc && typeof key === 'string', true)
}

function ImportDialog({ customerId, locale: initialLocale = '', open, onClose }: ImportTranslationsProps) {
  const { translations } = useApi()
  const { data: customers, refresh } = useCache(translations, 'listCustomers', {
    fallback: translations.listTokenCustomers()
  })
  const { data: locales } = useCache(translations, 'listLocales')

  const classes = useStyles()
  const setPopup = usePopup()
  const [locale, setLocale] = React.useState(initialLocale)
  const [data, setData] = React.useState(null)
  const [reset, setReset] = React.useState(false)

  const customer: TranslationsCustomer = customers.find(customer => (customer.customerId === customerId))
  const foundLocale = customer && customer.locales && !!customer.locales.find(customerLocale => (customerLocale === locale))

  React.useEffect(() => {
    clearForm()
    // eslint-disable-next-line
  }, [customerId])

  const clearForm = () => {
    setReset(true)
    setLocale(initialLocale)
    setData([])
  }

  const handleClose = () => {
    clearForm()
    onClose && onClose()
  }

  const loadData = (file) => {
    setReset(false)
    if (!file) { setData([]); return }
    try {
      const data = loadTranslations(file)
      if (!checkTranslations(data)) throw new Error('You have uploaded an invalid translations file.')
      setData(data)
    } catch (e) {
      setPopup(<QuickSnack title='We cant load your file!' severity='error'>{e.toString()}</QuickSnack>)
    }
  }

  const saveData = async (replace = false) => {
    const fun = (replace
      ? translations.setLocale
      : translations.updateLocale).bind(translations)
    return fun(customerId, locale, data).then(() => {
      clearForm()
      refresh()
      navigate(`/translations/${customerId}/${locale}`)
      return 'Your translations have been uploaded.'
    })
  }

  const count = data && Object.keys(data).length
  const validType = !!customer && !!locale // TODO: check if locale is valid in a better way
  const validData = !!count
  const validDone = validData && validType

  const steps = [
    {
      label: 'Select locale',
      title: `Import translations into ${titleCaps(customerId)}`,
      next: validType,
      content: <React.Fragment>
        <p>Please enter or choose the locale you want to import.</p>
        <SimpleAutocomplete
          autoFocus
          label='Locale'
          value={locale}
          freeSolo
          options={locales || (customer && customer.locales) || []}
          onChange={(e, data) => setLocale(data)}
          required
        />
      </React.Fragment>
    },
    {
      label: 'Import File',
      title: 'File Upload',
      next: validData,
      content: <React.Fragment>
        <p>Please select a file to load your <b>{locale}</b> translations from, we accept standard CSV and JSON files in <b>utf-8 encoding</b>.</p>
        <p>CSV files must have English values in the first column and translations in the second, JSON files must be a <b>flat object</b> mapping English keys to target values.</p>
        <div className={classes.buttonGroup}>
          <SimpleFileUpload
            id='bulkdata'
            accept='.csv,text/csv,.json,application/json'
            reset={reset}
            onChange={(data) => loadData(data)}
            className={classes.fileFab}
          />
          <a href='/translations.csv' target='_blank'>Example CSV</a>
          <a href='/translations.json' target='_blank'>Example JSON</a>
        </div>
      </React.Fragment>
    },
    {
      label: 'Confirm Import',
      title: 'Preview',
      next: validDone,
      content: <React.Fragment>
        {validType && <p>We will import <b>{count}</b> entries for <b>{locale}</b>,
          only users authorized for customerId <b>{customerId}</b> will be able to see the data.
        </p>}
        <p>Please review if the data appears correctly.</p>
        <ImportDataTable data={mapToArray(data, 'sourceText', 'targetText').slice(0, 5)} />
      </React.Fragment>
    }
  ]

  const finishSteps: SteppedDialogFinishAction[] = [
    {
      label: `Add ${count} translations`,
      tooltip: 'This will add/update the translations in your file on the chosen locale.',
      onClick: () => saveData(false)
    }
  ]

  if (foundLocale) {
    finishSteps.push({
      label: 'Replace all translations',
      tooltip: 'This will delete ALL the data previously stored for the chosen locale!',
      onClick: () => saveData(false)
    })
  }

  return (
    <SteppedDialog open={open} step={initialLocale ? 1 : 0} onClose={handleClose} steps={steps} finish={finishSteps} />
  )
}

export default ImportDialog
