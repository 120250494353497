import React from 'react'
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider
} from '@mui/material'
import { CostChipsConfigs } from './CostChipColor'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line
  interface DefaultTheme extends Theme { }
}

declare module '@mui/material/styles' {
  interface Palette {
    GSB: Palette['primary'];
    gsmBackground: string;

  }

  interface PaletteOptions {
    GSB: PaletteOptions['primary']; // Genius sports blue
    gsmBackground: string;
    costChipsConfigs: CostChipsConfigs;
  }

}

export const primaryColor = '#0000DC'
export const secondaryColor = '#2196f3'
export const successColor = '#7db82f'
export const errorColor = '#ec4239'

const g50 = '#E5E5EA'
const g100 = '#D1D1D6'
const g200 = '#C7C7CC'
const g300 = '#AEAEB2'
const g400 = '#8E8E93'
const g500 = '#636366'
const g600 = '#48484A'
const g700 = '#3A3A3C'
const g800 = '#2C2C2E'
const g900 = '#1C1C1E'
const background = '#F2F2F5'
const GSB50 = '#E6ECFF'
const GSB100 = '#A3BCFF'
const GSB200 = '#7A9DFF'
const GSB300 = '#4E7BF5'
const GSB400 = '#2532E8'
const GSB500 = '#0000DC'
const GSB600 = '#0000B5'
const GSB700 = '#00008F'
const GSB800 = '#000069'
const GSB900 = '#000042'
export const G00 = '#FFFFFF'

export const GeniusTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    },
    success: {
      main: successColor
    },
    error: {
      main: errorColor
    },
    grey: {
      50: g50,
      100: g100,
      200: g200,
      300: g300,
      400: g400,
      500: g500,
      600: g600,
      700: g700,
      800: g800,
      900: g900
    },
    GSB: {
      50: GSB50,
      100: GSB100,
      200: GSB200,
      300: GSB300,
      400: GSB400,
      500: GSB500,
      600: GSB600,
      700: GSB700,
      800: GSB800,
      900: GSB900
    },
    gsmBackground: background,
    costChipsConfigs: {
      analyzing: {
        background: background,
        text: g700
      },
      optimal: {
        background: '#E8FCF2',
        text: '#139F5A'
      },
      normal: {
        background: GSB50,
        text: GSB500
      },
      high: {
        background: '#FFFBCC',
        text: '#7A6500'
      },
      heavy: {
        background: '#FFEFE5',
        text: '#FA5D00'
      },
      error: {
        background: '#FFEFE5',
        text: '#C20000'
      }
    }
  },
  typography: {
    fontFamily: ['"Red Hat Text"', 'Arial', 'sans-serif'].join(','),
    h3: {
      fontFamily: 'KlarheitKurrent',
      fontWeight: 'bold',
      fontSize: '1em'
    },
    h4: {
      fontFamily: 'KlarheitKurrent',
      fontWeight: 'bold',
      fontSize: '1.9em'
    },
    h5: {
      fontFamily: 'KlarheitKurrent',
      fontSize: '1.7em'
    },
    h6: {
      fontSize: '1.3em',
      fontFamily: 'KlarheitKurrent',
      color: 'black'
    },
    subtitle1: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: 'gray'
    },
    subtitle2: {
      fontSize: '1.7em',
      fontFamily: 'Red Hat Text',
      color: 'black'
    },
    fontWeightBold: 500
  }
})

const paperExtra = {
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            fontWeight: '900'
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: GeniusTheme.palette.common.white
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: GeniusTheme.spacing(1)
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: GeniusTheme.spacing(1)
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: GeniusTheme.palette.GSB
        },
        [GeniusTheme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#404854'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 400
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingRight: 0,
          minHeight: '49px',
          [GeniusTheme.breakpoints.up('sm')]: {
            minHeight: 48,
            paddingRight: 0
          }
        }
      }
    }
  }
}

Object.assign(GeniusTheme, paperExtra)

function withGeniusTheme (BaseComponent: React.ComponentType<any>) {
  return ({ ...props }) => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={GeniusTheme}>
        <BaseComponent {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default withGeniusTheme
