import React, { useState, useEffect } from 'react'
import { Auth, getToken, getDecoded, HttpClient, Role, getUsername } from '../api/Auth'
import AuthContext, { AuthContextType } from './AuthContext'
import { flushCache } from '../api/Cache'

const AuthProvider = ({ ...props }) => {
  const [isLoggedIn, setLoggedIn] = useState(!!getToken())
  const [token, setToken] = useState(getToken())
  const [username, setUsername] = useState(getUsername())

  const update = () => {
    return Auth.checkAuth().then(() => {
      setLoggedIn(true)
      setToken(getToken())
      setUsername(getUsername())
      return true
    }).catch(() => {
      setLoggedIn(false)
      setToken(null)
      return false
    })
  }

  useEffect(() => {
    update()
  }, [])

  const login = async ({ username, password, newPassword } : { username: string, password: string, newPassword?: string }) => {
    return Auth.login({
      username,
      password,
      newPassword,
      onRefresh: () => { update() }
    }).then(() => update())
  }

  const logout = async () => {
    Auth.logout()
    flushCache()
    setLoggedIn(false)
  }

  const context: AuthContextType = {
    username,
    token,
    isLoggedIn,
    login,
    logout,
    checkAuth: update,
    getDecoded,
    fetch: HttpClient,
    role: Role
  }

  return <AuthContext.Provider value={context} {...props} />
}

export const withAuthContextProvider = (BaseComponent: React.ComponentType<any>) => ({ ...props }) => {
  return (<AuthProvider><BaseComponent {...props} /></AuthProvider>)
}

export default AuthProvider
