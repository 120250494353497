import React from 'react'
import PaperBody from '../../components/common/layout/PaperBody'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Link } from '@reach/router'
import CustomerDataGraphTable from '../../components/customerdata/CustomerDataGraphTable'
import Box from '@mui/system/Box'
import { useStyles } from '../../components/common/ObjectEditor/styles'
import { styled } from '@mui/material/styles'
import CustomDivider from '../../components/common/CustomDivider'

const ExternalDataTitle = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).h5.fontFamily}`,
  fontSize: `${(theme.typography as any).h4.fontSize}`
}))

function TypeEntries(props) {
  const { customerId, type } = props
  const classes = useStyles()

  return (
    <>
      <Box className={classes.commonContainer}>
        <ExternalDataTitle>
          <b>
            {customerId}
          </b>
        </ExternalDataTitle>
      </Box>
      <Tabs value={0} >
        <Tab label='Entries' />
        <Tab component={Link} to='explore' label='Query Explorer' />
      </Tabs>
      <CustomDivider />
      <PaperBody noPadding>
        <CustomerDataGraphTable customerId={customerId} type={type} />
      </PaperBody>
    </>
  )
}

export default TypeEntries
