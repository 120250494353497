import { fetchJson } from './HttpClient'
import type { HttpClient, HttpMethod } from './HttpClient'
export { GET, PUT, POST, DELETE, PATCH } from './HttpClient'
export type { HttpMethod } from './HttpClient'

export default class RestApi {
  client: HttpClient
  apiUrl: string

  constructor(apiUrl, httpClient: HttpClient = fetchJson) {
    this.apiUrl = apiUrl
    this.client = httpClient
  }

  async api(method: HttpMethod, url: string, body?) {
    return (await this.client(`${this.apiUrl}/${url}`, {
      method,
      body
    })).json
  }
}