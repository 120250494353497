import yaml from 'js-yaml'
import ExamplesType, { MapQuery, Query } from './ExamplesType'

const queryFileNames = {
  MLB_STATS_PLAYER: 'MLB_STATS_PLAYER',
  MLB_STATS_TEAM: 'MLB_STATS_TEAM',
  NFL_BETTING_CONSENSUS_MARKETS: 'NFL_BETTING_CONSENSUS_MARKETS',
  NFL_BETTING_CONTEXTUAL_ODDS: 'NFL_BETTING_CONTEXTUAL_ODDS',
  NFL_FIXTURES_LINEUPS: 'NFL_FIXTURES_LINEUPS',
  NFL_FIXTURES_PLAY_BY_PLAY: 'NFL_FIXTURES_PLAY_BY_PLAY',
  NFL_FIXTURES_SCHEDULE: 'NFL_FIXTURES_SCHEDULE',
  NFL_FIXTURES_SCORE: 'NFL_FIXTURES_SCORE',
  NFL_STATS_PLAYER: 'NFL_STATS_PLAYER',
  NFL_STATS_SEASON: 'NFL_STATS_SEASON',
  NFL_STATS_TEAM: 'NFL_STATS_TEAM',
  NFL_FIXTURES_SUMMARY: 'NFL_FIXTURES_SUMMARY',
  BASKETBALL_COMPETITIONS: "BASKETBALL_COMPETITIONS",
  BASKETBALL_FIXTURES: "BASKETBALL_FIXTURES",
  BASKETBALL_SUMMARY: "BASKETBALL_SUMMARY",
  BASKETBALL_STATS_PLAYER: "BASKETBALL_STATS_PLAYER",
  BASKETBALL_STATS_TEAM: "BASKETBALL_STATS_TEAM",
  FOOTBALL_FIXTURES: "FOOTBALL_FIXTURES",
  FOOTBALL_SUMMARY: "FOOTBALL_SUMMARY",
  FOOTBALL_COMPETITIONS: "FOOTBALL_COMPETITIONS",
  FOOTBALL_STATS_TEAM: "FOOTBALL_STATS_TEAM",
  FOOTBALL_STATS_PLAYER: "FOOTBALL_STATS_PLAYER"
}

const queryList = Object.values(queryFileNames)

const bringSampleQuery = async (fileName: string): Promise<Query> => {
  try {
    const response: string = await fetch(`sample-queries/${fileName}.yml`).then(
      (response: Response) => {
        return response.text()
      }
    )
    const yamlFile = yaml.load(response, 'utf8')
    return yamlFile
  } catch (e) {
    return undefined
  }
}

const queryFilter = (queries: Query[], fileNames: string[]): string[] => {
  return queries.filter(q => fileNames.includes(q.fileName)).map(q => q.id)
}

const bringAllSampleQueries = async (): Promise<ExamplesType> => {
  const queries: Query[] = await Promise.all(
    queryList.map(async (queryName: string): Promise<Query> => {
      const query: Query = await bringSampleQuery(queryName)
      return { ...query, fileName: queryName }
    })
  )
  const mapQueries: MapQuery = queries.reduce((prevQueries, currentQuery) => {
    prevQueries[currentQuery.id] = currentQuery
    return prevQueries
  }, {})
  return {
    sports: [
      {
        name: 'NFL',
        popularQueryIds: queryFilter(queries, [queryFileNames.NFL_FIXTURES_LINEUPS, queryFileNames.NFL_FIXTURES_SCHEDULE]),
        queryCategories: [
          {
            id: 'NFL_FIXTURES',
            categoryName: 'FIXTURES',
            sampleQueryIds: queryFilter(queries,
              [
                queryFileNames.NFL_FIXTURES_SCHEDULE,
                queryFileNames.NFL_FIXTURES_SUMMARY,
                queryFileNames.NFL_FIXTURES_LINEUPS,
                queryFileNames.NFL_FIXTURES_SCORE,
                queryFileNames.NFL_FIXTURES_PLAY_BY_PLAY
              ])
          },
          {
            id: 'NFL_STATS',
            categoryName: 'STATS',
            sampleQueryIds: queryFilter(queries,
              [
                queryFileNames.NFL_STATS_PLAYER,
                queryFileNames.NFL_STATS_TEAM,
                queryFileNames.NFL_STATS_SEASON
              ])
          },
          {
            id: 'NFL_BETTING',
            categoryName: 'BETTING',
            sampleQueryIds: queryFilter(queries,
              [
                queryFileNames.NFL_BETTING_CONTEXTUAL_ODDS,
                queryFileNames.NFL_BETTING_CONSENSUS_MARKETS
              ])
          }
        ]
      },
      {
        name: 'MLB',
        popularQueryIds: queryFilter(queries,
          [
            queryFileNames.MLB_STATS_PLAYER,
            queryFileNames.MLB_STATS_TEAM
          ]),
        queryCategories: [
          {
            id: 'MLB_STATS',
            categoryName: 'STATS',
            sampleQueryIds: queryFilter(queries,
              [
                queryFileNames.MLB_STATS_PLAYER,
                queryFileNames.MLB_STATS_TEAM
              ])
          }
        ]
      },
      {
        name: 'BASKETBALL',
        popularQueryIds: queryFilter(queries,
          [
            queryFileNames.BASKETBALL_COMPETITIONS,
            queryFileNames.BASKETBALL_FIXTURES
          ]),
        queryCategories: [
          {
            id: 'BASKETBALL_FIXTURES',
            categoryName: 'FIXTURES',
            sampleQueryIds: queryFilter(queries,
              [
                queryFileNames.BASKETBALL_COMPETITIONS,
                queryFileNames.BASKETBALL_FIXTURES,
                queryFileNames.BASKETBALL_SUMMARY
              ])
          },
          {
            id: 'BASKETBALL_STATS',
            categoryName: 'STATS',
            sampleQueryIds: queryFilter(queries,
              [
                queryFileNames.BASKETBALL_STATS_PLAYER,
                queryFileNames.BASKETBALL_STATS_TEAM
              ])
          }
        ]
      },
      {
        name: 'FOOTBALL',
        popularQueryIds: queryFilter(queries,
          [
            queryFileNames.FOOTBALL_COMPETITIONS,
            queryFileNames.FOOTBALL_FIXTURES
          ]),
        queryCategories: [
          {
            id: 'FOOTBALL_FIXTURES',
            categoryName: 'FIXTURES',
            sampleQueryIds: queryFilter(queries,
              [
                queryFileNames.FOOTBALL_COMPETITIONS,
                queryFileNames.FOOTBALL_FIXTURES,
                queryFileNames.FOOTBALL_SUMMARY
              ])
          },
          {
            id: 'FOOTBALL_STATS',
            categoryName: 'STATS',
            sampleQueryIds: queryFilter(queries,
              [
                queryFileNames.FOOTBALL_STATS_TEAM,
                queryFileNames.FOOTBALL_STATS_PLAYER
              ])
          }
        ]
      }
    ],
    queries: mapQueries
  }
}

export default bringAllSampleQueries
