import CssBaseline from '@mui/material/CssBaseline'
import Hidden from '@mui/material/Hidden'
import Toolbar from '@mui/material/Toolbar'
import React from 'react'
import Header from './Header'
import Footer from './../Footer'
import type { NavigatorProps } from './Navigator'
import Navigator from './Navigator'
import Box from '@mui/material/Box'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { getLastLogInDate } from '../../../api/Auth'
import { styled, ThemeProvider } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { GeniusTheme } from '../../../enhancers/withGeniusTheme'

const useStyles = makeStyles((theme) =>
  createStyles({
    mainLayout: {
      padding: theme.spacing(1)
    }
  })
)

const Content = styled('div')`
    min-height: calc(100vh - 63px);
`

const drawerWidth = 256
const smallerWidth = 56
export interface LayoutProps {
  children: any;
  onSelectItem?: NavigatorProps['onSelectItem'];
  explorer?: boolean;
}

function Layout(props: LayoutProps) {
  const [fullscreen, setFullscreen] = useLocalStorage('cgfullscreen', false)

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const classes = useStyles()

  return (
    <ThemeProvider theme={GeniusTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header onDrawerToggle={handleDrawerToggle} />
        <nav>
          <Hidden mdUp implementation='js'>
            <Content>
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                onSelectItem={props.onSelectItem}
                setFullscreen={setFullscreen}
                drawerWidth={fullscreen ? smallerWidth : drawerWidth}
              />
            </Content>
          </Hidden>
          <Hidden mdDown implementation='css'>
            <Navigator
              PaperProps={{ style: { width: fullscreen ? smallerWidth : drawerWidth } }}
              variant='permanent'
              onSelectItem={props.onSelectItem}
              setFullscreen={setFullscreen}
              drawerWidth={fullscreen ? smallerWidth : drawerWidth}
              fullscreen={fullscreen}
            />
          </Hidden>
        </nav>
        <Box component='main' sx={{ flexGrow: 1, p: 0 }}>
          <Content>
            <Toolbar />
            <main className={!props.explorer ? classes.mainLayout : ""}>
              {props.children}
            </main>
          </Content>
          {!props.explorer && <Footer lastLoginDate={getLastLogInDate()} />}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default (Layout)
