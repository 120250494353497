import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const Loading = () => (
  <div style={{ padding: '20px', textAlign: 'center' }}>
    <CircularProgress />
  </div>
)

export default Loading
