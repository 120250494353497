import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import UserEditor from './UserEditorComponent'
import type { User, SimpleRole } from '../../api/UsersApi'

export default function UserDialog ({ roles = [], onSubmit = (user) => null } : {roles: SimpleRole[], onSubmit: (user: User) => void}) {
  const newUser = true
  const [open, setOpen] = React.useState(false)
  const [user, setUser] = React.useState<User>({ username: '' })

  React.useEffect(() => {
    setOpen(true)
  }, [])

  const handleClose = (_: object, reason: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }

  const handleChange = (user) => {
    setUser(user)
  }

  const handleSubmit = () => {
    if (validUser() && onSubmit) {
      setOpen(false)
      return onSubmit(user)
    }
  }

  const validUser = () => {
    if (!user.username) return false
    if (!(user.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email))) return false
    if (!user.role) return false
    return true
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Create User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the user's email address and select a role for the new user.
        </DialogContentText>
        <UserEditor newUser={newUser} roles={roles} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={!validUser()}>
          Create User
        </Button>
      </DialogActions>
    </Dialog>
  );
}
