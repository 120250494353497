import React from 'react'
import PaperBody from '../common/layout/PaperBody'
import QueryTable from './QueryTableComponent'
import { styled } from '@mui/material/styles'
import CustomDivider from '../common/CustomDivider'
import { useRole } from '../../context/AuthContext'
import { PERMISSION_HASURA_TRAFFIC_RECORDER_API_ACCESS } from '../../config/config'


const ReportsTitle = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).h5.fontFamily}`,
  fontSize: `${(theme.typography as any).h5.fontSize}`,
  paddingLeft: theme.spacing(2),
  lineHeight: theme.spacing(4)
}))

const ReportsMessage = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).subtitle1.fontFamily}`,
  fontSize: `${(theme.typography as any).h6.fontSize}`,
  color: `${(theme.palette.grey['400'])}`,
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(3)
}))

const Spaced = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2)
}))


function HasuraTrafficReports() {
  const [isError, setError] = React.useState(false)
  const { hasPermission } = useRole()
  return isError
    ? (
      <PaperBody>
        <h3>Cannot load the information</h3>
        <p>Your user is probably not authorized to use the Reports.</p>
      </PaperBody>
    )
    : (
      <div>
        <Spaced />
        <ReportsTitle><b>Reports</b></ReportsTitle>
        <Spaced />
        <ReportsMessage>Review your most expensive queries and queries that throw errors</ReportsMessage>
        <Spaced />
        <CustomDivider />
        <Spaced />
        {hasPermission(PERMISSION_HASURA_TRAFFIC_RECORDER_API_ACCESS) ? (<PaperBody noPadding>
          <QueryTable title='Top Expensive Queries All Users' setError={setError} />
        </PaperBody>) : null}
        {hasPermission(PERMISSION_HASURA_TRAFFIC_RECORDER_API_ACCESS) ? (<PaperBody noPadding>
          <QueryTable title='Top Queries With Errors All Users' withError setError={setError} />
        </PaperBody>) : null}
        <PaperBody noPadding>
          <QueryTable title='Top Queries' currentUser setError={setError} />
        </PaperBody>
        <PaperBody noPadding>
          <QueryTable title='Top Queries With Errors' withError currentUser setError={setError} />
        </PaperBody>


      </div>
    )
}

export default HasuraTrafficReports
