import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Link } from '@reach/router'

function MenuItemLink (props) {
  return (<MenuItem
    component={Link}
    {...props}
  />)
}

export default MenuItemLink
