import { baseKeys } from '.'

export function replaceVars (text: string, data: {[key: string]: string}): string {
  return text.toString().replace(/\$(\w+)/g, (a, b) => {
    return data[b] || ''
  });
}

export function trim (str: string): string {
  return (str || '').replace(/^\s+/, '').replace(/\s+$/, '');
}

export function titleCaps (str: string): string {
  return (str || '').split(/[_\-\s]/).map(str => str.replace(/^\w/, c => c.toUpperCase())).join(' ');
}

export function stripAt (email: string): string {
  const match = (email || '').match(/^(.*)@.*$/)
  return (match && match[1]) ? match[1] : email
}

export function stripTypePluralSuffix (typeName: string): string {
  return (typeName || '').replace(/s$/, '');
}

export function stripIdSuffix (columnName: string): string {
  return (columnName || '').replace(/Id$/, '');
}

export function checkCustomerType (type: string): boolean {
  return typeof type === 'string' && /^[a-zA-Z][\w]+[a-z]$/.test(type);
}

export function sanitizeType (str: string): string {
  return trim(str).replace(/\s+/g, '_').replace('-', '_');
}

export function sanitizeHeader (header: string): string {
  header = trim(header)
  const low = header.toLowerCase()
  const lowKeys = baseKeys.map(key => key.toLowerCase())
  const baseIndex = lowKeys.findIndex((key) => key === low)

  if (baseIndex >= 0) {
    // Fix case if different
    header = baseKeys[baseIndex]
  }

  if (header === 'dateKey') return 'date'
  if (header === 'customerId' || header === 'type') return null

  return header.replace(/\s+/g, '_');
}
