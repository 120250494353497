import RestApi, { GET } from './RestApi'


export interface PageQueries {
  count: number,
  content: QueryCost[]
}
export interface QueryCost {
  username: string;
  queryHash: number;
  query: string;
  hasuraRole: string;
  operationName: string;
  variables: string;
  countQuery: number;
  cost: number;
  createdAt: string;
  lastUpdatedAt: string;
  withError: boolean;
}

export default class HasuraReportApi extends RestApi {

  name = 'HasuraReportApi'

  async listTopQueriesByCost(limit: number, offset: number): Promise<PageQueries> {
    return this.api(GET, `users?sortBy=cost&sinceDays=8&limit=${limit}&offset=${offset}`)
  }

  async listTopQueriesByErrors(limit: number, offset: number): Promise<PageQueries> {
    return this.api(GET, `users?sortBy=errors&sinceDays=8&limit=${limit}&offset=${offset}`)
  }

  async listTopQueriesByCostCurrentUser(limit: number, offset: number): Promise<PageQueries> {
    return this.api(GET, `user/current?sortBy=cost&limit=${limit}&offset=${offset}`)
  }

  async listTopQueriesByErrorsCurrentUser(limit: number, offset: number): Promise<PageQueries> {
    return this.api(GET, `user/current?sortBy=errors&limit=${limit}&offset=${offset}`)
  }

}
