import React from 'react'
import clsx from 'clsx'
import IconButton from '@mui/material/IconButton'
import { Link, useLocation } from '@reach/router'

function IconButtonLink ({ activeClass = '', className = '', ...props }) {
  const location = useLocation()
  const active = location && (location.pathname.startsWith(props.to))

  return (
    <IconButton
      component={Link}
      className={active ? clsx(className, activeClass) : className}
      {...props}
      size="large" />
  );
}

export default IconButtonLink
