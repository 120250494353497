import React from 'react'
import Button from '@mui/material/Button'

import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import type { GraphTableAction, GraphTableRowAction, GraphTableDataRow } from './types'
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { styled } from "@mui/material/styles";

const StyledIconButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[500],
  "&:hover": {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1)
  },
  "&:focus": {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1)
  },
}))

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    disabledButton: {
      color: theme.typography.body2.color
    },
    iconMargin:{
      marginLeft: theme.spacing(1)
    }
  }))

function GraphTableToolbar({ actions }: { actions: GraphTableAction[], refresh?: () => void })
function GraphTableToolbar({ actions, row }: { actions: GraphTableRowAction[], row: GraphTableDataRow, refresh?: () => void })
function GraphTableToolbar({ actions, row = null, refresh }: { actions: GraphTableAction[] | GraphTableRowAction[], row?: GraphTableDataRow, refresh?: () => void }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      {(actions as any).filter(action => !!action).map(({ title, icon: Icon, onClick, customRender, disabled,hideTitle }, index) => {
        const handleClick = (ev) => {
          onClick && (row ? onClick(ev, row, refresh) : onClick(ev, refresh))
        }

        return (
          <Tooltip key={index} title={title}>
            {customRender ? customRender()
              : <StyledIconButton
                className={disabled ? classes.disabledButton : classes.iconButton}
                onClick={disabled ? undefined : handleClick}
                size="medium">
                {!hideTitle && title}
                {Icon ? <Icon className={!hideTitle && classes.iconMargin} /> : <HelpOutlineIcon className={!hideTitle && classes.iconMargin}/>}
              </StyledIconButton>
            }
          </Tooltip>
        );
      })}
    </React.Fragment>
  );
}

export default GraphTableToolbar
