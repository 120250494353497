import React from 'react'
import MuiDataTable, { debounceSearchRender } from 'mui-datatables'
import Typography from '@mui/material/Typography'
import RefreshIcon from '@mui/icons-material/Refresh'
import Loading from '../Loading'
import CircularProgress from '@mui/material/CircularProgress'
import GraphTableSnackbar from './GraphTableSnackbar'
import { titleCaps } from '../../../util'
import { useGraph } from '../../../context/ApiContext'
import { usePopup } from '../../../hooks/modals'
import { graphFetch, graphExtract, graphExtractCount, graphColumns, mapColumn, graphSearch } from './functions'
import { useDeepEffect } from '../../../hooks/useDeepEffect'
import type { GraphTableProps, GraphTableColumn, GraphTableRefAttributes } from './types'
import type { MUIDataTableProps, MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables'
import GraphTableToolbar from './GraphTableToolbar'
import { styled } from '@mui/material/styles'

const HideSearchIconDiv = styled('div')`
  .MUIDataTableSearch-main-46 > button {
    display: none;
  }
`

function GraphTable(props: GraphTableProps, ref: React.Ref<GraphTableRefAttributes>) {
  const scrollRef = React.useRef()
  const cgApi = useGraph()
  const setPopup = usePopup()
  const {
    title = `${titleCaps(props.type)}`,
    hideTitle = props.hideTitle,
    query,
    variables,
    type,
    serverSort = false,
    serverPagination = false,
    serverFilters = false,
    searchFields = null,
    searchQuery = graphSearch,
    pageSize: pageSizeProp = 25,
    data: propData,
    columns: propColumns = graphColumns,
    columnAttributes = {},
    fetch = graphFetch(cgApi),
    extract = graphExtract,
    extractCount = graphExtractCount,
    tableActions = [],
    rowActions = [],
    transform = false
  } = props

  let mounted = true
  const transformData = (raw) => transform ? raw.map(transform) : raw
  const extractData = (response) => extract ? extract(response, type) : response

  const isRemote = !propData // if we're fetching remote data
  const [rawData, setRawData] = React.useState(propData || [])
  const [isLoading, setIsLoading] = React.useState(!!propData)
  const [data, setData] = React.useState(null)
  const [columns, setColumns] = React.useState<GraphTableColumn[]>([])
  const [count, setCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(pageSizeProp)
  const [orderBy, setOrderBy] = React.useState({})
  const [where, setWhere] = React.useState({})
  const refresh = () => { !isLoading && fetchData() }

  React.useImperativeHandle(ref, () => ({
    getData: () => data,
    getRawData: () => rawData,
    getColumns: () => columns,
    refresh
  }))

  // Update Raw Data
  const fetchData = () => {
    if (propData) {
      updateRaw(propData)
      setIsLoading(false)
    } else {
      setIsLoading(true)
      fetch(query, {
        variables,
        serverSort,
        orderBy,
        serverPagination,
        serverFilters,
        where,
        page,
        pageSize
      }).then(response => {
        if (mounted) {
          setIsLoading(false)
          updateRaw(extractData(response))
          setCount(extractCount(response, type))
        }
      }).catch(error => {
        setIsLoading(false)
        setPopup(<GraphTableSnackbar error={error} />)
      })
    }
    return () => { mounted = false }
  }

  // Transform raw data and update state
  const updateRaw = (raw) => {
    const data = transformData(raw)
    setRawData(raw)
    setData(data)
    updateColumns(data)
  }

  // Calculate columns to display and update state
  const updateColumns = (data) => {
    if (!data || !data.length) return // Dont update columns if data is empty
    const newColumns = (typeof propColumns === 'function') ? propColumns(data, type) : propColumns
    if (newColumns && newColumns.length !== columns.length) {
      setColumns(newColumns)
    } else if (!newColumns) {
      setColumns([])
    }
  }

  // Like useEffect but doing deep obj comparison instead of reference check, reload data whenever any of these change
  useDeepEffect(fetchData, [propData, query, variables, page, pageSize, orderBy, type, where])

  // Update Columns
  useDeepEffect(() => {
    updateColumns(data)
  }, [propColumns])


  interface MuiDataTableOptionsExtended extends MUIDataTableOptions {
    searchAlwaysOpen: boolean
  }

  // MUI Data Table Options
  const tableOptions: MuiDataTableOptionsExtended = {
    viewColumns: false,
    download: false,
    searchAlwaysOpen: !isRemote || serverFilters,
    filter: (!isRemote || serverFilters) && !!columns.filter(({ filter }) => filter).length,
    print: false,
    search: false,
    responsive: 'standard',
    enableNestedDataAccess: '.',
    fixedSelectColumn: false,
    selectableRows: 'none',
    selectableRowsHeader: false,
    serverSide: serverPagination,
    sort: !serverPagination || serverSort,
    count: serverPagination ? count : undefined,
    customSearchRender: debounceSearchRender(250),
    pagination: true,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [pageSizeProp, 50, 100],
    onTableChange: (action, state) => {
      if (action === 'viewColumnsChange') {
        state.columns.forEach((col, i) => {
          const columnIndex = columns.findIndex(tableCol => tableCol.field === col.name)
          if (columnIndex !== -1) columns[columnIndex].hidden = !(col.display && col.display !== 'false')
        })
        setColumns(columns)
      }
    },

    onChangePage: (page) => {
      setPage(page);
      (scrollRef.current as any).scrollIntoView({ behavior: 'smooth' })
    },

    onChangeRowsPerPage: (rows) => setPageSize(rows),
    elevation: 0
  }

  if (serverFilters) {
    tableOptions.onSearchChange = (searchText: string) => {
      setWhere({
        ...where,
        ...searchQuery(searchText, searchFields)
      })
    }

    // This logic is kind of crappy and should be moved somewhere cleaner
    tableOptions.onFilterChange = (_, filterList) => {
      const filter = filterList.reduce((acc, value, index) => {
        if (value.length) {
          acc[(tableColumns[index] as any).name] = { _ilike: `${value[0]}%` }
        } else {
          delete acc[(tableColumns[index] as any).name]
        }
        return acc
      }, Object.assign({}, where))
      setWhere(filter)
    }
  }

  if (serverPagination) {
    tableOptions.onColumnSortChange = (columnName, direction) => {
      setOrderBy({
        [columnName]: direction
      })
    }
  }

  const after = []

  if (isRemote || (tableActions && tableActions.length)) {
    tableOptions.customToolbar = () => <GraphTableToolbar
      actions={isRemote ? [
        {
          title: 'Refresh',
          icon: RefreshIcon,
          onClick: refresh,
          disabled: isLoading
        },
        ...tableActions
      ] : tableActions} refresh={refresh}
    />
  }

  if (rowActions && rowActions.length) {
    const actions: MUIDataTableColumn = {
      name: 'actions',
      label: 'Actions',
      options: {
        customBodyRenderLite: (_, row) => <GraphTableToolbar actions={rowActions} row={data[row]} refresh={refresh} />,
        sort: false
      }
    }
    after.push(actions)
  }

  // MUI Data Table Columns
  const tableColumns: MUIDataTableProps['columns'] = [
    ...columns.map(column => mapColumn(data, column, columnAttributes)),
    ...after
  ]
  return (
    <div ref={scrollRef}>
      {(!data) && isLoading ? <Loading />
        :
        <HideSearchIconDiv>
          <MuiDataTable
            title={<Typography variant='h6'>{!hideTitle && title}
              {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
            </Typography>}
            data={data || []}
            columns={tableColumns}
            options={tableOptions}
          />
        </HideSearchIconDiv >
      }
    </div>
  )
}

export default React.forwardRef(GraphTable)
