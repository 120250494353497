import React from 'react'
import PaperBody from '../common/layout/PaperBody'
import AddIcon from '@mui/icons-material/Add';
import NoDataAvailablePlaceholder from '../../components/common/NoDataAvailablePlaceholder'
import HeaderWithActions from '../common/HeaderWithActions'
import NavGrid from '../common/NavGrid'
import { titleCaps } from '../../util'

import { useRole } from '../../context/AuthContext'
import { PERMISSION_CUSTOMER_DATA_WRITE } from '../../config/config'
import type { CustomerDataCustomer } from '../../api/CustomerData'


export type CustomerBodyProps = {
  customer: CustomerDataCustomer
  onImportClick: (customerId: string, close?: boolean) => any
}

function CustomerBody ({ customer, onImportClick: handleImport } : CustomerBodyProps) {
  const { customerId, types = [] } = customer
  const title = titleCaps(customerId)
  const canWrite = useRole().hasPermission(PERMISSION_CUSTOMER_DATA_WRITE)
  const importAction = {
    title: `Import Data`,
    onClick: () => handleImport(customerId),
    icon: AddIcon
  }
  
  // Do not display empty customers we cant write to!
  if (!types.length && !canWrite) {
    return <></>
  }

  return (
    <PaperBody>
      <HeaderWithActions defaultAction={canWrite && importAction}>{title}</HeaderWithActions>
      <NavGrid
        items={types.map(type => ({
          title: type.type,
          target: `/customer-data/${customerId}/${type.type}`,
          count: type.entries
        }))} placeholder={<NoDataAvailablePlaceholder title="There's no data yet for this customer..." actionTitle='Import Customer Data...' actionOnClick={() => handleImport(customerId)} />}
      />
    </PaperBody>)
}

export default CustomerBody
