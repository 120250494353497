import Browser from '../../components/graphiql/Browser'
import PaperBody from '../../components/common/layout/PaperBody'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Link } from '@reach/router'
import Box from '@mui/system/Box'
import { useStyles } from '../../components/common/ObjectEditor/styles'
import { styled } from '@mui/material/styles'
import CustomDivider from '../../components/common/CustomDivider'

const ExternalDataTitle = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).h5.fontFamily}`,
  fontSize: `${(theme.typography as any).h4.fontSize}`
}))

const query = `query CustomerData (
  $customerId: String = "", $type: String = ""
) {
  customer_data(where: {
    customerId: {_eq: $customerId},
    type:{_eq: $type},
  }, limit: 20, offset: 0) {
    type
    key
    data
  }
  }

query CustomerDataRelationships (
  $customerId: String = "", $type: String = ""
) {
  customer_data(where: {
    customerId: {_eq: $customerId},
    type:{_eq: $type},
  }, limit: 20, offset: 0) {
    type
    key
    dateKey
    fixture {
      name
    }
    competitor {
      name
    }
    competition {
      name
    }
    season {
      name
    }
  }
}
`

function TypeExplore(props) {
  const { customerId, type } = props
  const classes = useStyles()

  return (
    <>
      <Box className={classes.commonContainer}>
        <ExternalDataTitle>
          <b>
            {customerId}
          </b>
        </ExternalDataTitle>
      </Box>
      <Tabs value={1} textColor='inherit'>
        <Tab component={Link} to='../' textColor='inherit' label='Entries' />
        <Tab label='Query Explorer' />
      </Tabs>
      <CustomDivider />
      <PaperBody noPadding>
        <Browser height='calc(100vh - 300px)' query={query} variables={JSON.stringify({ customerId, type }, null, 2)} />
      </PaperBody>
    </>
  )
}

export default TypeExplore
