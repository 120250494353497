import { CircularProgress, Typography } from '@mui/material'
import moment from 'moment-timezone'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import React, { useEffect, useState } from 'react'
import { QueryCost } from '../../api/HasuraReport'
import { useHasuraReport } from '../../context/ApiContext'
import { useDeepEffect } from '../../hooks/useDeepEffect'
import CostChip from '../common/CostChip/CostChip'
import QueryDetailsDialog, { QueryDetailsDialogProperties } from './QueryDetails'

export interface QueryTableProperties {
  title: string,
  withError?: boolean,
  setError: Function,
  currentUser?: boolean
}

export interface TableState {
  data?: QueryCost[],
  count?: number,
  isLoading: boolean,
}

export default function QueryTable(props: QueryTableProperties) {
  const [pageSize, setPageSize] = React.useState(5)
  const [page, setPage] = React.useState(0)
  const [state, setState] = React.useState({
    data: [],
    count: 0,
    isLoading: false
  } as TableState)

  const hasuraReport = useHasuraReport()
  const dateTimeColumnRender = (value: string) => {
    return moment.tz(value, 'UTC').format('ddd, MMM Do YYYY, h:mm:ss a')
  }
  const queryCostRenderer = (value: number) => {
    return <CostChip queryCost={value} />
  }

  const columns: MUIDataTableColumnDef[] =
    [
      props.currentUser ? undefined : { label: 'Username', name: 'username' },
      props.currentUser ? undefined : { label: 'Role', name: 'hasuraRole' },
      props.currentUser ? undefined : { label: 'Count', name: 'countQuery' },
      props.withError ? undefined : { label: 'Cost', name: 'cost', options: { customBodyRender: queryCostRenderer } },
      { label: 'Operation Name', name: 'operationName' },
      { label: 'Ran At', name: 'lastUpdatedAt', options: { customBodyRender: dateTimeColumnRender } }
    ].filter(e => e)


  const [queryDetailsProps, setqueryDetailsProps] = useState({ open: false } as QueryDetailsDialogProperties)
  const closeConfirm = () => setqueryDetailsProps({ open: false })
  const openQueryDetails = (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
    setqueryDetailsProps({
      open: true,
      query: state.data[rowMeta.dataIndex].query,
      variables: state.data[rowMeta.dataIndex].variables,
      onClose: () => closeConfirm()
    })
  }

  const changePage = async (currentPage: number) => {
    setState({ isLoading: true })
    setPage(currentPage)
    const offset = (currentPage) * pageSize
    try {
      const page = await getReportsInformation(pageSize, offset)
      setState({ data: page.content, count: page.count, isLoading: false })
    } catch (err) {
      //do nothing some tables could be empty.
    }
  }

  const getReportsInformation = async (pageSize: number, offset: number) => {
    if (props.currentUser) {
      return props.withError ?
        await hasuraReport.listTopQueriesByErrorsCurrentUser(pageSize, offset)
        :
        await hasuraReport.listTopQueriesByCostCurrentUser(pageSize, offset)
    } else {
      return props.withError
        ? await hasuraReport.listTopQueriesByErrors(pageSize, offset)
        : await hasuraReport.listTopQueriesByCost(pageSize, offset)
    }
  }

  const updatePageSize = async (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  const loadData = async function loadData() {
    await changePage(page)
  }

  // reload the page when the page size changed
  useDeepEffect(() => { loadData() }, [pageSize])

  // load the first page
  useEffect(() => { loadData() }, [])// eslint-disable-line

  const options: MUIDataTableOptions = {
    filter: false,
    download: false,
    print: false,
    search: false,
    responsive: 'standard',
    enableNestedDataAccess: '.',
    fixedSelectColumn: false,
    selectableRows: 'none',
    selectableRowsHeader: false,
    serverSide: true,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [5, 10, 20],
    sort: false,
    pagination: true,
    count: state.count,
    setTableProps: () => ({ className: 'reports' }),
    onChangePage: changePage,
    onRowClick: openQueryDetails,
    onChangeRowsPerPage: updatePageSize
  }

  return (
    <div>
      <MUIDataTable
        title={<Typography variant='h6'>{props.title}
          {state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
        </Typography>}
        data={state.data || []}
        columns={columns}
        options={options}
      />
      {queryDetailsProps.open && <QueryDetailsDialog {...queryDetailsProps} />}
    </div>
  )
}
