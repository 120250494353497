import React from 'react'
import Link from '@mui/material/Link'
import { GeniusTheme } from '../../enhancers/withGeniusTheme'
import { styled } from '@mui/material/styles'

import packageJson from '../../../package.json';
const { version } = packageJson;

const FooterStyled = styled('footer')`
    clear: both;
    position: relative;
    min-height: 63px;
    bottom: 0px;
    margin-top: auto;
    border-top-style: solid;
    border-top-color:${props => props.theme.palette.grey[200]};
    border-top-width: 1px;
    display: flex;
    align-items: center;
`

const TextContainer = styled('div')`
    width: 100%;
`

const Copryrigh = styled('div')`
    font-family:'Red Hat Text';
    font-style: normal;
    font-weight: normal;
    float: left;
    color: #3A3A3C;
    flex: 1;
    text-align: left;
    padding-left: ${props => props.theme.spacing(2)};
`

const LastLogin = styled('div')`
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: normal;
    float: right;
    color: #48484A;
    flex: 1;
    text-align: right;
    padding-right: ${props => props.theme.spacing(2)};
`

interface FooterProps {
  lastLoginDate: string;
  classes?: any;
}

function Footer({ lastLoginDate }: FooterProps) {
  return (
    <FooterStyled>
      <TextContainer>
        <Copryrigh theme={GeniusTheme}>
          Content Graph Web v{version} · Copyright ©{' '}
          <Link color='inherit' href='https://www.geniussportsmedia.com/'>
            Genius Sports Media
          </Link>{' '}
          {new Date().getFullYear()}.
        </Copryrigh>
        <LastLogin theme={GeniusTheme}>Last login on {lastLoginDate}</LastLogin>
      </TextContainer>
    </FooterStyled>
  )
}

export default Footer
