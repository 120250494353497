import React from 'react'
import Home from './pages/Home'
import Explore from './pages/Explore'
import CustomerDataWrapper from './pages/customerdata/Wrapper'
import CustomerDataHome from './pages/customerdata'
import TypeEntries from './pages/customerdata/TypeEntries'
import TypeExplore from './pages/customerdata/TypeExplore'
import TranslationsHome from './pages/translations'
import TranslationsWrapper from './pages/translations/Wrapper'
import TranslationsLocale from './pages/translations/Locale'
import TranslationsLocaleExplore from './pages/translations/LocaleExplore'
import Fixtures from './pages/graph-table/fixtures'
import Users from './pages/Users'
import NotFound from './pages/NotFound'
import HasuraTrafficReport from './pages/Reports'

import { Router } from '@reach/router'
import withGeniusTheme from './enhancers/withGeniusTheme'
import withLoginForm from './enhancers/withLoginForm'
import { withAuthContextProvider } from './context/AuthProvider'
import { withPopupProvider } from './hooks/modals'

const App = () => (
  <Router>
    <Home path='/' />
    <Explore path='/graphiql' />
    <CustomerDataHome path='/customer-data' />
    <CustomerDataWrapper path='/customer-data/:customerId'>
      <CustomerDataHome path='/' noWrapper />
      <TypeEntries path=':type' />
      <TypeExplore path=':type/explore' />
    </CustomerDataWrapper>
    <TranslationsHome path='/translations' />
    <TranslationsWrapper path='/translations/:customerId'>
      <TranslationsHome path='/' noWrapper />
      <TranslationsLocale path=':locale' />
      <TranslationsLocaleExplore path=':locale/explore' />
    </TranslationsWrapper>
    <Fixtures path='/fixtures' />
    <Users path='/users' />
    <HasuraTrafficReport path='/reports' />
    <NotFound default />
  </Router>
)

export default withGeniusTheme(
  withAuthContextProvider(
    withPopupProvider(
      withLoginForm(App)
    )
  )
)
