import React from 'react'
import PaperBody from '../common/layout/PaperBody'
import UserTable from './UserTableComponent'
import { useApi } from '../../context/ApiContext'
import { usePopup } from '../../hooks/modals'
import Loading from '../common/Loading'
import QuickSnack from '../common/QuickSnack'
import CreateUserDialog from './CreateUserDialog'
import ModifyUserDialog from './ModifyUserDialog'
import CopyUserDialog from './CopyUserDialog'
import { styled } from '@mui/material/styles'
import { useStyles } from '../common/ObjectEditor/styles'
import Box from '@mui/system/Box'
import CustomDivider from '../common/CustomDivider'
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';

const UsersManagementTitle = styled('div')(({ theme }) => ({
    fontFamily: `${(theme.typography as any).h5.fontFamily}`,
    fontSize: `${(theme.typography as any).h5.fontSize}`,
    lineHeight: theme.spacing(4),
    float: 'left'
}))

const Spaced = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(2)
}))
const HalfSpaced = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(1)
}))

const UsersManagementHeader = styled('div')(({ theme }) => ({
    minHeight: theme.spacing(4)

}))


const StyledIconButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(3),
    marginRight: theme.spacing(2),
    '&:hover': { backgroundColor: theme.palette.secondary.main },
    '&:focus': { backgroundColor: theme.palette.secondary.main },
    float: 'right'
}))

const UsersManagementMessage = styled('div')(({ theme }) => ({
    fontFamily: `${(theme.typography as any).subtitle1.fontFamily}`,
    fontSize: `${(theme.typography as any).h6.fontSize}`,
    color: `${(theme.palette.grey['400'])}`,
    paddingTop: theme.spacing(2)
}))


function createNewUsersButton(actions) {
    return (<Tooltip title="custom icon">
        <StyledIconButton onClick={actions.add} size='large'>
            <Add /> <span>New User</span>
        </StyledIconButton>
    </Tooltip>)
}

function Users() {
    const { users: api } = useApi()
    const [isLoading, setLoading] = React.useState(true)
    const [isError, setError] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [roles, setRoles] = React.useState([])
    const setPopup = usePopup()
    const classes = useStyles()

    React.useEffect(() => {
        Promise.all([api.listUsers(), api.listRoles()]).then(([users, roles]) => {
            setLoading(false)
            setUsers(users)
            setRoles(roles)
        }).catch((error) => {
            setLoading(false)
            setError(true)
            setUsers([])
            setRoles([])
        })
    }, [api])

    const actions = {
        add: () => {
            setPopup(
                <CreateUserDialog
                    roles={roles} onSubmit={user => {
                        api.createUser(user).then(response => {
                            setUsers([{
                                username: user.username,
                                email: user.email,
                                role: user.role
                            }].concat(users))
                            setPopup(
                                <QuickSnack title='The user has been created!' autoHideDuration={null} severity='success'>
                                    <b>Username:</b> {response.username}<br />
                                    <b>Password:</b> {response.temporaryPassword}<br />
                                </QuickSnack>
                            )
                        }).catch(e => {
                            setPopup(
                                (e.status === 403)
                                    ? <QuickSnack title='Error creating user!' severity='error'>
                                        A user called {user.username} already exists!<br />
                                    </QuickSnack>
                                    : <QuickSnack title='Error creating user!' severity='error'>
                                        We were unable to create user {user.username}.<br />
                                        Please try again later.
                                    </QuickSnack>
                            )
                        })
                    }}
                />
            )
        },
        copy: (user) => {
            setPopup(
                <CopyUserDialog
                    username={user.username} onSubmit={(username, copyFrom) => {
                        api.copyUserPermissions(username, copyFrom).then(response => {
                            setPopup(
                                <QuickSnack title='The user permissions have been copied!' autoHideDuration={null}
                                    severity='success'>
                                    <b>Username:</b> {response.username}<br />
                                    <b>Copied permissions from:</b> {copyFrom}<br />
                                </QuickSnack>
                            )
                        }).catch(e => {
                            setPopup(
                                <QuickSnack title='Error copying permissions from user!' severity='error'>
                                    We were unable to copy permissions from user {copyFrom}.<br />
                                    Please try again later.
                                </QuickSnack>
                            )
                        })
                    }}
                />
            )
        },
        edit: (user) => {
            setPopup(
                <ModifyUserDialog
                    username={user.username} roles={roles} onSubmit={user => {
                        api.updateUser(user).then(response => {
                            // TODO: update table with updated user
                            setPopup(
                                <QuickSnack title={`User ${user.username} has been updated!`} severity='success' />
                            )
                        }).catch(e => {
                            setPopup(
                                <QuickSnack title='Error updating user!' severity='error'>
                                    We were unable to update user {user.username} with your changes.<br />
                                    Please try again later.
                                </QuickSnack>
                            )
                        })
                    }}
                />
            )
        },
        delete: (user) => user && user.username && api.deleteUser(user).then(() => {
            setUsers(users.filter(i => i.username !== user.username))
            setPopup(
                <QuickSnack title='User deleted!' severity='success'>
                    We have successfully deleted {user.username}.<br />
                </QuickSnack>
            )
        }).catch(e => {
            setPopup(
                <QuickSnack title='Error deleting user!' severity='error'>
                    We were unable to delete {user.username}.<br />
                    Please try again later.
                </QuickSnack>
            )
        }),
        reset: (user) => user && user.username && api.resetUserPassword(user).then((response) => {
            setPopup(
                <QuickSnack title='The password has been reset!' autoHideDuration={null} severity='success'>
                    <b>Username:</b> {response.username}<br />
                    <b>Password:</b> {response.temporaryPassword}<br />
                </QuickSnack>
            )
        }).catch(e => {
            setPopup(
                <QuickSnack title='Error resetting password!' severity='error'>
                    We were unable to reset the password for {user.username}.<br />
                    Please try again later.
                </QuickSnack>
            )
        })
    }

    return (
        <Box>
            <Box className={classes.commonContainer}>
                <UsersManagementHeader>
                    <UsersManagementTitle><b>Users</b></UsersManagementTitle>
                    {createNewUsersButton(actions)}
                </UsersManagementHeader>
                <UsersManagementMessage>Manage permissions and passwords for all our users.</UsersManagementMessage>
            </Box>
            <Spaced />
            <HalfSpaced />
            <CustomDivider />
            <Spaced />
            {
                isLoading ? <Loading /> : (isError
                    ? (
                        <PaperBody>
                            <h3>Cannot load users</h3>
                            <p>Your user is probably not authorized to use the User Manager.</p>
                        </PaperBody>
                    ) : (
                        <PaperBody noPadding>
                            <UserTable title='Users' data={users} actions={actions} />
                        </PaperBody>
                    )
                )
            }
        </Box>
    )
}


export default Users
