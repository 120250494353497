import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function ConfirmDialog (props) {
  return (
    <Dialog
      open={props.open || false}
      onClose={props.onCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{props.title || 'Are you sure?'}</DialogTitle>
      {(props.children || props.description) && <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {props.children || props.description}
        </DialogContentText>
      </DialogContent>}
      <DialogActions>
        <Button onClick={props.onCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={props.onConfirm} color='primary' autoFocus>
          {props.action || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
