import { buildClientSchema, getIntrospectionQuery, parse } from 'graphql'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { fetchJson } from './HttpClient'
import type { HttpClient } from './HttpClient'
import { memoize } from './Cache'
import AutoComplete from './AutoComplete'
import { getToken } from "./Auth"


export interface ContentGraphResponse {
  data?: any,
  errors?: {
    extensions?: any,
    message: string
  }[]
}

export interface GraphOperation {
  query?: string;
  variables?: Object;
  operationName?: string;
  [key: string]: any;
}

export class ContentGraphError extends Error {
  status?: number
  errors: ContentGraphResponse['errors']
  constructor (errors: ContentGraphResponse['errors']) {
    const { message, extensions } = errors[0]
    super(message)
    this.errors = errors
    this.status = (extensions && extensions.code === 'invalid-jwt') ? 401 : 400
  }
}

export default class ContentGraph {
  name = 'ContentGraph'

  client: HttpClient
  apiUrl: string
  AutoComplete: AutoComplete

  constructor(apiUrl, httpClient: HttpClient = fetchJson) {
    this.apiUrl = apiUrl
    this.client = httpClient
    this.AutoComplete = new AutoComplete(this)
    this.getSchema = memoize(this, 'getSchema')
    this.getFixture = memoize(this, 'getFixture')
    this.getCompetitor = memoize(this, 'getCompetitor')
    this.getCompetition = memoize(this, 'getCompetition')
    this.getSeason = memoize(this, 'getSeason')
    this.getRound = memoize(this, 'getRound')
  }

  async graph(query: string, variables: any = null, operationName?: string): Promise<ContentGraphResponse> {
    return this.fetch({
      query,
      variables,
      operationName: operationName || undefined
    }).then(response => response.errors
       ? Promise.reject(new ContentGraphError(response.errors))
       : response)
  }

  async getSchema() {
    return this.graph(getIntrospectionQuery()).then(result => buildClientSchema(result.data))
  }

  async getFixture(id: string) {
    return this.graph(`
    query ($id: String) {
      fixtures(where: {fixtureId: {_eq: $id}}) {
        name
        scheduledStartTime
        sportId
        sportName
        competitionId
        competitionName
        seasonId
        seasonName
        roundId
        roundName
      }
    }
    `, {
      id: id.toString()
    }).then(response =>
      response.data && response.data.fixtures && response.data.fixtures[0])
  }

  async getCompetitor(id: string) {
    return this.graph(`
    query ($id: String) {
      competitors(where: {competitorId: {_eq: $id}}) {
        name
        sportId
      }
    }
    `, {
      id: id.toString()
    }).then(response =>
      response.data && response.data.competitors && response.data.competitors[0])
  }

  async getCompetition(id: string) {
    return this.graph(`
    query ($id: String) {
      competitions(where: {competitionId: {_eq: $id}}) {
        name
        sportId
        sportName
      }
    }
    `, {
      id: id.toString()
    }).then(response =>
      response.data && response.data.competitions && response.data.competitions[0])
  }

  async getSeason(id: string) {
    return this.graph(`
    query ($id: String) {
      seasons(where: {seasonId: {_eq: $id}}) {
        name
        sportId
        sportName
        competitionId
        competitionName
      }
    }
    `, {
      id: id.toString()
    }).then(response =>
      response.data && response.data.seasons && response.data.seasons[0])
  }

  async getRound(id: string) {
    return this.graph(`
    query ($id: String) {
      rounds(where: {seasonId: {_eq: $id}}) {
        name
        sportId
        sportName
        competitionId
        competitionName
        seasonId
        seasonName
      }
    }
    `, {
      id: id.toString()
    }).then(response =>
      response.data && response.data.rounds && response.data.rounds[0])
  }

  async fetch(body: GraphOperation) {
    return this.client(this.apiUrl, {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(response => response.json)
  }

  subscriptionFetch(body: GraphOperation) {
    if (isSubscription(body)) {
      return createWsClient(this.apiUrl).request(body)
    }
    return this.fetch(body)
  }
}

const createWsClient = (url) => {
  const gqlUrl = new URL(/^\//.test(url) ? `${location.origin}${url}` : url); // eslint-disable-line no-restricted-globals
  const websocketProtocol = gqlUrl.protocol === 'https:' ? 'wss' : 'ws';
  const graphqlUrl = `${websocketProtocol}://${gqlUrl.host}${gqlUrl.pathname}`;
  const client = new SubscriptionClient(graphqlUrl, {
    connectionParams: {
      headers: {
        'Authorization': `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      },
    },
    reconnect: true,
  });
  return client;
}

const isSubscription = (body: GraphOperation) => {
  const queryDoc = parse(body.query);
  for (const definition of queryDoc.definitions) {
    if (definition.kind === 'OperationDefinition') {
      const operation = definition.operation;
      if (operation === 'subscription') {
        return true;
      }
    }
  }
  return false;
};
