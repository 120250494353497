import React from 'react'
import { MapQuery } from './ExamplesType'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material'

const StyledPopularQueriesContainer = styled('div')`
  display: "flex";
  flex-direction: "column";
  padding-left: ${(props) => props.theme.spacing(3.2)};
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(1)};
  letter-spacing: 0.4px;
  .title {
    margin-bottom: ${(props) => props.theme.spacing(1)};
  }
  .chipPopularQuery {
    margin: ${(props) => props.theme.spacing(0.25)};
    font-size: ${(props) => props.theme.typography.subtitle1.fontSize};
  }
`

interface PopularQueriesProps {
  popularQueryIds: string[];
  queries: MapQuery;
  updateDisplayedQuery: (idQuery) => void;
}
const PopularQueries = ({
  popularQueryIds,
  queries,
  updateDisplayedQuery
}: PopularQueriesProps) => {
  return (
    <StyledPopularQueriesContainer>
      <div className='title'>Popular Queries</div>
      <div>
        {popularQueryIds.map((popularQueryId) => (
          <Chip
            className='chipPopularQuery'
            key={popularQueryId}
            label={queries[popularQueryId].name}
            onClick={() => updateDisplayedQuery(popularQueryId)}
            size='small'
          />
        ))}
      </div>
    </StyledPopularQueriesContainer>
  )
}

export default PopularQueries
