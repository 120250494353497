import React from 'react'
import Tooltip from '@mui/material/Tooltip'

import Sports from '@mui/icons-material/Sports'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import PoolIcon from '@mui/icons-material/Pool'

import SportsBaseball from '@mui/icons-material/SportsBaseball'
import SportsBasketball from '@mui/icons-material/SportsBasketball'
import SportsCricket from '@mui/icons-material/SportsCricket'
import SportsEsports from '@mui/icons-material/SportsEsports'
import SportsFootball from '@mui/icons-material/SportsFootball'
import SportsGolf from '@mui/icons-material/SportsGolf'
import SportsHandball from '@mui/icons-material/SportsHandball'
import SportsHockey from '@mui/icons-material/SportsHockey'
// import SportsKabaddi from '@mui/icons-material/SportsKabaddi'
import SportsMma from '@mui/icons-material/SportsMma'
import SportsMotorsports from '@mui/icons-material/SportsMotorsports'
import SportsRugby from '@mui/icons-material/SportsRugby'
import SportsSoccer from '@mui/icons-material/SportsSoccer'
import SportsTennis from '@mui/icons-material/SportsTennis'
import SportsVolleyball from '@mui/icons-material/SportsVolleyball'
import Rowing from '@mui/icons-material/Rowing'

import type { GraphTableSport } from './types'

function GraphTableSportIcon ({ sportId, ...props }) {
  const sport = ContentGraphSports.find(sport => sport.sportId == sportId) // eslint-disable-line eqeqeq
  const name = (sport && sport.name) || `sportId: ${sportId}`
  const Icon = ((sport && sport.icon) || Sports) as any
  return <Tooltip title={name}><Icon {...props} /></Tooltip>
}

export default React.memo(GraphTableSportIcon)

export const ContentGraphSports: GraphTableSport[] = [
  {
    sportId: '1',
    name: 'Athletics',
    icon: DirectionsRunIcon
  },
  {
    sportId: '2',
    name: 'Australian Rules',
    icon: SportsFootball
  },
  {
    sportId: '3',
    name: 'Baseball',
    icon: SportsBaseball
  },
  {
    sportId: '4',
    name: 'Basketball',
    icon: SportsBasketball
  },
  {
    sportId: '5',
    name: 'Boxing',
    icon: SportsMma
  },
  {
    sportId: '6',
    name: 'Cricket',
    icon: SportsCricket
  },
  {
    sportId: '7',
    name: 'Cycling',
    icon: DirectionsBikeIcon
  },
  {
    sportId: '8',
    name: 'Darts'
  },
  {
    sportId: '9',
    name: 'Counter-Strike',
    icon: SportsEsports
  },
  {
    sportId: '10',
    name: 'Football',
    icon: SportsSoccer
  },
  {
    sportId: '11',
    name: 'League of Legends',
    icon: SportsEsports
  },
  {
    sportId: '12',
    name: 'Golf',
    icon: SportsGolf
  },
  {
    sportId: '13',
    name: 'Greyhounds'
  },
  {
    sportId: '14',
    name: 'Horse Racing'
  },
  {
    sportId: '15',
    name: 'Ice Hockey',
    icon: SportsHockey
  },
  {
    sportId: '16',
    name: 'Motor Sport',
    icon: SportsMotorsports
  },
  {
    sportId: '17',
    name: 'American Football',
    icon: SportsFootball
  },
  {
    sportId: '18',
    name: 'Dota 2',
    icon: SportsEsports
  },
  {
    sportId: '21',
    name: 'Winter Sports',
    icon: AcUnitIcon
  },
  {
    sportId: '22',
    name: 'Snooker'
  },
  {
    sportId: '23',
    name: 'Specials'
  },
  {
    sportId: '24',
    name: 'Tennis',
    icon: SportsTennis
  },
  {
    sportId: '26',
    name: 'Yachting',
    icon: DirectionsBoatIcon
  },
  {
    sportId: '28',
    name: 'Fishing'
  },
  {
    sportId: '7658',
    name: 'Pool'
  },
  {
    sportId: '8554',
    name: 'Bowls'
  },
  {
    sportId: '62969',
    name: 'Rowing',
    icon: Rowing
  },
  {
    sportId: '70679',
    name: 'Equestrian'
  },
  {
    sportId: '73743',
    name: 'Rugby League',
    icon: SportsRugby
  },
  {
    sportId: '73744',
    name: 'Rugby Union',
    icon: SportsRugby
  },
  {
    sportId: '78155',
    name: 'Politics'
  },
  {
    sportId: '78156',
    name: 'Financial'
  },
  {
    sportId: '11205868',
    name: 'Virtual Greyhound Racing'
  },
  {
    sportId: '11205869',
    name: 'Virtual Motor Racing'
  },
  {
    sportId: '11205877',
    name: 'Virtual Archery'
  },
  {
    sportId: '11205878',
    name: 'Virtual Chariot Racing'
  },
  {
    sportId: '11205879',
    name: 'Virtual Badminton'
  },
  {
    sportId: '11205880',
    name: 'Virtual Speed Skating'
  },
  {
    sportId: '90164',
    name: 'Poker'
  },
  {
    sportId: '437727',
    name: 'Squash'
  },
  {
    sportId: '491393',
    name: 'Futsal',
    icon: SportsSoccer
  },
  {
    sportId: '550153',
    name: 'Swimming',
    icon: PoolIcon
  },
  {
    sportId: '704211',
    name: 'Nordic Combined Skiing'
  },
  {
    sportId: '704217',
    name: 'Speed Skating'
  },
  {
    sportId: '704218',
    name: 'Figure Skating'
  },
  {
    sportId: '704219',
    name: 'Snowboarding'
  },
  {
    sportId: '704220',
    name: 'Freestyle Skiing'
  },
  {
    sportId: '729926',
    name: 'Weightlifting'
  },
  {
    sportId: '2751941',
    name: 'Olympics'
  },
  {
    sportId: '4021954',
    name: 'Archery'
  },
  {
    sportId: '4022228',
    name: 'Canoeing/Kayaking'
  },
  {
    sportId: '5322045',
    name: 'Bobsleigh'
  },
  {
    sportId: '5322074',
    name: 'Luge'
  },
  {
    sportId: '5427239',
    name: 'Ten Pin Bowling'
  },
  {
    sportId: '6230056',
    name: 'TV / Film & Music'
  },
  {
    sportId: '6230057',
    name: 'Chess'
  },
  {
    sportId: '6463040',
    name: 'Gaelic Football'
  },
  {
    sportId: '6463041',
    name: 'Hurling'
  },
  {
    sportId: '6890914',
    name: 'Beach Soccer',
    icon: SportsSoccer
  },
  {
    sportId: '7950337',
    name: 'Beach Volleyball',
    icon: SportsVolleyball
  },
  {
    sportId: '8185230',
    name: 'Competitive Eating'
  },
  {
    sportId: '8185231',
    name: 'Multi Sport'
  },
  {
    sportId: '8499067',
    name: 'Skeleton'
  },
  {
    sportId: '8499068',
    name: 'Short Track Speed Skating'
  },
  {
    sportId: '10915624',
    name: 'eSports',
    icon: SportsEsports
  },
  {
    sportId: '10915625',
    name: 'Harness Racing'
  },
  {
    sportId: '11205863',
    name: '3x3 Basketball'
  },
  {
    sportId: '11205864',
    name: 'Virtual Football'
  },
  {
    sportId: '11205865',
    name: 'Virtual Basketball'
  },
  {
    sportId: '11205866',
    name: 'Virtual Horse Racing'
  },
  {
    sportId: '11205870',
    name: 'Virtual Cricket'
  },
  {
    sportId: '11205871',
    name: 'Virtual Table Tennis'
  },
  {
    sportId: '11205872',
    name: 'Virtual Ice Hockey'
  },
  {
    sportId: '11205873',
    name: 'Virtual Tennis'
  },
  {
    sportId: '11205874',
    name: 'Virtual American Football'
  },
  {
    sportId: '11205875',
    name: 'Virtual Golf'
  },
  {
    sportId: '11205876',
    name: 'Virtual Cycling'
  },
  {
    sportId: '91189',
    name: 'Volleyball',
    icon: SportsVolleyball
  },
  {
    sportId: '99112',
    name: 'Floorball'
  },
  {
    sportId: '99614',
    name: 'Handball',
    icon: SportsHandball
  },
  {
    sportId: '100557',
    name: 'Alpine Skiing'
  },
  {
    sportId: '100558',
    name: 'Ski Jumping'
  },
  {
    sportId: '102842',
    name: 'Biathlon'
  },
  {
    sportId: '102857',
    name: 'Cross Country'
  },
  {
    sportId: '140073',
    name: 'Netball'
  },
  {
    sportId: '208627',
    name: 'Hockey',
    icon: SportsHockey
  },
  {
    sportId: '249966',
    name: 'Winter Olympics',
    icon: AcUnitIcon
  },
  {
    sportId: '266230',
    name: 'Pesäpallo'
  },
  {
    sportId: '4022299',
    name: 'Diving'
  },
  {
    sportId: '4022341',
    name: 'Fencing'
  },
  {
    sportId: '4022394',
    name: 'Gymnastics'
  },
  {
    sportId: '4022554',
    name: 'Judo'
  },
  {
    sportId: '4022615',
    name: 'Modern Pentathlon'
  },
  {
    sportId: '4022667',
    name: 'Sailing'
  },
  {
    sportId: '4022691',
    name: 'Shooting'
  },
  {
    sportId: '4022775',
    name: 'Softball',
    icon: SportsBaseball
  },
  {
    sportId: '4022858',
    name: 'Taekwondo'
  },
  {
    sportId: '4022991',
    name: 'Triathlon'
  },
  {
    sportId: '4023055',
    name: 'Wrestling'
  },
  {
    sportId: '266231',
    name: 'Surfing'
  },
  {
    sportId: '269467',
    name: 'Table Tennis'
  },
  {
    sportId: '271554',
    name: 'Badminton'
  },
  {
    sportId: '276032',
    name: 'Water Polo'
  },
  {
    sportId: '300115',
    name: 'Martial Arts/UFC'
  },
  {
    sportId: '337918',
    name: 'Curling'
  },
  {
    sportId: '337919',
    name: 'Bandy'
  },
  {
    sportId: '360369',
    name: 'Lacrosse'
  }
]
