import React from 'react'
import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Hidden from '@mui/material/Hidden'
import Button from '@mui/material/Button'
import MenuIcon from '@mui/icons-material/Menu'
import ExitToAppIcon from '@mui/icons-material/LogoutSharp'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import logo from '../../../assets/logo-header.svg'
import { useAuth } from '../../../context/AuthContext'

const PREFIX_TOOLBAR = 'Toolbar'

const ToolbarClasses = {
  leftSide: `${PREFIX_TOOLBAR}-leftSide`,
  geniusLogo: `${PREFIX_TOOLBAR}-geniusLogo`,
  geniusName: `${PREFIX_TOOLBAR}-geniusName`,
  rightSide: `${PREFIX_TOOLBAR}-rightSide`,
  userName: `${PREFIX_TOOLBAR}-userName`,
  logoutButton: `${PREFIX_TOOLBAR}-logoutButton`
}

const StyledToolbar = styled(Toolbar)((
  {
    theme
  }
) => ({
  color: theme.palette.grey[600],
  backgroundColor: theme.palette.common.white,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottomWidth: '1px',
  borderBottomColor: theme.palette.grey[200],
  borderBottomStyle: 'solid',
  fontSize: 16,
  [`& .${ToolbarClasses.leftSide}`]: {
    float: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  [`& .${ToolbarClasses.rightSide}`]: {
    float: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  [`& .${ToolbarClasses.geniusName}`]: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.grey[600],
    fontWeight: 700,
    fontFamily: "KlarheitKurrent",
  },
  [`& .${ToolbarClasses.userName}`]: {
    paddingRight: theme.spacing(1),
  },
  [`& .${ToolbarClasses.geniusLogo}`]: {
    '& img': {
      verticalAlign: 'bottom',
    }
  },
  [`.MuiTypography-body1`]: {
    fontFamily: theme.typography.h6.fontFamily,
    fontSize: '1em',
    color: theme.palette.grey[600]
  }

}))


interface HeaderProps {
  onDrawerToggle: () => void;
}

function Header({ onDrawerToggle }: HeaderProps) {
  const { username, logout } = useAuth()

  return (
    <AppBar position='fixed' elevation={0} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <StyledToolbar>
        <Hidden mdUp>
          <Button
            color='inherit'
            aria-label='open drawer'
            onClick={onDrawerToggle}
            size='large'
          >
            <MenuIcon />
          </Button>
        </Hidden>
        <div className={ToolbarClasses.leftSide}>
          <a href='/' className={ToolbarClasses.geniusLogo}>
            <img src={logo} alt='Content Graph' />
          </a>
          <a href='/' className={ToolbarClasses.geniusName}>
            Content Graph
          </a>
        </div>
        <div className={ToolbarClasses.rightSide}>
          <div className={ToolbarClasses.userName}>
            <Hidden mdDown >
              {username || 'chosen one'}
            </Hidden>
          </div>
          <Tooltip title='Logout'>
            <Button
              color='inherit' onClick={() => logout()} size='large'
              sx={{ height: '48px', borderRadius: 0 }}
            >
              <span>Logout</span>
              <ExitToAppIcon />
            </Button>
          </Tooltip>
        </div>
      </StyledToolbar>
    </AppBar >
  )
}

export default Header
