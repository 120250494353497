import React from 'react'
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useGraph } from '../../../context/ApiContext'
import type { GraphDataFieldProps } from './types'

export const GraphDataFieldEntities = ['fixture', 'competitor', 'competition', 'season', 'round']

export const getEntity = (graph, entity, entityId) => {
  switch (entity) {
    case 'fixture':
      return graph.getFixture(entityId)
    case 'competitor':
      return graph.getCompetitor(entityId)
    case 'competition':
      return graph.getCompetition(entityId)
    case 'season':
      return graph.getSeason(entityId)
    case 'round':
      return graph.getRound(entityId)
  }
  return null
}

export const renderGraphData = (entity: string, id?: string) => (rowData) => (
  <GraphDataField entity={entity} entityId={rowData[id || `${entity}Id`]} />
)

const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 480,
    fontSize: theme.typography.pxToRem(14)
  }
}))(Tooltip)

const GraphDataField = ({ entity, entityId }: GraphDataFieldProps) => {
  const [data, setData] = React.useState(null)
  const graph = useGraph()

  React.useEffect(() => {
    let mounted = true
    setData(null)
    entity && entityId && getEntity(graph, entity, entityId)
      .then(result => result && mounted && setData(result))
      .catch(reason => console.log(reason))
    return () => { mounted = false }
  }, [graph, entity, entityId])

  return data
    ? <StyledTooltip
      title={
        <>
          <Typography color='inherit'><b>{data.name || entityId}</b> {data.name && <em>({entityId})</em>}</Typography>
          {data.scheduledStartTime && (<div><b>Time:</b> {data.scheduledStartTime}</div>)}
          {data.sportName && (<div><b>Sport:</b> {data.sportName}</div>)}
          {data.competitionId && data.competitionName && (<div><b>Competiton:</b> {data.competitionName} <em>({data.competitionId})</em></div>)}
          {data.seasonId && data.seasonName && (<div><b>Season:</b> {data.seasonName} <em>({data.seasonId})</em></div>)}
          {data.roundId && data.roundName && (<div><b>Round:</b> {data.roundName} <em>({data.roundId})</em></div>)}
        </>
      }
    ><span style={{ borderBottom: '1px dotted rgba(0,0,0,.4)', cursor: 'help' }}>{data.name || entityId}</span>
      </StyledTooltip>
    : <span>{entityId}</span>
}

export default React.memo(GraphDataField)
