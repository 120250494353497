import React from 'react'

// Makes a shared place for modals within their own context
export const ModalProviderFactory = () => {
  const PopupContext = React.createContext({
    popup: <></>,
    setPopup: (e?: JSX.Element) => {}
  })

  const PopupProvider = ({ initial = <span style={{ display: 'none' }} />, children, ...props }) => {
    const [popup, setPopup] = React.useState(initial)
    return (
      <PopupContext.Provider value={{ popup, setPopup: (e?: JSX.Element) => setPopup(e || initial) }} {...props}>
        {children}
        {popup}
      </PopupContext.Provider>
    )
  }

  const usePopup = (e?: JSX.Element) => {
    const { setPopup } = React.useContext(PopupContext)
    return setPopup
  }

  return { PopupProvider, usePopup }
}

// Context for Snackbars :)
export const { PopupProvider, usePopup } = ModalProviderFactory()

export const withPopupProvider = (BaseComponent: React.ComponentType<any>) => ({ ...props }) => {
  return (<PopupProvider><BaseComponent {...props} /></PopupProvider>)
}
