import React from 'react'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem'
import MenuItemLink from './MenuItemLink'
import IconButtonLink from './IconButtonLink'
import { styled } from "@mui/material/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  more: {
    float: 'right'
  }
}))

export interface HeaderMenuItem {
  title?: string,
  target?: string,
  onClick?: ((item: HeaderMenuItem) => void) | false,
  disabled?: boolean
}

export interface HeaderWithActionsProps {
  title?: string,
  children?: string | JSX.Element,
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  defaultAction?: HeaderMenuItem & {
    icon: React.ComponentType
  },
  menuItems?: HeaderMenuItem[]
}

function HeaderWithActions ({ title, variant = null, menuItems, defaultAction, children }: HeaderWithActionsProps) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const StyledIconButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.GSB.light,
      borderRadius: theme.spacing(1)
    },
    "&:focus": {
      backgroundColor: theme.palette.GSB.light,
      borderRadius: theme.spacing(1)
    },
  }))

  return (
    <Typography variant={variant} className={classes.root}>
      <span>{children || title}</span>

      {menuItems && menuItems.length && (
        <React.Fragment>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={handleClickMore}
            className={classes.more}
            size="large">
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {menuItems.map((item, index) => item.target
              ? <MenuItemLink key={index} to={item.target} disabled={item.disabled}>{item.title}</MenuItemLink>
              : <MenuItem key={index} disabled={item.disabled} onClick={() => { item.onClick && item.onClick(item); handleClose() }}>{item.title}</MenuItem>)}
          </Menu>
        </React.Fragment>
      )}

      {defaultAction && (
        <div className={classes.more}>
          {defaultAction.target
            ? <IconButtonLink to={defaultAction.target} disabled={defaultAction.disabled}>
              <defaultAction.icon />
            </IconButtonLink>
            : <StyledIconButton
              onClick={() => { defaultAction.onClick && defaultAction.onClick(defaultAction) }}
              disabled={defaultAction.disabled}
              size="large">
              {defaultAction.title}
              <defaultAction.icon />
            </StyledIconButton>}
        </div>
      )}
    </Typography>
  );
}

export default HeaderWithActions
