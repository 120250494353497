import React from 'react'
import { useGraph } from '../context/ApiContext'

export function useAutoComplete (type) {
  const autoComplete = useGraph().AutoComplete
  const [options, setOptions] = React.useState(autoComplete.getOptions(type))
  React.useEffect(() => {
    let mounted = true
    autoComplete.fetch(type).then(data => (mounted && setOptions(data)))
    return () => {
      mounted = false
    }
  }, [type, autoComplete])
  return options
}
