import React from 'react'
import { parse } from 'graphql'
import { makeDefaultArg, getDefaultScalarArgValue } from './CustomArgs'
import { useGraph } from '../../context/ApiContext'
import { useCache } from '../../hooks/cache'
import Loading from '../common/Loading'
import GraphTableSnackbar from '../common/GraphTable/GraphTableSnackbar'
import GraphiQL from 'graphiql'
import GraphiQLExplorer from 'graphiql-explorer'
import './ExplorerBrowser.css'
import QueryExample from './query-example/QueryExample'
import { Button } from '@mui/material'

const getLocalStorageItem = (key: string, defaultValue: string = '') => {
  const value = localStorage.getItem(key)
  return (!value || value === 'null') ? defaultValue : value
}

export default function ExplorerBrowser ({
  query: initialQuery,
  variables
}: {
  query?: string;
  variables?: string;
}) {
  const graph = useGraph()
  const [query, setQuery] = React.useState(
    initialQuery || getLocalStorageItem('graphiql:query')
  )
  const [queryVariables, setQueryVariables] = React.useState(
    variables || getLocalStorageItem('graphiql:variables')
  )
  const [openExplorerTooltip, setOpenExplorerTooltip] = React.useState(
    (getLocalStorageItem('graphiql:openExplorerTooltip', 'true') === 'true')
  )
  const [isExplorerOpen, setExplorerOpen] = React.useState(
    localStorage.getItem('graphiql:explorerOpen') !== 'false'
  )
  const [isExamplesOpen, setExamplesOpen] = React.useState(
    localStorage.getItem('graphiql:examplesOpen') !== 'false'
  )
  const {
    data: schema,
    isLoading,
    error
  } = useCache(graph, 'getSchema', {
    fallback: null,
    ttl: 0
  })

  const ref = React.useRef()
  const graphiql: GraphiQL = ref.current

  const handleInspectOperation = (
    cm: any,
    mousePos: { line: number; ch: number }
  ) => {
    let parsedQuery = null
    try {
      parsedQuery = parse(query || '')
    } catch (e) {
      console.error(e)
      return null
    }

    const token = cm.getTokenAt(mousePos)
    const start = { line: mousePos.line, ch: token.start }
    const end = { line: mousePos.line, ch: token.end }
    const relevantMousePos = {
      start: cm.indexFromPos(start),
      end: cm.indexFromPos(end)
    }

    const position = relevantMousePos

    const def = parsedQuery.definitions.find((definition) => {
      if (!definition.loc) {
        return false
      }

      const { start, end } = definition.loc
      return start <= position.start && end >= position.end
    })

    if (!def) {
      console.error(
        'Unable to find definition corresponding to mouse position'
      )
      return null
    }

    const operationKind =
      def.kind === 'OperationDefinition'
        ? def.operation
        : def.kind === 'FragmentDefinition'
          ? 'fragment'
          : 'unknown'

    const operationName =
      def.kind === 'OperationDefinition' && !!def.name
        ? def.name.value
        : def.kind === 'FragmentDefinition' && !!def.name
          ? def.name.value
          : 'unknown'

    const selector = `.graphiql-explorer-root #${operationKind}-${operationName}`

    const el = document.querySelector(selector)
    el && el.scrollIntoView()
  }

  const editQuery = (query: string) => {
    localStorage.setItem('graphiql:query', query)
    setQuery(query)
  }

  const editVariables = (variables: string) => {
    localStorage.setItem('graphiql:variables', variables)
    setQueryVariables(variables)
  }

  const toggleExplorer = () => {
    const status = !isExplorerOpen
    localStorage.setItem('graphiql:explorerOpen', status ? 'true' : 'false')
    setExplorerOpen(status)
  }

  const toggleExamples = () => {
    const status = !isExamplesOpen
    localStorage.setItem('graphiql:examplesOpen', status ? 'true' : 'false')
    setExamplesOpen(status)
    hideAnimatedTooltip()
  }

  const hideAnimatedTooltip = () => {
    localStorage.setItem('graphiql:openExplorerTooltip', 'false')
    setOpenExplorerTooltip(false)
  }

  graphiql &&
    graphiql.getQueryEditor().setOption('extraKeys', {
      ...(graphiql.getQueryEditor().options.extraKeys || {}),
      'Shift-Alt-LeftClick': handleInspectOperation
    })

  return isLoading ? (<Loading />) : (// eslint-disable-line multiline-ternary
    <div className='graphiql-layout'>
      {!isExamplesOpen ? (// eslint-disable-line multiline-ternary
        <div className='examples-container'>
          <QueryExample
            onToggleQueryExample={toggleExamples}
            onEditQuery={editQuery}
            onEditVariables={editVariables}
          />
        </div>
      ) : (// eslint-disable-line multiline-ternary
        <React.Fragment />
      )}
      <div className='graphiql-container-parent'>
        {error && <GraphTableSnackbar error={error} autoHideDuration={null} />}
        <div
          className='graphiql-container'
          style={{
            width: '320px',
            zIndex: 7,
            display: isExplorerOpen ? 'block' : 'none'
          }}
        >
          <GraphiQLExplorer
            schema={schema}
            query={query}
            variables={queryVariables}
            onEdit={editQuery}
            onEditVariables={editVariables}
            onRunOperation={(operationName) =>
              graphiql.handleRunQuery(operationName)}
            explorerIsOpen={isExplorerOpen}
            onToggleExplorer={toggleExplorer}
            getDefaultScalarArgValue={getDefaultScalarArgValue}
            makeDefaultArg={makeDefaultArg}
          />
        </div>
        <div
          className='graphiql-container'
          style={{
            width: isExplorerOpen ? 'calc(100% - 300px)' : '100%',
            float: 'right'
          }}
        >
          <GraphiQL
            ref={ref}
            fetcher={graph.subscriptionFetch.bind(graph)}
            schema={schema}
            query={query}
            onEditQuery={editQuery}
            variables={queryVariables}
            onEditVariables={editVariables}
          >
            <GraphiQL.Toolbar>
              <GraphiQL.Button
                onClick={toggleExplorer}
                key='Explorer'
                label='Explorer'
                title='Toggle Explorer'
              />
              <GraphiQL.Button
                onClick={() => graphiql.handleToggleHistory()}
                label='History'
                title='Show History'
              />
              <GraphiQL.Button
                onClick={() => graphiql.handlePrettifyQuery()}
                label='Prettify'
                title='Prettify'
              />
              <div className='examples-button'>
                <Button
                  className='toolbar-button'
                  aria-label='NEW'
                  onClick={toggleExamples}
                >
                  Examples
                  <span
                    className={
                      openExplorerTooltip
                        ? 'tooltip-text'
                        : 'tooltip-text hidden'
                    }
                  >
                    NEW
                  </span>
                </Button>
              </div>
            </GraphiQL.Toolbar>
          </GraphiQL>
        </div>
      </div>
    </div>
  )
}
