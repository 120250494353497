import React from 'react'
import GraphDataField, { GraphDataFieldEntities } from './GraphDataField'
import GraphTableSportIcon from './GraphTableSportIcon'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { localeFormatDate } from '../../../util'
import get from 'lodash/get'

import type { GraphTableData, GraphTableColumn, GraphTableCellProps } from './types'

export function renderMuiTableCell (data: GraphTableData, column: GraphTableColumn): (dataIndex: number, rowIndex: number) => React.ReactNode {
  const graphType = GraphDataFieldEntities.includes(column.type)
  if (graphType) { return (_, row) => <GraphDataField entity={column.type} entityId={data && data[row] && get(data[row], column.field, '') as string} /> }
  return (_, row) => <GraphTableCell column={column} value={(data && data[row] && get(data[row], column.field)) || ''} />
}

function wrapSpan (value, styles, moreStyles = {}) {
  return (
    <span style={{
      ...styles,
      ...moreStyles
    }}
    >{value}
    </span>
  )
}

function GraphTableCell ({ column, value }: GraphTableCellProps) {
  const { type, cellStyle: styles } = column
  const dateLocale = 'en-US' // TODO: check if we should keep this or use browser locale?
  switch (type) {
    case 'boolean':
      const checkStyle = { verticalAlign: 'middle', width: 48 }
      return wrapSpan(value ? <CheckIcon style={checkStyle} /> : <ClearIcon style={checkStyle} />, styles)
    case 'date':
    case 'time':
    case 'datetime':
      return wrapSpan(localeFormatDate(value, type, dateLocale), styles, { whiteSpace: 'nowrap' })
    case 'sport':
      const sportStyle = { verticalAlign: 'middle', width: 48 }
      return wrapSpan(<GraphTableSportIcon sportId={value} style={sportStyle} />, styles, { display: 'block', textAlign: 'center' })
    default: {
      const actualType = type || typeof value
      if (actualType === 'string' || actualType === 'number') {
        return wrapSpan(value.toString(), styles)
      }
      return wrapSpan(JSON.stringify(value), styles)
    }
  }
}
