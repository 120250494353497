import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  commonContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(0)
  }
}))
