import React from 'react'
import ExplorerBrowser from '../components/graphiql/ExplorerBrowser'
import Layout from '../components/common/layout/Layout'

export interface ContentProps {
  customerId: string
  type: string
}

function Content (props: ContentProps) {
  return (
    <Layout explorer>
      <ExplorerBrowser />
    </Layout>
  )
}

export default Content
