import RestApi, { GET, PUT, POST, DELETE } from './RestApi'

export interface SimpleUser {
  username: string,
  email?: string,
  role?: string
}

export interface User extends SimpleUser{
  phoneNumber?: string,
  roleAttributes?: Map<string, Array<string>>,
  apiPermissions?: string
}

export interface NewUser {
  username: string,
  temporaryPassword: string
}

export interface SimpleRole {
  name: string,
  description?: string,
  attributes?: string[]
}

export interface Role {
  name: string,
  description?: string,
  limit?: Number,
  attributes?: RoleAttribute[]
}

export interface RoleAttribute {
  name: string,
  description?: string,
  type?: string,
  collections?: string[]
}

export default class UsersApi extends RestApi {
  name = 'UsersApi'

  async listUsers (): Promise<SimpleUser[]> {
    return this.api(GET, 'users').then(users => users.sort((a, b) => (a.username < b.username) ? -1: 0))
  }

  async getUser (user: string | SimpleUser): Promise<User> {
    return this.api(GET, `users/${typeof user==='string' ? user : user.username}`)
  }

  async listRoles (): Promise<SimpleRole[]> {
    return this.api(GET, 'roles')
  }

  async getRole (role: string | SimpleRole): Promise<Role> {
    return this.api(GET, `roles/${typeof role==='string' ? role : role.name}`)
  }

  async createUser (user: User): Promise<NewUser> {
    return this.api(POST, 'users', JSON.stringify(user))
  }

  async copyUserPermissions (username:string, copyFrom: string): Promise<NewUser> {
    return this.api(PUT, `users/${username}/copies/${copyFrom}`)
  }

  async updateUser (user): Promise<User> {
    return this.api(PUT, `users/${user.username}`, JSON.stringify(user))
  }

  async updateUserAttributes (username: string, attributes) {
    return this.api(PUT, `users/${username}/attributes`, JSON.stringify(attributes))
  }

  async resetUserPassword (user: string | SimpleUser): Promise<NewUser> {
    return this.api(POST, `users/${typeof user==='string' ? user : user.username}/reset`)
  }

  async deleteUser (user: string | SimpleUser) {
    return this.api(DELETE, `users/${typeof user==='string' ? user : user.username}`)
  }
}