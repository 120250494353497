import React from 'react'
import Browser from '../../components/graphiql/Browser'
import PaperBody from '../../components/common/layout/PaperBody'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Link } from '@reach/router'
import Box from '@mui/system/Box'
import ReplyIcon from '@mui/icons-material/Reply';
import { styled } from '@mui/material/styles'

import { Link as MUILink } from '@mui/material';
import { useStyles } from '../../components/common/ObjectEditor/styles'
import CustomDivider from '../../components/common/CustomDivider'

const query = `query Translations ($customerId: String = "", $locale: String = "") {
  translations (where:{customerId: {_eq: $customerId}, locale: {_eq: $locale}}) {
		sourceText
    targetText
  }
}

query TranslatedEntities ($customerId: String = "", $locale: String = "") {
  competitorNames: competitors (where:{nameTranslation: {customerId: {_eq: $customerId}, locale: {_eq: $locale}}}) {
    competitorId
    name
    nameTranslation {
      targetText
    }
  }

  marketTypeIds: markets (where:{marketTypeIdTranslation: {customerId: {_eq: $customerId}, locale: {_eq: $locale}}}, distinct_on: [marketTypeId]) {
    marketTypeId
    marketTypeIdTranslation {
      targetText
    }
  }
}

query TranslationMatchPlays ($customerId: String = "", $locale: String = ""){
  match_plays(where:{playTranslations: {customerId: {_eq: $customerId}, locale: {_eq: $locale}}}){
    playDescription
    playTranslations{
      locale
      targetText   	
    }
  }
}

`
const TransaltionsTitle = styled('div')(({ theme }) => ({
  fontFamily: `${(theme.typography as any).h5.fontFamily}`,
  fontSize: `${(theme.typography as any).h4.fontSize}`
}))

function Page({ customerId, locale }) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Box className={classes.commonContainer}>
        <TransaltionsTitle>
          <b>
            Translations for <MUILink
              color='primary'
              href={`/translations`}>
              {customerId}
              <ReplyIcon />
            </MUILink> ({locale})
          </b>
        </TransaltionsTitle>
      </Box>
      <Tabs value={1}>
        <Tab component={Link} to='../' label='Entries' />
        <Tab label='Query Explorer' />
      </Tabs>
      <CustomDivider />
      <PaperBody noPadding>
        <Browser height='calc(100vh - 300px)' query={query} variables={JSON.stringify({ customerId, locale }, null, 2)} />
      </PaperBody>
    </React.Fragment>
  )
}

export default Page
