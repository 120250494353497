import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

export default function SimpleAutocomplete (props) {
  const label = props.label
  return (<Autocomplete
    {...props}
    renderInput={(params) => (
      <TextField
        margin='dense'
        fullWidth
        {...params} label={label}
      />
    )}
  />)
}
